/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form'
import {
  ReusableInput,
  ReusableSelect1
} from '../../component/TextField/text-field'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import {
  Dialog,
  DialogStatus,
  showDialog
} from './../../component/dialog/dialog'
import { Ultis, uuidv4 } from '../../core/Utils'
import CheckboxComponent from './../../component/checkbox/checkbox'
import ReusableDatePicker from '../../component/date-picker/date-picker'
import ConfigAPI from '../../config/configApi'
import { ControllerDA } from '../../core/controller'
import { ActionType } from '../../core/ActionType'
import notiUtil from './../../core/notiUtil'
import { ADD, EDIT, applyStatusList, notiApplyInfor } from '../../assets/const/select-list'
import ImportFile from '../../component/import-file/import-file'
import { postData } from '../../core/BaseDA'

export default function QuanLyUngVienForm({ controller }) {
  const location = useLocation()

  const {
    register,
    control,
    setValue,
    handleSubmit,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({ shouldFocusError: false, defaultValues: { applyStatus: 1, academi: { list: [{}] }, familyMember: [{}], work: [{}] } })

  const { id } = useParams()

  const dialogRef = useRef()

  const avatarRef = useRef()
  const [showPass, setShowPass] = useState(false)
  const [pushNoti, setPushOnti] = useState(true)

  const addAcademi = () => {
    setValue('academi.list', [...getValues('academi.list'), {}])
  }
  const addWork = () => {
    setValue('work', [...getValues('work'), {}])
  }
  const addFamily = () => {
    setValue('familyMember', [...getValues('familyMember'), {}])
  }

  const handleImageChange = async e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      const resFile = await ControllerDA.uploadFile({ file: img })
      if (resFile) {
        setValue('urlAvatar', resFile.data.id)
      }
    }
  }

  const _submitForm = () => {
    let data = getValues()
    let validateDone = true
    if (!id) {
      data.userName = data.userName.trim()
      data.password = data.password.trim()
      let newUserName = data.userName
      let newPass = data.password
      const userNameRegex = /^[a-zA-Z0-9_.-]*$/g
      const passRegex = /^[A-Za-z0-9_@./#^!\(*%\)$&+-]*$/g
      if (!newUserName.match(userNameRegex)) {
        validateDone = false
        setError('userName', { message: 'Chỉ cho phép nhập chữ cái (a-z), số (0-9) và dấu (.-_)' })
        notiUtil.errors('Tên đăng nhập không hợp lệ')
      }
      if (!newPass.match(passRegex)) {
        validateDone = false
        setError('password', { message: 'Chỉ cho phép nhập chữ cái (a-z), số (0-9) và dấu (.!@#$%^&*()_+-)' })
        notiUtil.errors('Mật khẩu không hợp lệ')
      }
    }
    if (validateDone)
      showDialog({
        ref: dialogRef,
        status: DialogStatus.INFOR,
        title: `Xác nhận ${id ? 'chỉnh sửa' : 'tạo'} hồ sơ ứng viên?`,
        submit: () => {
          if (data.birthDay?.length > 0)
            data.birthDay = Ultis.stringToDate(data.birthDay).getTime()
          else data.birthDay = 0
          if (data.cardDate?.length > 0)
            data.cardDate = Ultis.stringToDate(data.cardDate).getTime()
          else data.cardDate = 0
          if (data.academi) {
            delete data.academi.studyFile
            data.academi = JSON.stringify(data.academi)
          }
          data.familyMember = JSON.stringify(data.familyMember)
          data.other = JSON.stringify(data.other)
          data.work = JSON.stringify(data.work)
          submitAction(data)
        }
      })
  }

  const submitAction = event => {
    if (!event.cvFile) delete event.cvFileId
    delete event.cvFile
    event.status ??= 1
    if (id) {
      event.Action = ActionType.Edit.value
      ControllerDA.editAction(ConfigAPI.customerUrl, controller, event).then(
        res => {
          if (res.code === 200) {
            notiUtil.success("Cập nhật hồ sơ ứng viên thành công")
            window.history.back()
          } else {
            notiUtil.success(res.message)
          }
        }
      )
    } else {
      event.type = 1
      event.dateCreated = new Date().getTime()
      event.Action = ActionType.Add.value
      ControllerDA.doAction(ConfigAPI.customerUrl, controller, event).then(
        async response => {
          if (response.code === 200) {
            if (pushNoti) {
              const data = {
                to: `/topics/2`,
                notification: {
                  body: `Ứng viên ${watch('name')} đã sẵn sàng ứng tuyển. Hãy kiểm tra hồ sơ ngay !`,
                  title: 'Đã có thêm 1 ứng viên mới',
                  icon: 'https://cdn-icons-png.flaticon.com/256/124/124010.png'
                },
                data: {
                  type: notiApplyInfor,
                  click_action: 'FLUTTER_NOTIFICATION_CLICK',
                  body: 'Body of Your Notification in Data',
                  title: 'Title of Your Notification in Title',
                  url: 'HOME2',
                  key_2: 'Value for key_2',
                  pattner_id: '90349279303689920881142',
                  idCustomer: response.data
                }
              }
              const res = await ControllerDA.pushNoti(data)
              if (res) {
                if (res) {
                  const obj = {
                    type: notiApplyInfor,
                    isRead: false,
                    title: 'Đã có thêm 1 ứng viên mới',
                    dateCreated: new Date().getTime(),
                    content: `Ứng viên ${watch('name')} đã sẵn sàng ứng tuyển. Hãy kiểm tra hồ sơ ngay !`,
                    // "phoneNumber": "string",
                    isDeleted: true,
                    url: response.data,
                    Action: ActionType.Add.value,
                  }
                  await ControllerDA.doAction(ConfigAPI.noti, 'Notification', obj)
                }
                notiUtil.success('Gửi thông báo thành công');
              }
            }
            window.history.back()
            notiUtil.success('Gửi thông báo thành công');
          } else {
            notiUtil.success(response.message)
          }
        }
      )
    }
  }

  const onError = () => { }

  const uploadStudyFile = (newFile, index) => {
    if (newFile) {
      ControllerDA.uploadFile({ file: newFile }).then(res => {
        if (res.code === 200) {
          setValue(`academi.studyFileId`, res.data.id)
          setValue(`academi.studyFile`, {
            id: res.data.id,
            name: res.data.filePath,
            path: res.data.filePath
          })
        } else {
          notiUtil.errors('error upload file')
        }
      })
    }
  }

  const uploadCvFile = (newFile, index) => {
    if (newFile) {
      ControllerDA.uploadFile({ file: newFile }).then(res => {
        if (res.code === 200) {
          setValue(`cvFileId`, res.data.id)
          setValue(`cvFile`, {
            id: res.data.id,
            name: res.data.filePath,
            path: res.data.filePath
          })
        } else {
          notiUtil.errors('error upload file')
        }
      })
    }
  }

  useEffect(() => {
    if (id) {
      if (!localStorage.getItem('listActive')?.includes(EDIT)) notiUtil.errors('Tài khoản không có quyền chỉnh sửa')
      ControllerDA.getDetail(ConfigAPI.customerUrl, controller, {
        id: id,
        Action: ActionType.GetById.value
      }).then(res => {
        Object.keys(res.data).forEach(fieldName => {
          if (typeof res.data[fieldName] === 'string')
            res.data[fieldName] = res.data[fieldName].trim()
          if (fieldName === 'academi') {
            let parseValue =
              res.data[fieldName]?.length > 0
                ? JSON.parse(res.data[fieldName])
                : { list: [{}] }
            setValue(fieldName, parseValue)
            if (parseValue.studyFileId?.length > 0) {
              ControllerDA.download({ fileId: parseValue.studyFileId }).then(res => {
                if (res.code === 200) {
                  setValue(`academi.studyFile`, {
                    id: parseValue.studyFileId,
                    name: res.data,
                    path: res.data
                  })
                }
              })
            }
          } else if (fieldName === 'cvFileId') {
            if (res.data[fieldName]?.length > 0)
              ControllerDA.download({ fileId: res.data[fieldName] }).then(res => {
                if (res.code === 200) {
                  setValue(`cvFile`, {
                    id: res.data[fieldName],
                    name: res.data,
                    path: res.data
                  })
                }
              })
            setValue(fieldName, res.data[fieldName])
          } else if (fieldName === 'other') {
            let parseValue =
              res.data[fieldName]?.length > 0 && res.data[fieldName] !== "[{}]"
                ? JSON.parse(res.data[fieldName])
                : {}
            setValue(fieldName, parseValue)
          } else if (['familyMember', 'work'].includes(fieldName)) {
            let parseValue =
              res.data[fieldName]?.length > 0
                ? JSON.parse(res.data[fieldName])
                : [{}]
            setValue(fieldName, parseValue)
          } else if (['birthDay', 'cardDate'].includes(fieldName)) {
            if (res.data[fieldName])
              setValue(
                fieldName,
                Ultis.datetoString(new Date(res.data[fieldName]))
              )
          } else {
            if (fieldName === 'mobile') setValue('initPhoneNumber', res.data[fieldName])
            setValue(fieldName, res.data[fieldName])
          }
        })
      })
    }
  }, [location.pathname])

  const editable = (id && localStorage.getItem('listActive')?.includes(EDIT)) || (!id && localStorage.getItem('listActive')?.includes(ADD))
  return (
    <form className={`admin-add-view mainView col ${editable ? '' : 'disabled'}`} onSubmit={handleSubmit(_submitForm, onError)} >
      <Dialog ref={dialogRef} />
      <div className='view-header col'>
        <div className='view-name heading-6'>
          {id ? 'Chỉnh sửa thông tin ứng viên' : 'Thêm mới ứng viên'}
        </div>
        <div className='breadcrums row'>
          <NavLink to={'/admin/quan-ly-ho-so-ung-vien'} className='nav-link regular1' type='button' >
            Quản lý hồ sơ ứng viên
          </NavLink>
          <FontAwesomeIcon className='icon' size='2xs' icon={faChevronRight} />
          <NavLink className='nav-link regular1' type='button'>
            {id ? 'Chỉnh sửa thông tin ứng viên' : 'Thêm mới ứng viên'}
          </NavLink>
        </div>
      </div>
      <div className='view-content-container row'>
        <div className='view-content col'>
          <div className='scroll-view-container col'>
            <div className='inputForm view-form row-3 row'>
              <div className='header row'>
                <div className='label flex1 heading-7'>Thông tin chung</div>
                {id ? null : <CheckboxComponent
                  value={pushNoti}
                  onClick={() => setPushOnti(!pushNoti)}
                  label={'Gửi thông báo đển ứng viên'}
                />}
              </div>

              <div className='left-item col' style={{ gap: 16 }}>
                <input ref={avatarRef} type='file' onChange={handleImageChange} style={{ display: 'none' }} />
                <img className='image' src={watch('urlAvatar') ? `${ConfigAPI.fileUrl}api/UploadFile/img/${watch('urlAvatar')}` : ''} alt='' />

                {editable ? <button type='button' className='upload-image row' onClick={() => { avatarRef.current.click() }} >
                  <svg width={14} height={13} viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg' >
                    <path d='M3.66732 3.88889L7.00065 0L10.334 3.88889H7.55621V8.88889H6.4451V3.88889H3.66732Z' fill='#262626' />
                    <path d='M12.5562 11.1111H1.4451V7.77778H0.333984V11.6667C0.333984 11.9733 0.582873 12.2222 0.88954 12.2222H13.1118C13.4184 12.2222 13.6673 11.9733 13.6673 11.6667V7.77778H12.5562V11.1111Z' fill='#262626' />
                  </svg>
                  <span className='button-text-3'>Tải ảnh lên</span>
                </button> : null}
              </div>

              <div className='list-input-container row'>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'userName'}
                    label={'Tên đăng nhập'}
                    disabled={id != null}
                    onBlur={(ev) => {
                      postData(ConfigAPI.customerUrl + `Customer/CheckRegister?userName=${ev.target.value}&mobile=0&rereferralCode=0`, { obj: { Action: 'CheckRegister' } }).then(res => {
                        if (res.code !== 200 && res.data === 1) {
                          notiUtil.errors(res.message)
                          setError('userName', res.message)
                        }
                      })
                    }}
                  />
                </div>
                {!id && <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'password'}
                    label={'Mật khẩu'}
                    type={showPass ? 'text' : 'password'}
                    sufix={
                      <div className='box32 center' onClick={() => setShowPass(!showPass)} >
                        <FontAwesomeIcon size='xs' icon={showPass ? faEyeSlash : faEye} />
                      </div>
                    }
                  />
                </div>}
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'code'}
                    label={'Mã hồ sơ'}
                    disabled={id != null && watch('code')?.length}
                    onBlur={ev => {
                      if (ev.target.value.trim().length) {
                        ControllerDA.doAction(
                          ConfigAPI.customerUrl,
                          controller,
                          { Action: ActionType.CheckProfile.value }
                        ).then(res => {
                          if (!res.data)
                            setError('code', { message: 'Mã hồ sơ đã tồn tại' })
                        })
                      }
                    }}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'referralCodeIntroduced'}
                    label={'Mã giới thiệu'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'name'}
                    label={'Họ và tên'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    value={watch('sex')}
                    control={control}
                    errors={errors}
                    label={'Giới tính'}
                    required={false}
                    name={'sex'}
                    valueType={'int'}
                    options={[
                      { name: 'Nam', id: 1 },
                      { name: 'Nữ', id: 0 }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'idCard'}
                    label={'CCCD'}
                    required={false}
                    type={'number'}
                  />
                </div>
                <div className='form-card'>
                  <ReusableDatePicker
                    label={'Ngày cấp'}
                    errors={errors}
                    register={register}
                    name={'cardDate'}
                    required={false}
                    value={watch('cardDate')}
                    placeholder={'Chọn ngày cấp'}
                    onChange={value => {
                      if (value) setValue('cardDate', value)
                    }}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'addressId'}
                    label={'Nơi cấp'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableDatePicker
                    label={'Ngày tháng năm sinh'}
                    errors={errors}
                    register={register}
                    name={'birthDay'}
                    required={false}
                    value={watch('birthDay')}
                    placeholder={'Chọn ngày sinh'}
                    onChange={value => {
                      if (value) setValue('birthDay', value)
                    }}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'homeTown'}
                    label={'Địa chỉ'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'mobile'}
                    label={'Số điện thoại'}
                    required={false}
                    type={'number'}
                    onBlur={(ev) => {
                      if (ev.target.value?.length && getValues('initPhoneNumber') !== ev.target.value) {
                        postData(ConfigAPI.customerUrl + `Customer/CheckRegister?userName='dsfsfvdrgrg@r'&mobile=${ev.target.value}&rereferralCode=0`, { obj: { Action: 'CheckRegister' } }).then(res => {
                          if (res.code === 404 && res.data === 2) {
                            setError('mobile', 'Số điện thoại đã tồn tại')
                            notiUtil.errors('Số điện thoại đã tồn tại')
                          }
                        })
                      } else clearErrors('mobile')
                    }}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    value={watch('marriage')}
                    control={control}
                    errors={errors}
                    label={'Tình trạng hôn nhân'}
                    required={false}
                    name={'marriage'}
                    valueType={'bool'}
                    options={[
                      { name: 'Đã kết hôn', id: true },
                      { name: 'Chưa kết hôn', id: false }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'height'}
                    label={'Chiều cao'}
                    required={false}
                    type={'number'}
                    sufix={<span className='sufix-icon regular2'>cm</span>}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'weight'}
                    label={'Cân nặng'}
                    required={false}
                    type={'number'}
                    sufix={<span className='sufix-icon regular2'>Kg</span>}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    label={'Nhóm máu'}
                    errors={errors}
                    required={false}
                    name={'bloodGroup'}
                    value={watch('bloodGroup')}
                    options={[
                      { name: 'A', id: 'A' },
                      { name: 'B', id: 'B' },
                      { name: 'AB', id: 'AB' },
                      { name: 'O', id: 'O' }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'eyeSight'}
                    label={'Thị lực'}
                    required={false}
                    type={'number'}
                    sufix={<span className='sufix-icon regular2'>/10</span>}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    name={'colorBlind'}
                    control={control}
                    errors={errors}
                    label={'Mù màu'}
                    value={watch('colorBlind')}
                    required={false}
                    valueType={'bool'}
                    options={[
                      { name: 'Có', id: true },
                      { name: 'Không', id: false }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    label={'Tay thuận'}
                    errors={errors}
                    required={false}
                    name={'preferredHand'}
                    value={watch('preferredHand')}
                    options={[
                      { name: 'Phải', id: 'Phải' },
                      { name: 'Trái', id: 'Trái' }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    errors={errors}
                    label={'Hình xăm'}
                    value={watch('tattooed')}
                    required={false}
                    name={'tattooed'}
                    valueType={'bool'}
                    options={[
                      { name: 'Có', id: true },
                      { name: 'Không', id: false }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    errors={errors}
                    label={'Hút thuốc'}
                    value={watch('smoke')}
                    required={false}
                    name={'smoke'}
                    valueType={'bool'}
                    options={[
                      { name: 'Có', id: true },
                      { name: 'Không', id: false }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    errors={errors}
                    label={'Uống rượu'}
                    value={watch('drinkAlcohol')}
                    required={false}
                    name={'drinkAlcohol'}
                    valueType={'bool'}
                    options={[
                      { name: 'Có', id: true },
                      { name: 'Không', id: false }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    errors={errors}
                    label={'Trạng thái'}
                    value={watch('applyStatus')}
                    required={false}
                    name={'applyStatus'}
                    options={applyStatusList}
                    disabled={!id}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'forte'}
                    label={'Sở trường'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'weakness'}
                    label={'Sở đoản'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'fieldOfInterest'}
                    label={'Môn học/ lĩnh vực sở trường'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'character'}
                    label={'Tính cách'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    errors={errors}
                    required={false}
                    control={control}
                    value={watch(`collectiveExperience`)}
                    label={'Kinh nghiệm tập thể'}
                    options={[
                      { name: 'Có', id: true },
                      { name: 'Không', id: false },
                    ]}
                    name={`collectiveExperience`}
                  />
                </div>
                <div className='form-card' style={{ overflow: 'hidden' }}>
                  <div className='TextFieldContainer col'>
                    <div className='label-container row'>
                      <span className='label-3'>CV ứng viên</span>
                    </div>
                    <div className='row' style={{ justifyContent: 'start' }}>
                      <ImportFile
                        fileItem={watch(`cvFile`)}
                        buttonOnly={true}
                        onChange={newFile => {
                          uploadCvFile(newFile)
                        }}
                        onRemove={() => { setValue('cvFile', undefined) }}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-card'>
                  <div className='TextFieldContainer col'>
                    <div className='label-container row'>
                      <span className='label-3'>Tệp đánh giá học tập</span>
                    </div>
                    <div className='row' style={{ justifyContent: 'start' }}>
                      <ImportFile
                        fileItem={watch(`academi.studyFile`)}
                        buttonOnly={true}
                        onChange={newFile => {
                          uploadStudyFile(newFile)
                        }}
                        onRemove={() => {
                          setValue(`academi.studyFileId`, undefined)
                          setValue('academi.studyFile', undefined)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='inputForm view-form row-3 row'>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'question1'}
                  label={'Lý do muốn đi Nhật'}
                  required={false}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'question2'}
                  label={'Số tiền tiết kiệm mong muốn sau 3 năm'}
                  placeholder={'Nhập số tiền'}
                  required={false}
                  type={'number'}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'question3'}
                  label={'Công việc mong muốn sau khi về nước'}
                  required={false}
                />
              </div>
              <div className='form-card'>
                <ReusableSelect1
                  control={control}
                  register={register}
                  errors={errors}
                  name={'question4'}
                  label={'Đã từng đi Nhật chưa?'}
                  required={false}
                  options={[{ id: '1', name: 'Đã từng' }, { id: '0', name: 'Chưa từng' }]}
                  value={watch('question4')}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'question5'}
                  label={'Các quốc gia đã từng đi?'}
                  required={false}
                />
              </div>
            </div>
            <div className='inputForm view-form row-4 col'>
              {/* row-3 */}
              <div className='header row'>
                <div className='label flex1 heading-7'>Lý lịch học tập</div>
                <button type='button' className='text-button' onClick={addAcademi} >
                  Thêm
                </button>
              </div>
              {(watch('academi.list')).map((item, index) => {
                return (
                  <div style={{ width: '100%' }} key={uuidv4()} className='list-input-container container-border row' >
                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`academi.list[${index}].schoolName`}
                        label={'Tên trường học'}
                        required={false}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableSelect1
                        register={register}
                        errors={errors}
                        control={control}
                        name={`academi.list[${index}].certificate`}
                        value={watch(`academi.list[${index}].certificate`)}
                        label={'Bằng cấp'}
                        required={false}
                        options={[{ id: 1, name: 'Cấp 3' }, { id: 2, name: 'Trung cấp' }, { id: 3, name: 'Cao đẳng' }, { id: 4, name: 'Đại học' }]}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`academi.list[${index}].majors`}
                        label={'Khoa'}
                        required={false}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`academi.list[${index}].studyTime`}
                        label={'Thời gian học'}
                        placeholder={'Từ năm - đến năm'}
                        required={false}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableSelect1
                        errors={errors}
                        required={false}
                        control={control}
                        value={watch(`academi.list[${index}].studyStatus`)}
                        label={'Tình trạng học tập'}
                        options={[
                          { name: 'Học muộn', id: 'Học muộn' },
                          { name: 'Học sớm', id: 'Học sớm' },
                          { name: 'Học lại', id: 'Học lại' }
                        ]}
                        name={`academi.list[${index}].studyStatus`}
                      />
                    </div>
                    <button type='button' onClick={() => {
                      const acaList = getValues('academi.list')
                      setValue('academi.list', [...acaList.slice(0, index), ...acaList.slice(index + 1)])
                    }} className='remove-block row' >
                      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' >
                        <path fillRule='evenodd' clipRule='evenodd' d='M16.4223 4.7559C16.7477 4.43047 16.7477 3.90283 16.4223 3.57739C16.0968 3.25195 15.5692 3.25195 15.2438 3.57739L9.99967 8.82147L4.7556 3.57739C4.43016 3.25195 3.90252 3.25195 3.57709 3.57739C3.25165 3.90283 3.25165 4.43047 3.57709 4.7559L8.82116 9.99998L3.57709 15.2441C3.25165 15.5695 3.25165 16.0971 3.57709 16.4226C3.90252 16.748 4.43016 16.748 4.7556 16.4226L9.99967 11.1785L15.2438 16.4226C15.5692 16.748 16.0968 16.748 16.4223 16.4226C16.7477 16.0971 16.7477 15.5695 16.4223 15.2441L11.1782 9.99998L16.4223 4.7559Z' fill='#E14337' />
                      </svg>
                    </button>
                  </div>
                )
              })}
            </div>
            <div className='inputForm view-form row-4 col'>
              {/* row-3 */}
              <div className='header row'>
                <div className='label flex1 heading-7'>Lý lịch làm việc</div>
                <button type='button' className='text-button' onClick={addWork} >
                  Thêm
                </button>
              </div>
              {watch('work').map((item, index) => <div style={{ width: '100%' }} key={uuidv4()} className='list-input-container container-border row' >
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={`work[${index}].companyName`}
                    label={'Tên công ty'}
                    required={false}
                  />
                </div>

                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={`work[${index}].workPosition`}
                    label={'Vị trí làm việc'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={`work[${index}].career`}
                    label={'Công việc'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={`work[${index}].workTime`}
                    label={'Thời gian làm việc'}
                    placeholder={'Từ năm - đến năm'}
                    required={false}
                  />
                </div>
                <button type='button' onClick={() => {
                  const workList = getValues('work')
                  setValue('work', [...workList.slice(0, index), ...workList.slice(index + 1)])
                }} className='remove-block row' >
                  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' >
                    <path fillRule='evenodd' clipRule='evenodd' d='M16.4223 4.7559C16.7477 4.43047 16.7477 3.90283 16.4223 3.57739C16.0968 3.25195 15.5692 3.25195 15.2438 3.57739L9.99967 8.82147L4.7556 3.57739C4.43016 3.25195 3.90252 3.25195 3.57709 3.57739C3.25165 3.90283 3.25165 4.43047 3.57709 4.7559L8.82116 9.99998L3.57709 15.2441C3.25165 15.5695 3.25165 16.0971 3.57709 16.4226C3.90252 16.748 4.43016 16.748 4.7556 16.4226L9.99967 11.1785L15.2438 16.4226C15.5692 16.748 16.0968 16.748 16.4223 16.4226C16.7477 16.0971 16.7477 15.5695 16.4223 15.2441L11.1782 9.99998L16.4223 4.7559Z' fill='#E14337' />
                  </svg>
                </button>
              </div>
              )}
            </div>
            <div className='inputForm view-form row-4 col'>
              <div className='header row'>
                <div className='label flex1 heading-7'>
                  Thành viên trong gia đình
                </div>
                <button type='button' className='text-button' onClick={addFamily} >
                  Thêm
                </button>
              </div>
              {watch('familyMember').map((item, index) => <div style={{ width: '100%' }} key={uuidv4()} className='list-input-container container-border row' >
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={`familyMember[${index}].name`}
                    label={'Họ và tên'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    errors={errors}
                    label={'Mối quan hệ'}
                    value={watch(`familyMember[${index}].relation`)}
                    required={false}
                    name={`familyMember[${index}].relation`}
                    options={[
                      { name: 'Cha đẻ', id: '1' },
                      { name: 'Mẹ đẻ', id: '2' },
                      { name: 'Vợ', id: '3' },
                      { name: 'Chồng', id: '6' },
                      { name: 'Con trai', id: '4' },
                      { name: 'Con gái', id: '5' },
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={`familyMember[${index}].age`}
                    label={'Tuổi'}
                    type={'number'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={`familyMember[${index}].career`}
                    label={'Nghề nghiệp'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    errors={errors}
                    label={'Tình trạng chung sống'}
                    value={watch(`familyMember[${index}].situation`)}
                    required={false}
                    name={`familyMember[${index}].situation`}
                    options={[
                      { name: 'Sống chung', id: '1' },
                      { name: 'Sống riêng', id: '2' }
                    ]}
                  />
                </div>
                <button type='button' onClick={() => {
                  const famList = getValues('familyMember')
                  setValue('familyMember', [...famList.slice(0, index), ...famList.slice(index + 1)])
                }} className='remove-block row' >
                  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' >
                    <path fillRule='evenodd' clipRule='evenodd' d='M16.4223 4.7559C16.7477 4.43047 16.7477 3.90283 16.4223 3.57739C16.0968 3.25195 15.5692 3.25195 15.2438 3.57739L9.99967 8.82147L4.7556 3.57739C4.43016 3.25195 3.90252 3.25195 3.57709 3.57739C3.25165 3.90283 3.25165 4.43047 3.57709 4.7559L8.82116 9.99998L3.57709 15.2441C3.25165 15.5695 3.25165 16.0971 3.57709 16.4226C3.90252 16.748 4.43016 16.748 4.7556 16.4226L9.99967 11.1785L15.2438 16.4226C15.5692 16.748 16.0968 16.748 16.4223 16.4226C16.7477 16.0971 16.7477 15.5695 16.4223 15.2441L11.1782 9.99998L16.4223 4.7559Z' fill='#E14337' />
                  </svg>
                </button>
              </div>
              )}
            </div>
            <div className='inputForm view-form row-3 row'>
              {/* row-3 */}
              <div className='header row'>
                <div className='label flex1 heading-7'>Thông tin khác</div>
              </div>
              <div className='form-card'>
                <ReusableSelect1
                  control={control}
                  errors={errors}
                  label={'Gia đình có ủng hộ đi Nhật?'}
                  value={watch(`other.opinion`)}
                  required={false}
                  name={`other.opinion`}
                  // valueType={'bool'}
                  options={[
                    { id: '1', name: 'Có' },
                    { id: '0', name: 'Không' }
                  ]}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={`other.familyIncome`}
                  label={'Thu nhập của gia đình'}
                  required={false}
                  type={'number'}
                />
              </div>
              <div className='form-card'>
                <ReusableSelect1
                  register={register}
                  errors={errors}
                  control={control}
                  name={`other.relativesInJapan`}
                  value={watch('other.relativesInJapan')}
                  label={'Có người thân bạn bè ở Nhật?'}
                  required={false}
                  options={[{ id: '1', name: 'Có' }, { id: '0', name: 'Không' }]}
                />
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className='view-footer row'>
        <button
          type='button'
          className='close-button'
          onClick={() => {
            if (editable) {
              showDialog({
                ref: dialogRef,
                status: DialogStatus.WARNING,
                title: `Xác nhận thoát ${id ? 'chỉnh sửa' : 'tạo'} ứng viên?`,
                content: 'Thông tin ứng viên đã nhập sẽ không được lưu lại',
                submit: () => {
                  window.history.back()
                }
              })
            } else {
              window.history.back()
            }
          }}
        >
          <span className='button-text-3'>Thoát</span>
        </button>
        <div className='space'></div>
        <button type='submit' className='submit-button'>
          <span className='button-text-3'>Gửi</span>
        </button>
      </div>
    </form>
  )
}
