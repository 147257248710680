/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ActionType } from '../../core/ActionType';
import { ControllerDA } from '../../core/controller';
import { ReusableInput } from '../../component/TextField/text-field';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useForm } from 'react-hook-form';
import ConfigAPI from '../../config/configApi';
import notiUtil from '../../core/notiUtil';
import { EDIT, GET } from '../../assets/const/select-list';
import { editorConfiguration } from '../../component/ckeditor/ckeditor'

export default function BaiVietGioiThieu({ controller, type }) {
    const location = useLocation();
    const { register, setValue, handleSubmit, watch, reset, formState: { errors } } = useForm({
        shouldFocusError: false, defaultValues: {
            pictureId: '',
            title: '',
            content: ''
        }
    });
    const [preview, setPreview] = useState(null);
    const fileInputRef = useRef();

    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            setPreview(URL.createObjectURL(img));
            const resFile = await ControllerDA.uploadFile({ file: img });
            if (resFile) {
                setValue("pictureId", resFile.data.id)
            }
        }
    };

    const _InitData = async ({ page, size, query }) => {
        if (!query) {
            query = location.search;
        }
        const queryParams = new URLSearchParams(query);
        const filters = [];

        queryParams.forEach((value, key) => {
            filters.push({
                "field": key,
                "operator": "contains",
                "value": value,
            });
        });
        filters.push({
            "field": 'type',
            "operator": "contains",
            "value": type,
        })

        var obj = {
            "loadOptions": {
                "requireTotalCount": true,
                "skip": (page - 1) * size,
                "take": size,
            },
            "filter": filters,
            "Action": ActionType.GetAll.value
        }

        reset()

        const res = await ControllerDA.filterAction(ConfigAPI.newsUrl, controller, obj);
        if (res.code == 200) {
            Object.keys(res.data[0]).map(fieldName => {
                setValue(fieldName, res.data[0][fieldName])
                return 0;
            });
        }
    }

    const _onSubmit = async (event) => {
        event.Action = ActionType.Edit.value;
        const res = await ControllerDA.editAction(ConfigAPI.newsUrl, controller, event);

        if (res) {
            notiUtil.success("Tạo thông tin bài viết thành công")
        } else {
            notiUtil.success(res.message)
        }
    }


    useEffect(() => {
        setPreview(null)
        if (localStorage.getItem('listActive')?.includes(GET))
            _InitData({ page: 1, size: 100, query: location.search });
    }, [location.search, type]);

    return (
        <form className={`admin-add-view mainView col ${localStorage.getItem('listActive')?.includes(EDIT) ? '' : 'disabled'}`} onSubmit={handleSubmit(_onSubmit)}>
            <div className='view-header col'>
                <div className='view-name heading-6'>Giới thiệu về {type === 3 ? 'Nhật Bản' : type === 4 ? 'các hình thức đi Nhật' : 'DHM'}</div>
            </div>
            {localStorage.getItem('listActive')?.includes(GET) ? [<div key={'body'} className="view-content-container row">
                <div className="view-content col">
                    <div className='scroll-view-container col'>
                        <div className="inputForm view-form row-2 col" >
                            <div className='header row'>
                                <div className='label flex1 heading-7'>Thông tin chung</div>
                            </div>
                            <div className='row' style={{ gap: 16 }}>
                                <input ref={fileInputRef} type="file" onChange={handleImageChange} style={{ display: "none" }} />
                                <img className="banner-image" src={preview || (watch("pictureId") && `${ConfigAPI.fileUrl}api/UploadFile/img/${watch("pictureId")}`) || ""} alt='' />

                                {localStorage.getItem('listActive')?.includes(EDIT) ? <button type='button' className='upload-image row' onClick={() => { fileInputRef.current.click() }}>
                                    <svg width={14} height={13} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.66732 3.88889L7.00065 0L10.334 3.88889H7.55621V8.88889H6.4451V3.88889H3.66732Z" fill="#262626" />
                                        <path d="M12.5562 11.1111H1.4451V7.77778H0.333984V11.6667C0.333984 11.9733 0.582873 12.2222 0.88954 12.2222H13.1118C13.4184 12.2222 13.6673 11.9733 13.6673 11.6667V7.77778H12.5562V11.1111Z" fill="#262626" />
                                    </svg>
                                    <span className='button-text-3'>Tải ảnh lên</span>
                                </button> : null}
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"title"}
                                    label="Tiêu đề bài viết"
                                />
                            </div>
                        </div>
                        <div className="ckeditor-layout col" >
                            <div className='header row '>
                                <div className='label heading-7'>Nội dung</div>
                            </div>
                            <div className='ckeditor-form'>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={watch("content")}
                                    config={editorConfiguration}
                                    disabled={!localStorage.getItem('listActive')?.includes(EDIT)}

                                    onReady={editor => {
                                        console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        setValue("content", data)
                                        console.log({ event, editor, data });
                                    }}
                                    onBlur={(event, editor) => {
                                        console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        console.log('Focus.', editor);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            <div key={'footer'} className='view-footer row'>
                <div className='space'></div>
                <button type="submit" className='submit-button'><span className='button-text-3'>Lưu</span></button>
            </div>] : <div className='empty-page col'>
                <div className='img-empty'></div>
                <div className='heading-6'>Tài khoản không có quyền lấy dữ liệu</div>
            </div>}
        </form>
    )
}
