/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form";
import { NavLink, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import { ReusableInput, ReusableSelect1 } from "../../component/TextField/text-field";
import ConfigAPI from "../../config/configApi";
import { ControllerDA } from "../../core/controller";
import { ActionType } from "../../core/ActionType";
import { Dialog, DialogStatus, showDialog } from "../../component/dialog/dialog";
import notiUtil from "../../core/notiUtil";
import { ADD, EDIT } from "../../assets/const/select-list";

export default function NguoiDungMobileDoiTacForm({ controller }) {
    const { register, getValues, setValue, handleSubmit, formState: { errors } } = useForm({ shouldFocusError: false });
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [showPass, setShowPass] = useState(false)

    const fileInputRef = useRef();
    const { id } = useParams();
    const dialogRef = useRef()

    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            setPreview(URL.createObjectURL(img));
            const resFile = await ControllerDA.uploadFile({ file: img });
            if (resFile) {
                setValue("urlAvatar", resFile.data.id)
            } else {

            }
        }
    };

    const onSubmit = async () => {
        showDialog({
            ref: dialogRef,
            status: DialogStatus.INFOR,
            title: `Xác nhận ${id ? 'chỉnh sửa' : 'tạo'} đối tác?`,
            submit: () => {
                let data = getValues()
                submitAction(data)
            }
        })
    }


    const submitAction = event => {
        event.Email = "";
        event.type = 2
        if (id) {
            event.id = id;
            event.Action = ActionType.Edit.value;
        } else {
            event.Action = ActionType.Add.value;
        }
        ControllerDA.editAction(ConfigAPI.baseUrl, controller, event).then(res => {
            if (res.code === 200) {
                notiUtil.success(`${id ? 'Chỉnh sửa' : 'Tạo mới'} đối tác thành công`)
                window.history.back()
            } else {
                notiUtil.success(res.message)
            }
        })
    }
    const onError = () => { }

    useEffect(() => {
        if (id) {
            if (!localStorage.getItem('listActive')?.includes(EDIT)) notiUtil.errors('Tài khoản không có quyền chỉnh sửa')
            ControllerDA.getDetail(ConfigAPI.baseUrl, controller, { id: id, Action: ActionType.GetById.value }).then(res => {
                if (res) {
                    Object.keys(res.data).map(fieldName => {
                        setValue(fieldName, res.data[fieldName]);
                        return 0
                    });
                }
            })
        }
    }, [])

    const editable = (id && localStorage.getItem('listActive')?.includes(EDIT)) || (!id && localStorage.getItem('listActive')?.includes(ADD))
    return (
        <form className={`admin-add-view mainView col ${editable ? '' : 'disabled'}`} onSubmit={handleSubmit(onSubmit, onError)}>
            <Dialog ref={dialogRef} />
            <div className='view-header col'>
                <div className='view-name heading-6'>{id ? 'Chỉnh sửa' : 'Thêm mới'} người dùng mobile app</div>
                <div className='breadcrums row'>
                    <NavLink to={'/admin/quan-ly-nguoi-dung-mobile-app'} className='nav-link regular1' type='button'>Quản lý người dùng mobile app</NavLink>
                    <FontAwesomeIcon className='icon' size='2xs' icon={faChevronRight} />
                    <NavLink className='nav-link regular1' type='button'>{id ? 'Chỉnh sửa' : 'Thêm mới'} đối tác</NavLink>
                </div>
            </div>
            <div className="view-content-container row">
                {/* <div className="view-sidebar"></div> */}
                <div className="view-content col">
                    <div className='scroll-view-container col'>
                        <div className="inputForm view-form row-3 col" >
                            <div className='header row'>
                                <div className='label flex1 heading-7'>Thông tin chung</div>
                            </div>
                            <div className='left-item row' style={{ gap: 16 }}>
                                <input ref={fileInputRef} type="file" onChange={handleImageChange} style={{ display: "none" }} />
                                <img className='image' src={image || preview} alt="" />

                                {editable ? <button type='button' className='upload-image row' onClick={() => {
                                    fileInputRef.current.click();
                                }}>
                                    <svg width={14} height={13} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.66732 3.88889L7.00065 0L10.334 3.88889H7.55621V8.88889H6.4451V3.88889H3.66732Z" fill="#262626" />
                                        <path d="M12.5562 11.1111H1.4451V7.77778H0.333984V11.6667C0.333984 11.9733 0.582873 12.2222 0.88954 12.2222H13.1118C13.4184 12.2222 13.6673 11.9733 13.6673 11.6667V7.77778H12.5562V11.1111Z" fill="#262626" />
                                    </svg>
                                    <span className='button-text-3'>Tải ảnh lên</span>
                                </button> : null}
                            </div>

                            <div className='list-input-container row'>
                                <div className='form-card'>
                                    <ReusableInput
                                        register={register}
                                        errors={errors}
                                        name={'name'}
                                        label={'Họ và tên'}
                                    />
                                </div>
                                <div className='form-card'>
                                    <ReusableInput
                                        register={register}
                                        errors={errors}
                                        name={'mobile'}
                                        label={'Số điện thoại'}
                                        required={false}
                                    />
                                </div>
                                <div className='form-card'>
                                    <ReusableInput
                                        register={register}
                                        errors={errors}
                                        name={'work'}
                                        label={'Tên công ty'}
                                        required={false}
                                    />
                                </div>
                                <div className='form-card'>
                                    <ReusableInput
                                        register={register}
                                        errors={errors}
                                        name={'workAddress'}
                                        label={'Địa chỉ công ty'}
                                        required={false}
                                    />
                                </div>
                                <div className='form-card'>
                                    <ReusableInput
                                        register={register}
                                        errors={errors}
                                        name={'userName'}
                                        label={'Tên tài khoản'}
                                        disabled={id != null}
                                    />
                                </div>
                                <div className='form-card'>
                                    <ReusableInput
                                        register={register}
                                        errors={errors}
                                        name={'password'}
                                        label={'Mật khẩu'}
                                        type={showPass ? 'text' : 'password'}
                                        sufix={
                                            <div className='box32 center' onClick={() => setShowPass(!showPass)} >
                                                <FontAwesomeIcon size='xs' icon={showPass ? faEyeSlash : faEye} />
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='view-footer row'>
                <button type='button' className='close-button' onClick={() => {
                    if (editable) {
                        showDialog({
                            ref: dialogRef,
                            status: DialogStatus.WARNING,
                            title: `Xác nhận thoát ${id ? 'chỉnh sửa' : 'tạo'} đối tác?`,
                            content: 'Thông tin đối tác đã nhập sẽ không được lưu lại',
                            submit: () => {
                                window.history.back()
                            }
                        })
                    } else {
                        window.history.back()
                    }
                }}><span className='button-text-3'>Thoát</span></button>
                <div className='space'></div>

                <button type="submit" className='submit-button'><span className='button-text-3'>Gửi</span></button>
            </div>
        </form>
    )
}