import React, { useState } from 'react';
import './login-screen.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { LoginDA } from './loginDA';
import encrypt from "../../core/encrypt";
import { ControllerDA } from '../../core/controller';
import ConfigAPI from '../../config/configApi';
import { activeAction } from '../../assets/const/select-list';

export default function LoginScreen() {
    const [loginForm, setLoginForm] = useState({ username: "", password: "" })
    const [passwordShow, setpasswordShow] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleLogin = async (ev) => {
        ev.preventDefault();
        if (loading) {
            return
        } else {
            setLoading(true);
            if (loginForm.username === "" || loginForm.password === "") {
                setLoginForm({})
            } else {
                const res = await LoginDA.login({ data: loginForm });
                if (res.code === 200) {
                    localStorage.setItem("token", res.data);
                    let userInfor = await ControllerDA.doAction(ConfigAPI.customerUrl, 'Customer', { Action: 'GetInfo' })
                    localStorage.setItem('customer', JSON.stringify(userInfor.data))
                    let lstModule = await ControllerDA.doAction(ConfigAPI.customerUrl, 'Module', { Action: 'GetListSimple_new' })
                    localStorage.setItem(
                        "_t",
                        encrypt.encryptData(JSON.stringify(lstModule))
                    );
                    localStorage.setItem(
                        "listActive",
                        lstModule[0] ? (lstModule[0]?.listActive ?? activeAction.map(e => e.id).join(',')) : ''
                    );
                    window.location.href = '/admin/' + (lstModule.find(e => e.link?.length > 0)?.link ?? '')
                }
            }
            setLoading(false);
        }
    }


    return (
        <div className="loginScreen col">
            <div className="loginFormContainer col center">
                <h1 className="text-title heading-2">Đăng nhập</h1>
                <form className='formLogin col' onSubmit={handleLogin}>
                    <div className='TextFieldContainer col'>
                        <div className='label-container row'>
                            <span className='regular2'>Tên đăng nhập</span>
                            <span className='required-icon regular2'>*</span>
                        </div>
                        <div className='input-container row'>
                            <div className={`input-border row ${loginForm.username == null && "helper-text"}`}>
                                <input
                                    className='regular1'
                                    name='name'
                                    type='text'
                                    value={loginForm.username}
                                    placeholder='Nhập tên đăng nhập'
                                    onChange={(event) => {
                                        if (event.target.value !== '') {
                                            setLoginForm({ ...loginForm, username: event.target.value })
                                        } else {
                                            setLoginForm({ ...loginForm, username: null })
                                        }
                                    }}
                                />
                                {loginForm.username == null && <div className='helper-text regular1'>Vui lòng nhập tên đăng nhập</div>}
                            </div>
                        </div>
                    </div>
                    <div className='TextFieldContainer col'>
                        <div className='label-container row'>
                            <span className='regular2'>Mật khẩu</span>
                            <span className='required-icon regular2'>*</span>
                        </div>
                        <div className='input-container row'>
                            <div className={`input-border row ${loginForm.password == null && "helper-text"}`}>
                                <input
                                    className='regular1'
                                    name='name'
                                    type={passwordShow ? "text" : "password"}
                                    value={loginForm.password}
                                    placeholder='Nhập mật khẩu'
                                    onChange={(event) => {
                                        if (event.target.value !== '') {
                                            setLoginForm({ ...loginForm, password: event.target.value })
                                        } else {
                                            setLoginForm({ ...loginForm, password: null })
                                        }
                                    }}
                                />
                                <div className='box32 center' onClick={() => setpasswordShow(!passwordShow)}>
                                    <FontAwesomeIcon size='xs' icon={passwordShow ? faEyeSlash : faEye} />
                                </div>
                                {loginForm.password == null && <div className='helper-text regular1'>Vui lòng nhập mật khẩu</div>}
                            </div>
                        </div>
                    </div>
                    <button type='submit' className={`formButton button-text-3 ${loading ? "loading" : ""}`}>
                        {loading ? <FontAwesomeIcon icon={faSpinner} spin color='red' /> : "Đăng nhập"}
                    </button>
                </form>
                <div className='forgot-pass-container'>
                    {/* <NavLink to={''} className={"nav-link regular1"}>Quên mật khẩu</NavLink> */}
                </div>
                <div className='signup-container'>
                    {/* <span className='regular1'>Chưa có tài khoản? <button className='text-button'>Đăng ký ngay</button></span> */}
                </div>
            </div>
        </div >
    );
}
