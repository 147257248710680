/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import './main-layout.css';

import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import routes, { getUrlRouter, getcomponentRouter } from "../../router/router";
import SidebarView from "../../layout/sidebar";
import HeaderView from './../../layout/header';
import encrypt from "../../core/encrypt";
import { useEffect } from 'react';
import { menu_list } from '../../assets/const/menu-list';
import NguoiDungMobileDoiTacForm from '../../view/QuanLyNguoiDungMobileApp/FormDoiTac';
import NguoiDungMobileNoiBoForm from '../../view/QuanLyNguoiDungMobileApp/FormNoiBo';
import DanhSachUngVienTrongDonHang from '../../view/QuanLyNguoiDungTrongDonHang/QuanLyNguoiDungTrongDonHang';
import DanhSachUngVienDoiTac from '../../view/QuanLyNguoiDungMobileApp/DanhSachUngVienDoiTac';
import QuanLyLopHocForm from '../../view/QuanLyLopHoc/Form';
import { ADD, GET } from '../../assets/const/select-list';

export default function MainLayout() {
    const [lstRouter, setrouterList] = useState([...routes]);
    var menuList = JSON.parse(
        encrypt.decryptData(localStorage.getItem("_t"))
    );
    // var menuList = menu_list;
    useEffect(() => {
        console.log(menuList);
        var lst = [...lstRouter];
        menuList.filter(c => menuList.every(e => c.id !== e.parentId)).map(element => {
            var item = [];
            if (localStorage.getItem('listActive')?.includes(GET)) {
                item.push({
                    path: '/' + getUrlRouter(element.link) + '/Edit/:id',
                    name: element.name,
                    components: getcomponentRouter(element.link, "Edit")
                })
            }
            if (localStorage.getItem('listActive')?.includes(ADD)) {
                item.push({
                    path: '/' + getUrlRouter(element.link) + '/Add',
                    name: element.name,
                    components: getcomponentRouter(element.link, "Add")
                })
            }
            var extend = [];
            if (element.link === "quan-ly-nguoi-dung-mobile-app") {
                extend = []
                if (localStorage.getItem('listActive')?.includes(GET)) {
                    extend.push(
                        {
                            path: '/quan-ly-nguoi-dung-mobile-app/FormNoiBo/:id',
                            name: element.name,
                            components: <NguoiDungMobileNoiBoForm controller={"Customer"} />
                        },
                        {
                            path: '/quan-ly-nguoi-dung-mobile-app/FormDoiTac/:id',
                            name: element.name,
                            components: <NguoiDungMobileDoiTacForm controller={"Customer"} />
                        }
                    )
                    if (localStorage.getItem('listActive')?.includes(ADD)) {
                        extend.push(
                            {
                                path: '/quan-ly-nguoi-dung-mobile-app/FormNoiBo',
                                name: element.name,
                                components: <NguoiDungMobileNoiBoForm controller={"Customer"} />
                            },
                            {
                                path: '/quan-ly-nguoi-dung-mobile-app/FormDoiTac',
                                name: element.name,
                                components: <NguoiDungMobileDoiTacForm controller={"Customer"} />
                            }
                        )
                    }
                }
            }
            if (localStorage.getItem('listActive')?.includes(GET)) {
                extend.push(
                    {
                        path: '/quan-ly-nguoi-dung-trong-don-hang/:id',
                        name: element.name,
                        components: <DanhSachUngVienTrongDonHang controller={"Customer"} />
                    },
                    {
                        path: '/danh-sach-nguoi-dung-doi-tac-chon/:id',
                        name: element.name,
                        components: <DanhSachUngVienDoiTac controller={"Customer"} />
                    },
                    {
                        path: '/thong-tin-ung-vien/:id',
                        name: element.name,
                        components: <QuanLyLopHocForm controller={"Customer"} />
                    },
                )
            }
            lst.push(...item, ...extend);
            // lst.push(...item);
            return 0;
        });
        setrouterList(lst);
    }, []);

    return (
        <div className="main-layout row">
            <SidebarView />
            <div className="content-view col">
                <div className='header'>
                    <HeaderView />
                </div>
                <div className='content'>
                    <Routes>
                        {menuList.filter(c => menuList.every(e => c.id !== e.parentId)).map((prop, key) => {

                            return (
                                <Route
                                    path={getUrlRouter(prop.link)}
                                    element={getcomponentRouter(prop.link)}
                                    key={key}
                                    exact
                                />
                            );
                        })}
                        {
                            lstRouter.map((prop, key) => {
                                // debugger
                                return (
                                    <Route
                                        path={prop.path}
                                        element={prop.components}
                                        key={key}
                                        exact
                                    />
                                );
                            })
                        }
                    </Routes>
                </div>
            </div>
        </div>
    );
}