// Processing = 1,
//         Draft = 2,
//         Success = 3,
//         Cancel = 4,
export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16)
    )
}

export function getStatus(status) {
    switch (status) {
        case 1:
            return 'Lưu nháp'
        case 2:
            return 'Chờ phê duyệt'
        case 3:
            return 'Đã phê duyệt'
        case 4:
            return 'Từ chối'
        default:
            return '';
    }
}
export const YEU_CAU_NHAP_kho = 1;
export const NHAP_kho = 2;
export const YEU_CAU_XUAT_kho = 3;
export const XUAT_KHO = 4;

export const LUU_NHAP = 1;
export const CHO_DUYET = 2;
export const THANH_CONG = 3;
export const TU_CHOI = 4;
