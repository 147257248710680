/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ControllerDA } from '../../core/controller'
import { ActionType } from '../../core/ActionType'
import ConfigAPI from '../../config/configApi'
import { ReusableInput } from '../../component/TextField/text-field'
import { useForm } from 'react-hook-form'
import { Dialog, DialogStatus, showDialog } from '../../component/dialog/dialog'
import notiUtil from '../../core/notiUtil'
import { EDIT, GET } from '../../assets/const/select-list'

export default function QuanLyBanner({ controller }) {
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors }
  } = useForm({ shouldFocusError: false })
  const dialogRef = useRef()
  const videoRef = useRef();
  const linkRef = useRef()
  const location = useLocation()

  const [data, setData] = useState([])
  const [selectedTab, setSelectedTab] = useState(0)

  const _InitData = async ({ page, size, query }) => {
    if (!query) {
      query = location.search
    }
    const queryParams = new URLSearchParams(query)
    const filters = []

    queryParams.forEach((value, key) => {
      filters.push({
        field: key,
        operator: 'contains',
        value: value
      })
    })

    var obj = {
      loadOptions: {
        requireTotalCount: true,
        skip: (page - 1) * size,
        take: size
      },
      filter: filters,
      Action: ActionType.GetAll.value
    }

    const res = await ControllerDA.doAction(
      ConfigAPI.customerUrl,
      controller,
      obj,
      1
    )
    if (res.code == 200) {
      setData(res.data)
      setValue('link', res.data[selectedTab].link)
      setValue('name', res.data[selectedTab].name)
      setValue('url', res.data[selectedTab].url)
    }
  }

  async function handleUpLoadImg(ev) {
    if (ev.target.files && ev.target.files[0]) {
      let img = ev.target.files[0]
      if (img.size > 20000000) {
        notiUtil.errors('Tệp vượt quá dung lượng upload tối đa (20Mb)')
        return
      }
      const resFile = await ControllerDA.uploadFile({ file: img })
      if (resFile) {
        setData(
          data.map((e, i) => {
            if (i === selectedTab) {
              e.url = `${ConfigAPI.fileUrl}${resFile.data.filePath}`
              e.link = img.type
              setValue('link', img.type)
              setValue('url', e.url)
            }
            return e
          })
        )
      }
    }
  }

  const _submitForm = () => {
    showDialog({
      ref: dialogRef,
      status: DialogStatus.INFOR,
      title: `Xác nhận chỉnh sửa thông tin banner ${watch('name')}?`,
      submit: () => {
        let values = getValues()
        values.type = 1
        submitAction({ ...data[selectedTab], ...values })
      }
    })
  }

  const submitAction = event => {
    event.Action = ActionType.Edit.value
    ControllerDA.editAction(ConfigAPI.customerUrl, controller, event).then(
      res => {
        if (res.code === 200) {
          notiUtil.success(res.message)
        } else {
          notiUtil.success(res.message)
        }
      }
    )
  }
  const onError = () => { }

  useEffect(() => {
    if (localStorage.getItem('listActive')?.includes(GET))
      _InitData({
        page: 1,
        size: 10,
        query: location.search
      })
  }, [location.search])

  return (
    <form className={`mainView col ${localStorage.getItem('listActive')?.includes(EDIT) ? '' : 'disabled'}`} onSubmit={handleSubmit(_submitForm, onError)} >
      <Dialog ref={dialogRef} />
      <div className='view-header col'>
        <div className='view-name heading-6'>Quản lý banner</div>
      </div>
      {localStorage.getItem('listActive')?.includes(GET) ? <div className='view-content-container row'>
        <div className='view-content col'>
          <div className='inputForm view-form row-2 row'>
            <div className='header col'>
              <div className='label flex1 heading-7'>Thông tin banner</div>
              <div className='subtitle regular2'>
                {'Kích thước ảnh đề xuất w:382 - h:214'}
              </div>
            </div>
            <div className='form-card'>
              <ReusableInput
                register={register}
                errors={errors}
                name={'name'}
                label={'Tên banner'}
              />
            </div>
            <div className='form-card'>
              <ReusableInput
                register={register}
                errors={errors}
                name={'url'}
                label={'Liên kết'}
                required={false}
                onBlur={ev => {
                  setValue('url', ev.target.value)
                }}
              />
            </div>
          </div>
          <div className='listView col'>
            <div className='row tab-container'>
              {data.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className={`button-tab semibold2 ${selectedTab === index ? 'selected' : ''}`}
                    onClick={() => {
                      setSelectedTab(index)
                      setValue('link', item.link)
                      setValue('name', item.name)
                      setValue('url', item.url)
                    }}
                  >{`Banner ${index + 1}`}</div>
                )
              })}
            </div>
            <div className='col' style={{ alignItems: 'center', rowGap: '16px' }} >
              {
                watch('link')?.split('/')?.includes('video') ?
                  <div className='banner-img-demo'>
                    <Clip url={watch('url')} type={watch('link')} />
                  </div> :
                  <div className='banner-img-demo' style={{ backgroundImage: `url(${watch('url')})` }} ></div>
              }
              {localStorage.getItem('listActive')?.includes(EDIT) ? [<button key={'upload'} type='button' className='upload-image row' style={{ width: 200, justifyContent: 'center' }} onClick={() => { linkRef.current.click() }} >
                <input
                  type='file'
                  style={{ display: 'none' }}
                  ref={linkRef}
                  onChange={handleUpLoadImg}
                  accept='image/png, image/jpeg, image/jpg, video/mp4'
                />
                <span className='button-text-3'>Tải ảnh/video lên</span>
                <svg width={14} height={13} viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg' >
                  <path d='M3.66732 3.88889L7.00065 0L10.334 3.88889H7.55621V8.88889H6.4451V3.88889H3.66732Z' fill='#262626' />
                  <path d='M12.5562 11.1111H1.4451V7.77778H0.333984V11.6667C0.333984 11.9733 0.582873 12.2222 0.88954 12.2222H13.1118C13.4184 12.2222 13.6673 11.9733 13.6673 11.6667V7.77778H12.5562V11.1111Z' fill='#262626' />
                </svg>
              </button>,
              <button key={'save'} type='submit' style={{ width: 200, justifyContent: 'center' }} className='upload-image row' >
                <span className='button-text-3'>Lưu</span>
              </button>] : null}
            </div>
          </div>
        </div>
      </div> : <div className='empty-page col'>
        <div className='img-empty'></div>
        <div className='heading-6'>Tài khoản không có quyền lấy dữ liệu</div>
      </div>}
    </form>
  )
}

function Clip({ url, type }) {
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [url, type]);

  return (
    <video ref={videoRef} width={'100%'} height={'100%'} controls autoplay >
      <source src={url} type={type} />
    </video>
  );
}