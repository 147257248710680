/* eslint-disable eqeqeq */

import { SelectMultiple } from '../input-multi-select/input-multi-select';
import { Select1 } from '../select1/select1';
import Select2 from './../select2/Select2';
import { Controller } from 'react-hook-form';

function parseValue(valueType, value) {
    if (value == null || value === '') {
        return null
    }
    switch (valueType) {
        case 'int':
            return parseInt(value, 10)
        case 'float':
            return parseFloat(value)
        case 'bool':
            return value == true
        default:
            return value ?? ""
    }
}

export function ReusableInput({ defaultValue, label, register, required = true, name, type, placeholder, errors, maxLength, readOnly = false, disabled = false, valueType, sufix, onChange, onClick, onBlur, autoComplete = false }) {
    return (
        <div className={`TextFieldContainer col ${disabled ? 'disabled' : ''}`}>
            <div className='label-container row'>
                <span className='label-3'>{label}</span>
                {required && <span className='required-icon regular2'>*</span>}
            </div>
            <div className='input-container row'>
                <div className={`input-border row ${errors[name] && 'helper-text'}`}>
                    <input
                        autoComplete={autoComplete ? 'on' : 'new-password'}
                        className='regular1'
                        {...register(name, {
                            required: required ? `Vui lòng nhập ${label.toLowerCase()}` : null,
                            setValueAs: value => parseValue(valueType, value),
                            onBlur: onBlur
                        })}
                        maxLength={maxLength}
                        name={name}
                        type={type ?? 'text'}
                        defaultValue={defaultValue}
                        placeholder={placeholder ?? (label || '')}
                        readOnly={readOnly}
                        disabled={disabled}
                        onChange={onChange ? onChange : () => { }}
                        onClick={onClick ? onClick : () => { }}
                    />
                    {sufix}
                    {errors[name] && <div className='helper-text regular1'>{errors[name].message}</div>}
                </div>
            </div>
        </div>
    )
}

export function ReusableTextArea({ getErrors, defaultValue, label, register, required = true, name, type, placeholder, errors, maxLength, readOnly = false, disabled = false, valueType, sufix, onChange, onClick, onBlur }) {
    const _errors = getErrors ? getErrors(errors) : errors[name];
    return (
        <div className={`TextFieldContainer col ${disabled ? 'disabled' : ''}`}>
            <div className='label-container row'>
                <span className='label-3'>{label}</span>
                {required && <span className='required-icon regular2'>*</span>}
            </div>
            <div className='input-container row'>
                <div className={`input-border row ${_errors && 'helper-text'}`}>
                    <textarea
                        autoComplete='off'
                        className='regular1'
                        {...register(name, {
                            required: required ? `Vui lòng nhập ${label.toLowerCase()}` : null,
                            setValueAs: value => parseValue(valueType, value),
                            onBlur: onBlur
                        })}
                        maxLength={maxLength}
                        name={name}
                        type={type ?? 'text'}
                        defaultValue={defaultValue ?? ""}
                        placeholder={placeholder ?? (label || '')}
                        readOnly={readOnly}
                        disabled={disabled}
                        onChange={onChange ? onChange : () => { }}
                        onClick={onClick ? onClick : () => { }}
                    />
                    {sufix}
                    {_errors && <div className='helper-text regular1'>{_errors.message}</div>}
                </div>
            </div>
        </div>
    )
}

export function ReusableDoubleInput({ defaultValue1, defaultValue2, label, register, required = true, name1, name2, type, placeholder1, placeholder2, errors, maxLength, readOnly = false, disabled = false, valueType, sufix1, sufix2, spacing, onChange1, onChange2
}) {
    return (
        <div className={`TextFieldContainer col ${disabled ? 'disabled' : ''}`}>
            <div className='label-container row'>
                <span className='label-3'>{label}</span>
                {required && <span className='required-icon regular2'>*</span>}
            </div>
            <div className='input-container row'>
                <div className={`input-border row ${errors[name1] && 'helper-text'}`}>
                    <input
                        autoComplete='off'
                        className='regular1'
                        {...register(name1, {
                            required: required ? `Vui lòng nhập ${placeholder1.toLowerCase()}` : null,
                            setValueAs: value => parseValue(valueType, value)
                        })}
                        maxLength={maxLength}
                        name={name1}
                        type={type ?? 'text'}
                        placeholder={placeholder1}
                        defaultValue={defaultValue1}
                        readOnly={readOnly}
                        disabled={disabled}
                        onChange={onChange1 ? onChange1 : () => { }}
                    />
                    {sufix1}
                    {errors[name1] && <div className='helper-text regular1'>{errors[name1].message}</div>}
                </div>
                {spacing ?? '-'}
                <div className={`input-border row ${errors[name2] && 'helper-text'}`}>
                    <input
                        autoComplete='off'
                        className='regular1'
                        {...register(name2, {
                            required: required ? `Vui lòng nhập ${placeholder2.toLowerCase()}` : null,
                            setValueAs: value => parseValue(valueType, value)
                        })}
                        maxLength={maxLength}
                        name={name2}
                        type={type ?? 'text'}
                        placeholder={placeholder2}
                        defaultValue={defaultValue2}
                        readOnly={readOnly}
                        disabled={disabled}
                        onChange={onChange2 ? onChange2 : () => { }}
                    />
                    {sufix2}
                    {errors[name2] && <div className='helper-text regular1'>{errors[name2].message}</div>}
                </div>
            </div>
        </div>
    )
}

export function ReusableSelect2({ name, label, value, control, errors, options, placeholder, valueType, required = true, onChange }) {
    return (
        <div className='TextFieldContainer col'>
            <div className='label-container row'>
                <span className='label-3'>{label}</span>
                {required && <span className='required-icon regular2'>*</span>}
            </div>
            <div className='input-container row'>
                <div className={`input-border row select2 ${errors[name] && 'helper-text'}`}>
                    <Controller
                        name={name}
                        control={control}
                        rules={{ required: required === true ? `Vui lòng nhập ${label.toLowerCase()}` : null }}
                        render={({ field }) => (
                            <Select2
                                {...field}
                                data={options}
                                value={`${value}`}
                                options={{ placeholder: placeholder ?? "Chọn " + label.toLowerCase() }}
                                onChange={(ev) => {
                                    if (ev.target.value != '') {
                                        if (valueType == 'bool') {
                                            field.onChange(ev.target.value == 'true')
                                        } else {
                                            ev.target.value = parseValue(valueType, ev.target.value)
                                            field.onChange(ev.target.value)
                                        }
                                        if (onChange) {
                                            onChange(ev.target.value);
                                        }
                                    }
                                }}
                            />
                        )}
                    />
                    {errors[name] && (<div className='helper-text regular1'>{errors[name].message}</div>)}
                </div>
            </div>
        </div>
    )
}

export function ReusableSelect1({ name, label, value, control, errors, options, placeholder, required = true, onChange, disabled = false }) {
    return (
        <div className={`TextFieldContainer col ${disabled ? 'disabled' : ''}`}>
            <div className='label-container row'>
                <span className='label-3'>{label}</span>
                {required && <span className='required-icon regular2'>*</span>}
            </div>
            <div className='input-container row'>
                <Select1
                    disabled={disabled}
                    value={value}
                    placeholder={placeholder ?? `Chọn ${label.toLowerCase()}`}
                    label={label}
                    name={name}
                    control={control}
                    errors={errors}
                    options={options}
                    onChange={onChange}
                    required={required}
                />
            </div>
        </div>
    )
}

export function ReusableSelectMultiple({ name, label, value, control, errors, options, placeholder, required = true, onChange, disabled = false }) {
    return (
        <div className={`TextFieldContainer col ${disabled ? 'disabled' : ''}`}>
            <div className='label-container row'>
                <span className='label-3'>{label}</span>
                {required && <span className='required-icon regular2'>*</span>}
            </div>
            <div className='input-container row'>
                <SelectMultiple
                    disabled={disabled}
                    value={value}
                    placeholder={placeholder ?? `Chọn ${label.toLowerCase()}`}
                    label={label}
                    name={name}
                    control={control}
                    errors={errors}
                    options={options}
                    onChange={onChange}
                    required={required}
                />
            </div>
        </div>
    )
}