/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form'
import {
  ReusableInput,
  ReusableSelect1,
} from '../../component/TextField/text-field'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import {
  Dialog,
  DialogStatus,
  showDialog
} from './../../component/dialog/dialog'
import ConfigAPI from '../../config/configApi'
import { ControllerDA } from '../../core/controller'
import { ActionType } from '../../core/ActionType'
import notiUtil from './../../core/notiUtil'
import { ADD, EDIT, listJobTitle } from '../../assets/const/select-list'
import { postData } from '../../core/BaseDA'

export default function QuanLyNguoiDungHeThongForm({ controller }) {
  const location = useLocation()

  const {
    register,
    control,
    setValue,
    handleSubmit,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({ shouldFocusError: false })

  const { id } = useParams()

  const dialogRef = useRef()

  const [showPass, setShowPass] = useState(false)

  const _submitForm = () => {
    showDialog({
      ref: dialogRef,
      status: DialogStatus.INFOR,
      title: `Xác nhận ${id ? 'chỉnh sửa' : 'tạo'} thông tin người dùng hệ thống?`,
      submit: () => {
        let data = getValues()
        submitAction(data)
      }
    })
  }

  const submitAction = event => {
    event.type = 0
    if (id) {
      event.Action = ActionType.Edit.value
      ControllerDA.editAction(ConfigAPI.customerUrl, controller, event).then(
        res => {
          if (res) {
            window.history.back()
          } else {
            notiUtil.success(res.message)
          }
        }
      )
    } else {
      event.dateCreated = new Date().getTime()
      event.Action = ActionType.Add.value
      ControllerDA.doAction(ConfigAPI.customerUrl, controller, event).then(
        res => {
          if (res) {
            window.history.back()
          } else {
            notiUtil.success(res.message)
          }
        }
      )
    }
  }
  const onError = () => { }

  useEffect(() => {
    if (id) {
      if (!localStorage.getItem('listActive')?.includes(EDIT)) notiUtil.errors('Tài khoản không có quyền chỉnh sửa')
      ControllerDA.getDetail(ConfigAPI.customerUrl, controller, {
        id: id,
        Action: ActionType.GetById.value
      }).then(res => {
        if (res) {
          Object.keys(res.data).forEach(fieldName => {
            if (fieldName === 'referralCode') setValue('initReferralCode', res.data[fieldName])
            setValue(fieldName, res.data[fieldName])
          })
        }
      })
    }
  }, [location.pathname])

  const editable = (id && localStorage.getItem('listActive')?.includes(EDIT)) || (!id && localStorage.getItem('listActive')?.includes(ADD))
  return (
    <form className={`admin-add-view mainView col ${editable ? '' : 'disabled'}`} onSubmit={handleSubmit(_submitForm, onError)} >
      <Dialog ref={dialogRef} />
      <div className='view-header col'>
        <div className='view-name heading-6'>
          {id
            ? 'Chỉnh sửa thông tin người dùng hệ thống'
            : 'Thêm mới người dùng hệ thống'}
        </div>
        <div className='breadcrums row'>
          <NavLink
            to={'/admin/quan-ly-ho-so-ung-vien'}
            className='nav-link regular1'
            type='button'
          >
            Quản lý thông tin người dùng hệ thống
          </NavLink>
          <FontAwesomeIcon className='icon' size='2xs' icon={faChevronRight} />
          <NavLink className='nav-link regular1' type='button'>
            {id
              ? 'Chỉnh sửa thông tin người dùng hệ thống'
              : 'Thêm mới người dùng hệ thống'}
          </NavLink>
        </div>
      </div>
      <div className='view-content-container row'>
        {/* <div className="view-sidebar"></div> */}
        <div className='view-content col'>
          <div className='scroll-view-container col'>
            <div className='inputForm view-form row-3 row'>
              <div className='header row'>
                <div className='label flex1 heading-7'>Thông tin chung</div>
              </div>
              <div className='list-input-container row'>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'name'}
                    label={'Họ và tên'}
                    disabled={id != null}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'referralCode'}
                    label={'Mã người dùng'}
                    required={false}
                    onBlur={(ev) => {
                      const newReferCode = ev.target.value.trim()
                      if (getValues('initReferralCode') !== newReferCode && newReferCode?.length) {
                        postData(ConfigAPI.customerUrl + `Customer/CheckRegister?userName='dsfsfvdrgrg@r'&mobile=0&rereferralCode=${newReferCode}`, { obj: { Action: 'CheckRegister' } }).then(res => {
                          if (res.data === 3 && res.code !== 404) {
                            setError('referralCode', 'Mã nhân viên đã tồn tại')
                            notiUtil.errors('Mã nhân viên đã tồn tại')
                          }
                        })
                      } else clearErrors('referralCode')
                    }}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'userName'}
                    label={'Tên tài khoản'}
                    disabled={id != null}
                    onBlur={(ev) => {
                      postData(ConfigAPI.customerUrl + `Customer/CheckRegister?userName=${ev.target.value}&mobile=0&rereferralCode=0`, { obj: { Action: 'CheckRegister' } }).then(res => {
                        if (res.code !== 200 && res.data === 1) {
                          notiUtil.errors(res.message)
                          setError('userName', res.message)
                        }
                      })
                    }}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'password'}
                    label={'Mật khẩu'}
                    type={showPass ? 'text' : 'password'}
                    sufix={
                      <div
                        className='box32 center'
                        onClick={() => setShowPass(!showPass)}
                      >
                        <FontAwesomeIcon
                          size='xs'
                          icon={showPass ? faEyeSlash : faEye}
                        />
                      </div>
                    }
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    value={watch('title')}
                    name={'title'}
                    errors={errors}
                    required={false}
                    label={'Chức danh'}
                    options={listJobTitle}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='view-footer row'>
        <button
          type='button'
          className='close-button'
          onClick={() => {
            if (editable) {
              showDialog({
                ref: dialogRef,
                status: DialogStatus.WARNING,
                title: `Xác nhận thoát ${id ? 'chỉnh sửa' : 'tạo'} ứng viên?`,
                content: 'Thông tin ứng viên đã nhập sẽ không được lưu lại',
                submit: () => {
                  window.history.back()
                }
              })
            } else {
              window.history.back()
            }
          }}
        >
          <span className='button-text-3'>Thoát</span>
        </button>
        <div className='space'></div>
        <button type='submit' className='submit-button'>
          <span className='button-text-3'>Gửi</span>
        </button>
      </div>
    </form>
  )
}
