/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ListView from "./ListView";
import { getData, postData } from "../../core/BaseDA";
import ConfigAPI from "../../config/configApi";
import notiUtil from "../../core/notiUtil";
import { ControllerDA } from "../../core/controller";
import { ActionType } from "../../core/ActionType";
export default function DanhSachUngVienTrongDonHang() {
    const location = useLocation();
    const { id } = useParams();
    const [customerInProduct, setCustomerInProduct] = useState([])
    const [dataProduct, setDataProduct] = useState([]);

    const action = (item) => {
        ControllerDA.editAction(ConfigAPI.productUrl, 'CustomerProduct', { ...customerInProduct.find(e => e.customerId === item.id), Action: ActionType.Delete.value }).then(res => {
            if (res.code === 200) {
                notiUtil.success("Xóa ứng viên khỏi đơn hàng thành công")
                _InitData()
            } else {
                notiUtil.success(res.message)
            }
        })
    }


    const _InitData = async () => {
        const res = await getData(`${ConfigAPI.productUrl}CustomerProduct/ListByProductId?Id=` + id)
        if (res.code === 200) {
            setCustomerInProduct(res.data)
            const customerIds = res.data.map(item => item.customerId).filter(id => id !== null);
            const resCus = await postData(`${ConfigAPI.baseUrl}Customer/GetMutipleInfo`, { obj: customerIds });
            if (resCus.code === 200) {
                setDataProduct(resCus.data);
            }
        }
    }

    useEffect(() => {
        _InitData();
    }, [location.search])


    return (
        <div className="mainView col">
            <div className='view-header col'>
                <div className="view-name heading-6">Quản lý ứng viên trong đơn hàng</div>
            </div>
            <div className="view-content-container row">
                <div className="view-content col">
                    <div className="scroll-view-container col">
                        <div className="listView col">
                            <ListView
                                data={dataProduct}
                                callback_action={action}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}