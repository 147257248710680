import { NavLink } from "react-router-dom";
import { ActionType } from "./ActionType";

export default function GetAction({ controller, action, onClick, actionTitle }) {
    switch (action) {
        case ActionType.Add.value:
            return <NavLink to={ActionType.Add.value} className='nav-link addButton row button-text-3'>
                {actionTitle ?? "Thêm mới"}
                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.60574 1.93809C8.60574 1.60337 8.33439 1.33203 7.99967 1.33203C7.66496 1.33203 7.39361 1.60337 7.39361 1.93809V7.39264H1.93907C1.60435 7.39264 1.33301 7.66398 1.33301 7.9987C1.33301 8.33342 1.60435 8.60476 1.93907 8.60476H7.39361V14.0593C7.39361 14.394 7.66496 14.6654 7.99967 14.6654C8.33439 14.6654 8.60574 14.394 8.60574 14.0593V8.60476H14.0603C14.395 8.60476 14.6663 8.33342 14.6663 7.9987C14.6663 7.66398 14.395 7.39264 14.0603 7.39264H8.60574V1.93809Z" fill="white" />
                </svg>
            </NavLink>
        case ActionType.Edit.value:
            return <button className="box32" onClick={onClick} >
                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.48302 13.1176C3.51435 13.5375 3.70291 13.9301 4.01105 14.217C4.31918 14.5039 4.72421 14.664 5.14524 14.6654H10.8541C11.2752 14.664 11.6802 14.5039 11.9883 14.217C12.2965 13.9301 12.485 13.5375 12.5164 13.1176L13.0408 5.77648H2.95858L3.48302 13.1176Z" fill="#E60012" />
                    <path d="M14.1108 3.55425H10.7775V1.88759C10.7775 1.74024 10.7189 1.59894 10.6147 1.49475C10.5105 1.39056 10.3692 1.33203 10.2219 1.33203H5.77745C5.63011 1.33203 5.4888 1.39056 5.38462 1.49475C5.28043 1.59894 5.2219 1.74024 5.2219 1.88759V3.55425H1.88856C1.74122 3.55425 1.59991 3.61279 1.49573 3.71697C1.39154 3.82116 1.33301 3.96247 1.33301 4.10981C1.33301 4.25715 1.39154 4.39846 1.49573 4.50265C1.59991 4.60683 1.74122 4.66536 1.88856 4.66536H14.1108C14.2581 4.66536 14.3994 4.60683 14.5036 4.50265C14.6078 4.39846 14.6663 4.25715 14.6663 4.10981C14.6663 3.96247 14.6078 3.82116 14.5036 3.71697C14.3994 3.61279 14.2581 3.55425 14.1108 3.55425ZM6.33301 2.44314H9.66634V3.55425H6.33301V2.44314Z" fill="#E60012" />
                </svg>
            </button>
        case ActionType.Delete.value:
            return <button className="deleteButton box32" onClick={onClick}>
                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.48302 13.1176C3.51435 13.5375 3.70291 13.9301 4.01105 14.217C4.31918 14.5039 4.72421 14.664 5.14524 14.6654H10.8541C11.2752 14.664 11.6802 14.5039 11.9883 14.217C12.2965 13.9301 12.485 13.5375 12.5164 13.1176L13.0408 5.77648H2.95858L3.48302 13.1176Z" fill="#E60012" />
                    <path d="M14.1108 3.55425H10.7775V1.88759C10.7775 1.74024 10.7189 1.59894 10.6147 1.49475C10.5105 1.39056 10.3692 1.33203 10.2219 1.33203H5.77745C5.63011 1.33203 5.4888 1.39056 5.38462 1.49475C5.28043 1.59894 5.2219 1.74024 5.2219 1.88759V3.55425H1.88856C1.74122 3.55425 1.59991 3.61279 1.49573 3.71697C1.39154 3.82116 1.33301 3.96247 1.33301 4.10981C1.33301 4.25715 1.39154 4.39846 1.49573 4.50265C1.59991 4.60683 1.74122 4.66536 1.88856 4.66536H14.1108C14.2581 4.66536 14.3994 4.60683 14.5036 4.50265C14.6078 4.39846 14.6663 4.25715 14.6663 4.10981C14.6663 3.96247 14.6078 3.82116 14.5036 3.71697C14.3994 3.61279 14.2581 3.55425 14.1108 3.55425ZM6.33301 2.44314H9.66634V3.55425H6.33301V2.44314Z" fill="#E60012" />
                </svg>
            </button>
        default:
            return <NavLink to={action} className='nav-link addButton row button-text-3'>
                {actionTitle ?? "Thêm mới"}
                <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.60574 1.93809C8.60574 1.60337 8.33439 1.33203 7.99967 1.33203C7.66496 1.33203 7.39361 1.60337 7.39361 1.93809V7.39264H1.93907C1.60435 7.39264 1.33301 7.66398 1.33301 7.9987C1.33301 8.33342 1.60435 8.60476 1.93907 8.60476H7.39361V14.0593C7.39361 14.394 7.66496 14.6654 7.99967 14.6654C8.33439 14.6654 8.60574 14.394 8.60574 14.0593V8.60476H14.0603C14.395 8.60476 14.6663 8.33342 14.6663 7.9987C14.6663 7.66398 14.395 7.39264 14.0603 7.39264H8.60574V1.93809Z" fill="white" />
                </svg>
            </NavLink>
    }
}