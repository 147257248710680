import { NavLink } from 'react-router-dom'
import { ActionType } from '../../core/ActionType'
import { useEffect } from 'react'
import { Select1 } from '../../component/select1/select1'
import { EDIT, customerStudyStatus } from '../../assets/const/select-list'

export default function ListView({ data, callback_action, onChangeTab, tab, totalCount }) {
  useEffect(() => { }, [])
  return (
    <>
      <div className='listView-header row'>
        <div className='heading-7 space'>Danh sách kết quả</div>
        {/* <GetAction controller={controller} action={ActionType.Add.value} /> */}
      </div>
      <div className="row tab-container">
        {customerStudyStatus.map(t => {
          return <div key={t.id} className={`button-tab semibold2 ${tab === t.id ? "selected" : ""}`} onClick={() => { onChangeTab(t.id) }}>{t.name}</div>
        })}
      </div>
      <div className='listView-body col'>
        <table className='custom-table'>
          <thead>
            <tr>
              <th style={{ minWidth: 75 }} className='fixed-first-column'>
                <div className='center'>STT</div>
              </th>
              <th style={{ minWidth: 360 }} className=''>Tên ứng viên</th>
              <th style={{ minWidth: 150 }} className=''>Mã hồ sơ</th>
              <th style={{ minWidth: 150 }} className=''>CCCD</th>
              <th style={{ minWidth: 172 }} className=''>SĐT</th>
              <th style={{ minWidth: 150 }} className='center'>Trạng thái</th>
            </tr>
          </thead>
          <tbody>
            {(data ?? []).map((item, index) => {
              return (
                <tr key={index}>
                  <td style={{ minWidth: 75 }} className='fixed-first-column'>
                    <div className='center'>{index + 1}</div>
                  </td>
                  <td style={{ minWidth: 360 }} className=''>
                    <NavLink to={ActionType.Edit.value + `/${item.id}`} className={'nav-link'} >
                      {item.name ?? ''}
                    </NavLink>
                  </td>
                  <td style={{ minWidth: 150 }} className=''>
                    {item.code ?? ''}
                  </td>
                  <td style={{ minWidth: 150 }} className=''>
                    {item.idCard ?? ''}
                  </td>
                  <td style={{ minWidth: 172 }} className=''>
                    {item.mobile ?? ''}
                  </td>
                  <td style={{ minWidth: 150 }} className=''>
                    <Select1
                      style={{ width: '100%', maxWidth: 196 }}
                      options={[
                        { name: 'Chưa học', id: 1 },
                        { name: 'Đang học', id: 2 },
                        { name: 'Đã hoàn thành khóa học', id: 3 },
                        { name: 'Thôi học', id: 4 }
                      ]}
                      value={item.status}
                      disabled={!localStorage.getItem('listActive')?.includes(EDIT)}
                      placeholder='Chọn trạng thái'
                      onChange={ev => {
                        item.status = ev.id
                        callback_action(item)
                      }}
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
