/* eslint-disable react-hooks/exhaustive-deps */
import { useFieldArray, useForm } from "react-hook-form";
import { ReusableDoubleInput, ReusableInput, ReusableSelect1, ReusableTextArea } from "../../component/TextField/text-field";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import { Dialog, DialogStatus, showDialog } from './../../component/dialog/dialog';
import { Ultis } from "../../core/Utils";
import CheckboxComponent from './../../component/checkbox/checkbox';
import ReusableDatePicker from "../../component/date-picker/date-picker"
import { ADD, EDIT, listMarriedStatus, listRequirementForm, listStatusExam, notiProduct } from './../../assets/const/select-list';
import ConfigAPI from "../../config/configApi";
import { ControllerDA } from "../../core/controller";
import { ActionType } from "../../core/ActionType";
import notiUtil from './../../core/notiUtil';
import ImportFile from "../../component/import-file/import-file";

export default function QuanLyDonHangForm({ controller }) {
    const location = useLocation();

    const { register, control, setValue, handleSubmit, getValues, watch, formState: { errors } } = useForm({ shouldFocusError: false });
    const [pushNoti, setPushOnti] = useState(true)

    const { id } = useParams();

    const dialogRef = useRef();

    const handleUpdateProduct = async (event) => {
        event.id = id;
        event.Action = ActionType.Edit.value;
        delete event.fileItem
        const response = await ControllerDA.editAction(ConfigAPI.productUrl, controller, event);
        if (response.code === 200) {
            window.history.back();
            notiUtil.success("Cập nhật đơn hàng thành công")
        } else {
            notiUtil.success(response.message)
        }
    }

    const handleCreateProduct = async (event) => {
        event.Action = ActionType.Add.value;
        delete event.fileItem
        const response = await ControllerDA.doAction(ConfigAPI.productUrl, controller, event);
        if (response.code === 200) {
            if (pushNoti) {
                var data = {
                    "to": `/topics/${notiProduct}`,
                    "notification": {
                        "body": `Đơn hàng ${watch("name")} đang sẵn sàng. Hãy ứng tuyển ngay!`,
                        "title": "Bạn có 1 đơn hàng mới",
                        "icon": "https://cdn-icons-png.flaticon.com/256/124/124010.png"
                    },
                    "data": {
                        'type': notiProduct,
                        "click_action": "FLUTTER_NOTIFICATION_CLICK",
                        "body": "Body of Your Notification in Data",
                        "title": "Title of Your Notification in Title",
                        "url": "HOME2",
                        "key_2": "Value for key_2",
                        "pattner_id": "90349279303689920881142",
                        "orderId": response.data,
                    }
                };
                const res = await ControllerDA.pushNoti(data);
                if (res) {
                    const obj = {
                        "type": notiProduct,
                        "isRead": false,
                        "title": data.notification.title,
                        "dateCreated": new Date().getTime(),
                        "content": data.notification.body,
                        "isDeleted": true,
                        "url": response.data,
                        "Action": ActionType.Add.value,
                    }
                    await ControllerDA.doAction(ConfigAPI.noti, "Notification", obj)
                }
            }
            notiUtil.success("Gửi thông báo thành công");
            window.history.back();
        } else {
            notiUtil.success(response.message)
        }
    }
    const pictureInputRefs = useRef([]);

    const { fields, update } = useFieldArray({
        control,
        name: "listPictureId",
        keyName: "gid"
    })

    const handlePictureChange = async (e, index) => {
        if (e.target.files && e.target.files[0]) {
            const picture = e.target.files[0];
            const pictureUrl = URL.createObjectURL(picture);
            // Set the URL for preview
            setValue(`listPictureId[${index}].pictureUrl`, pictureUrl);

            const resPicture = await ControllerDA.uploadFile({ file: picture });
            if (resPicture) {
                // Update the id in the field array
                update(index, { value: resPicture.data.id, pictureUrl: pictureUrl });
            } else {
                notiUtil.errors('error upload file')
            }
        }
    }

    const handleFileChange = async (newFile) => {
        console.log(newFile)
        if (newFile) {
            const resFile = await ControllerDA.uploadFile({ file: newFile });
            if (resFile) {
                setValue(`urlFile`, resFile.data.id);
                setValue('fileItem', {
                    name: resFile.data.filePath,
                    size: newFile.size
                })
            } else {
                notiUtil.success('error upload file')
            }
        }
    }

    const _submitForm = () => {
        showDialog(
            {
                ref: dialogRef,
                status: DialogStatus.INFOR,
                title: "Xác nhận tạo đơn hàng?",
                submit: () => {
                    let data = getValues();
                    data.SubName = "1";
                    data.updatedProductDto = "1";
                    console.log(data)
                    if (!data.fileItem) delete data.urlFile
                    if (id) {
                        handleUpdateProduct({
                            ...data,
                            dateStart: Ultis.stringToDate(data.dateStart).getTime(),
                            dateEnd: Ultis.stringToDate(data.dateEnd).getTime(),
                            expense: JSON.stringify(data.expense),
                            maleJson: JSON.stringify(data.maleJson),
                            femaleJson: JSON.stringify(data.femaleJson),
                            salaryBenefits: JSON.stringify(data.salaryBenefits),
                            listPictureId: JSON.stringify(data.listPictureId?.map((e) => e.value)),
                            time: JSON.stringify(data.time),
                        })
                    } else {
                        handleCreateProduct({
                            ...data,
                            dateStart: Ultis.stringToDate(data.dateStart).getTime(),
                            dateEnd: Ultis.stringToDate(data.dateEnd).getTime(),
                            expense: JSON.stringify(data.expense),
                            maleJson: JSON.stringify(data.maleJson),
                            femaleJson: JSON.stringify(data.femaleJson),
                            salaryBenefits: JSON.stringify(data.salaryBenefits),
                            listPictureId: JSON.stringify(data.listPictureId?.map((e) => e.value)),
                            // listPictureId: data.listPictureId != '[]' ? JSON.stringify(data.listPictureId?.map((e) => e.value)) : '[]',
                            time: JSON.stringify(data.time),
                        })
                    }
                }
            }
        )
    }

    const onError = (ev) => {
    }

    useEffect(() => {
        if (id) {
            if (!localStorage.getItem('listActive')?.includes(EDIT)) notiUtil.errors('Tài khoản không có quyền chỉnh sửa')
            ControllerDA.getDetail(ConfigAPI.productUrl, controller, { "id": id, "Action": ActionType.GetById.value }).then(response => {
                if (response.code === 200) {
                    Object.keys(response.data).map(fieldName => {
                        if (fieldName === 'urlFile') {
                            if (response.data[fieldName]?.length > 0) {
                                ControllerDA.download({ fileId: response.data[fieldName] }).then(res => {
                                    if (res.code === 200) {
                                        setValue(`fileItem`, {
                                            id: response.data[fieldName],
                                            name: res.data,
                                            path: res.data
                                        })
                                    }
                                })
                                setValue(fieldName, response.data[fieldName])
                            }
                        } else if ([
                            'expense',
                            'maleJson',
                            'femaleJson',
                            'salaryBenefits',
                            'time',
                            'listPictureId'
                        ].includes(fieldName)) {
                            let parseValue = response.data[fieldName]?.length > 0
                                ? JSON.parse(response.data[fieldName])
                                : []
                            if (fieldName === 'listPictureId') {
                                parseValue = parseValue.map(e => {
                                    return { value: e }
                                })
                                setValue(fieldName, parseValue)
                            }
                            setValue(fieldName, parseValue)
                        } else if (['dateStart', 'dateEnd'].includes(fieldName)) {
                            setValue(fieldName, Ultis.datetoString(new Date(response.data[fieldName])))
                        } else {
                            setValue(fieldName, response.data[fieldName])
                        }
                        return 0;
                    });
                }
            })
        }
    }, [location.pathname])

    const editable = (id && localStorage.getItem('listActive')?.includes(EDIT)) || (!id && localStorage.getItem('listActive')?.includes(ADD))

    return (
        <form className={`admin-add-view mainView col ${editable ? '' : 'disabled'}`} onSubmit={handleSubmit(_submitForm, onError)}>
            <Dialog ref={dialogRef} />
            <div className='view-header col'>
                <div className='view-name heading-6'>{
                    id != null ?
                        "Sửa thông tin đơn hàng" : "Thêm mới đơn hàng"
                }</div>
                <div className='breadcrums row'>
                    <NavLink to={'/admin/danh-sach-don-hang'} className='nav-link regular1' type='button'>Quản lý đơn hàng</NavLink>
                    <FontAwesomeIcon className='icon' size='2xs' icon={faChevronRight} />
                    <NavLink className='nav-link regular1' type='button'>{
                        id != null ?
                            "Sửa thông tin đơn hàng" : "Thêm mới đơn hàng"
                    }</NavLink>
                </div>
            </div>
            <div className="view-content-container row">
                <div className="view-content col">
                    <div className='scroll-view-container col'>
                        <div className="inputForm view-form row-2 row" >
                            <div className='header row'>
                                <div className='label flex1 heading-7'>Ảnh đơn hàng</div>
                                {editable ? <button type='button' className='text-button' onClick={
                                    () => {
                                        if (fields.length < 3) {
                                            let listPictureId = fields || [];
                                            listPictureId.push("");

                                            setValue("listPictureId", listPictureId);
                                        }
                                    }
                                }> Thêm ảnh</button> : null}
                            </div>
                            {
                                fields.map((item, index) => (
                                    <div key={item.gid} className="product-picture-container row">
                                        <input
                                            // {...register(`listPictureId.${index}.id`)}
                                            ref={(el) => (pictureInputRefs.current[index] = el)}
                                            type="file"
                                            onChange={(ev) => handlePictureChange(ev, index)}
                                            style={{ display: "none" }}
                                        />
                                        <img
                                            className="product-picture-preview row "
                                            src={item.value ? `${ConfigAPI.fileUrl}api/UploadFile/img/${item.value}` : item.pictureUrl || ''}
                                            alt=""
                                        />
                                        <button type="button" className="upload button-text-3" onClick={() => pictureInputRefs.current[index].click()}>
                                            Upload ảnh đơn hàng
                                        </button>
                                        <button type="button" className="delete button-text-3" onClick={() => {
                                            const newList = fields.filter((e) => e.gid !== item.gid);
                                            setValue('listPictureId', newList);
                                        }}>
                                            Xóa ảnh
                                        </button>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="inputForm view-form row-2 row" >
                            <div className='header row'>
                                <div className='label flex1 heading-7'>Thông tin chung</div>
                                {id ? null : <CheckboxComponent value={pushNoti} onClick={() => setPushOnti(!pushNoti)} label={"Gửi thông báo đển ứng viên"} />}
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={true}
                                    errors={errors}
                                    name={"name"}
                                    label="Tên đơn hàng"
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={true}
                                    errors={errors}
                                    name={"orderCode"}
                                    label="Mã đơn hàng"
                                />
                            </div>
                            <div className="form-card">
                                <ReusableDoubleInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name1={"priceStart"}
                                    name2={"priceEnd"}
                                    label="Mức lương"
                                    placeholder1={"Giá bắt đầu"}
                                    placeholder2={"Giá kết thúc"}
                                    sufix2={<span className='regular2 sufix-icon'>VNĐ</span>}
                                    sufix1={<span className='regular2 sufix-icon'>¥</span>}
                                    spacing={'~'}
                                />
                            </div>
                            <div className="form-card row">
                                <div className='flex1'>
                                    <ReusableDatePicker
                                        label={'Ngày tạo'}
                                        errors={errors}
                                        register={register}
                                        name={'dateStart'}
                                        required={false}
                                        value={watch('dateStart')}
                                        placeholder={'Chọn ngày tạo'}
                                        onChange={value => {
                                            if (value) setValue('dateStart', value)
                                        }}
                                    />
                                </div>
                                <div className="row" style={{ marginTop: 32 }}>-</div>
                                <div className='flex1'>
                                    <ReusableDatePicker
                                        label={'Ngày kết thúc'}
                                        errors={errors}
                                        register={register}
                                        name={'dateEnd'}
                                        required={false}
                                        value={watch('dateEnd')}
                                        placeholder={'Chọn ngày kết thúc'}
                                        onChange={value => {
                                            if (value) setValue('dateEnd', value)
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("status")}
                                    label='Trạng thái'
                                    name='status'
                                    control={control}
                                    required={true}
                                    errors={errors}
                                    options={listStatusExam}
                                />
                            </div>

                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("type")}
                                    label='Loại hình đơn hàng'
                                    name='type'
                                    required={true}
                                    control={control}
                                    errors={errors}
                                    options={[
                                        { name: "Thực tập sinh", id: 1 },
                                        { name: "Kỹ sư", id: 2 },
                                        { name: "Du học", id: 3 },
                                        { name: "Tokutei", id: 4 },
                                    ]}
                                />
                            </div>

                            <div className="form-card">
                                <div className='TextFieldContainer col'>
                                    <div className='label-container row'>
                                        <span className='label-3'>Tệp đính kèm</span>
                                    </div>
                                    <div className='row' style={{ justifyContent: 'start' }}>
                                        <ImportFile
                                            fileItem={watch(`fileItem`)}
                                            buttonOnly
                                            onChange={handleFileChange}
                                            onRemove={() => { setValue('fileItem', undefined) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="inputForm view-form row-2 row" >
                            <div className='header row'>
                                <div className='label flex1 heading-7'>Thông tin chung về ngành</div>
                            </div>

                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"companyId"}
                                    label="Xí nghiệp"
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={true}
                                    errors={errors}
                                    name={"workAddress"}
                                    label="Địa chỉ làm việc"
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    errors={errors}
                                    name={"description"}
                                    label="Công việc cụ thể"
                                    required={false}
                                />
                            </div>
                            <div className="form-card">
                                <div className='TextFieldContainer col'>
                                    <div className='label-container row'>
                                        <span className='label-3'>Số lượng TTS cần tuyển</span>
                                    </div>
                                    <div className='input-container row'>
                                        <div className={`input-border row `}>
                                            <input
                                                autoComplete='off'
                                                type='text'
                                                placeholder='Nam'
                                                name='quantityMale'
                                                value={watch("quantityMale")}
                                                onChange={(ev) => {
                                                    setValue("quantityMale", ev.target.value);
                                                }}
                                            />
                                            <span className='regular1 sufix-icon'>Nam</span>
                                        </div>
                                        <div className={`input-border row `}>
                                            <input
                                                autoComplete='off'
                                                type='text'
                                                placeholder='Nữ'
                                                name='quantityFemale'
                                                value={watch("quantityFemale")}
                                                onChange={(ev) => {
                                                    setValue("quantityFemale", ev.target.value);
                                                }}
                                            />
                                            <span className='regular1 sufix-icon'>Nữ</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-card">
                                <div className='TextFieldContainer col'>
                                    <div className='label-container row'>
                                        <span className='label-3'>Số lượng thi tuyển</span>
                                    </div>
                                    <div className='input-container row'>
                                        <div className={`input-border row `}>
                                            <input
                                                autoComplete='off'
                                                type='text'
                                                placeholder='Nam'
                                                name='examMale'
                                                value={watch("examMale")}
                                                onChange={(ev) => {
                                                    setValue("examMale", ev.target.value);
                                                }}
                                            />
                                            <span className='regular1 sufix-icon'>Nam</span>
                                        </div>
                                        <div className={`input-border row `}>
                                            <input
                                                autoComplete='off'
                                                type='text'
                                                placeholder='Nữ'
                                                name='examFemale'
                                                value={watch("examFemale")}
                                                onChange={(ev) => {
                                                    setValue("examFemale", ev.target.value);
                                                }}
                                            />
                                            <span className='regular1 sufix-icon'>Nữ</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div className="inputForm view-form row-2 row" >
                            <div className='header row'>
                                <div className='label flex1 heading-7'>Yêu cầu tuyển dụng đối với nam</div>
                            </div>
                            <div className="form-card">
                                <ReusableDoubleInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name1={"maleJson[ageFrom]"}
                                    name2={"maleJson[ageTo]"}
                                    label="Độ tuổi"
                                    placeholder1={"Nhập tuổi"}
                                    placeholder2={"Nhập tuổi"}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("maleJson[level]")}
                                    label='Trình độ'
                                    name='maleJson[level]'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={[
                                        { name: "Cấp 3", id: 1 },
                                        { name: "Đại học", id: 2 },
                                        { name: "Đại học có ngôn ngữ", id: 3 },
                                    ]}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("maleJson[skill]")}
                                    label='Tay nghề'
                                    name='maleJson[skill]'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={[
                                        { name: "Không yêu cầu", id: 1 },
                                        { name: "Dưới 12 tháng", id: 2 },
                                        { name: "Trên 12 tháng", id: 3 },
                                    ]}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"maleJson[eyeSight]"}
                                    label="Thị lực"
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"maleJson[height]"}
                                    label="Chiều cao"
                                    sufix={<span className='regular2 sufix-icon'>cm</span>}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"maleJson[weight]"}
                                    label="Cân nặng"
                                    sufix={<span className='regular2 sufix-icon'>Kg</span>}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("maleJson[dominantArm]")}
                                    label='Tay thuận'
                                    name='maleJson[dominantArm]'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={[{ name: "Tay trái", id: 1 }, { name: "Tay phải", id: 2 }]}
                                />
                            </div>

                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("maleJson[smoke]")}
                                    label='Hút thuốc'
                                    name='maleJson[smoke]'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={[{ name: "Có", id: true }, { name: "Không", id: false }]}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("maleJson[maritalStatus]")}
                                    label='Tình trạng hôn nhân'
                                    name='maleJson[maritalStatus]'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={listMarriedStatus}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("maleJson[requirementForm]")}
                                    label='Hình thức tuyển dụng'
                                    name='maleJson[requirementForm]'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={listRequirementForm}
                                />
                            </div>

                            <div style={{ width: "100%" }}>
                                <ReusableTextArea
                                    register={register}
                                    label='Yêu cầu đặc biệt'
                                    name='maleJson[specialRequest]'
                                    required={false}
                                    errors={errors}
                                    getErrors={(error) => {
                                        return error.femaleJson?.specialRequest;
                                    }}
                                />
                            </div>
                        </div>

                        <div className="inputForm view-form row-2 row" >
                            <div className='header row'>
                                <div className='label flex1 heading-7'>Yêu cầu tuyển dụng đối với nữ</div>
                            </div>
                            <div className="form-card">
                                <ReusableDoubleInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name1={"femaleJson[ageFrom]"}
                                    name2={"femaleJson[ageTo]"}
                                    label="Độ tuổi"
                                    placeholder1={"Nhập tuổi"}
                                    placeholder2={"Nhập tuổi"}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("femaleJson[level]")}
                                    label='Trình độ'
                                    name='femaleJson[level]'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={[
                                        { name: "Cấp 3", id: 1 },
                                        { name: "Đại học", id: 2 },
                                        { name: "Đại học có ngôn ngữ", id: 3 },
                                    ]}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("femaleJson[skill]")}
                                    label='Tay nghề'
                                    name='femaleJson[skill]'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={[
                                        { name: "Không yêu cầu", id: 1 },
                                        { name: "Dưới 12 tháng", id: 2 },
                                        { name: "Trên 12 tháng", id: 3 },
                                    ]}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"femaleJson[eyeSight]"}
                                    label="Thị lực"
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"femaleJson[height]"}
                                    label="Chiều cao"
                                    sufix={<span className='regular2 sufix-icon'>cm</span>}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"femaleJson[weight]"}
                                    label="Cân nặng"
                                    sufix={<span className='regular2 sufix-icon'>Kg</span>}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("femaleJson[dominantArm]")}
                                    label='Tay thuận'
                                    name='femaleJson[dominantArm]'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={[{ name: "Tay trái", id: 1 }, { name: "Tay phải", id: 2 }]}
                                />
                            </div>

                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("femaleJson[smoke]")}
                                    label='Hút thuốc'
                                    name='femaleJson[smoke]'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={[{ name: "Có", id: true }, { name: "Không", id: false }]}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("femaleJson[maritalStatus]")}
                                    label='Tình trạng hôn nhân'
                                    name='femaleJson[maritalStatus]'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={listMarriedStatus}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("femaleJson[requirementForm]")}
                                    label='Hình thức tuyển dụng'
                                    name='femaleJson[requirementForm]'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={listRequirementForm}
                                />
                            </div>

                            <div style={{ width: "100%" }}>
                                <ReusableTextArea
                                    register={register}
                                    label='Yêu cầu đặc biệt'
                                    name='femaleJson[specialRequest]'
                                    required={false}
                                    errors={errors}
                                    getErrors={(error) => {
                                        return error.femaleJson?.specialRequest;
                                    }}
                                />
                            </div>
                        </div>


                        <div className="inputForm view-form row-1 row" >
                            <div className='header row'>
                                <div className='label flex1 heading-7'>Tiền lương và quyền lợi</div>
                            </div>
                            <div className="form-card">
                                <ReusableTextArea
                                    register={register}
                                    label='Tiền lương cơ bản'
                                    name='salaryBenefits[basicSalary]'
                                    required={false}
                                    errors={errors}
                                    getErrors={(error) => {
                                        return error.salaryBenefits?.basicSalary;
                                    }}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableTextArea
                                    register={register}
                                    label='Trợ cấp đặc biệt'
                                    name='salaryBenefits[specialSupport]'
                                    required={false}
                                    errors={errors}
                                    getErrors={(error) => {
                                        return error.salaryBenefits?.specialSupport;
                                    }}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableTextArea
                                    register={register}
                                    label='Trợ cấp đào tạo tháng đầu'
                                    name='salaryBenefits[firstMonthSupport]'
                                    required={false}
                                    errors={errors}
                                    getErrors={(error) => {
                                        return error.salaryBenefits?.firstMonthSupport;
                                    }}
                                />
                            </div>
                            <div className="form-card">
                                <ReusableTextArea
                                    register={register}
                                    label='Bảo hiểm xã hội, thân thể, bảo hiểm thất nghiệp'
                                    name='salaryBenefits[insurance]'
                                    required={false}
                                    errors={errors}
                                    getErrors={(error) => {
                                        return error.salaryBenefits?.insurance;
                                    }}
                                />
                            </div>

                        </div>

                        <div className="inputForm view-form row-2 row" >
                            <div className='header row'>
                                <div className='label flex1 heading-7'>Thời gian</div>
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"time[checkDay]"}
                                    label="Ngày check, sơ tuyển"
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"time[confirmFormDay]"}
                                    label="Ngày chốt form"
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"time[intendExam]"}
                                    label="Thời gian dự kiến thi tuyển"
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"time[intendEntry]"}
                                    label="Ngày dự kiến nhập cảnh"
                                />
                            </div>
                        </div>

                        <div className="inputForm view-form row-2 row" >
                            <div className='header row'>
                                <div className='label flex1 heading-7'>Chi phí</div>
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"expense[exit]"}
                                    label="Chi phí xuất cảnh"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='view-footer row'>
                <button type='button' className='close-button' onClick={() => {
                    if (editable) {
                        showDialog(
                            {
                                ref: dialogRef,
                                status: DialogStatus.WARNING,
                                title: "Xác nhận thoát tạo đơn hàng?",
                                content: "Thông tin đơn hàng đã nhập sẽ không được lưu lại",
                                submit: () => { window.history.back() }
                            }
                        )
                    } else {
                        window.history.back()
                    }
                }}><span className='button-text-3'>Thoát</span></button>
                <div className='space'></div>

                <button type='submit' className='submit-button'><span className='button-text-3'>Gửi</span></button> </div>
        </form >
    )
};
