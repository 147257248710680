/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form";
import { ReusableInput, ReusableSelect1, ReusableSelect2, ReusableTextArea } from "../../component/TextField/text-field";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { DialogStatus, showDialog } from './../../component/dialog/dialog';
import { ticketStatus, ticketType, ticketTypeName } from './../../assets/const/select-list';
import ConfigAPI from "../../config/configApi";
import { ControllerDA } from "../../core/controller";
import { ActionType } from "../../core/ActionType";
import { Ultis } from "../../core/Utils";

export default function QuanLyTicketForm({ controller }) {
    const { register, control, setValue, watch, formState: { errors } } = useForm({ shouldFocusError: false });

    const { id } = useParams();


    const _InitData = async () => {
        if (id) {
            const response = await ControllerDA.getDetail(ConfigAPI.customerUrl, controller, { "id": id, "Action": ActionType.GetById.value })
            if (response.code === 200) {
                Object.keys(response.data).forEach(fieldName => {
                    if (['startTime', 'endTime'].some(e => e === fieldName)) {
                        setValue(fieldName, Ultis.datetoString(new Date(response.data[fieldName]), 'dd/MM/yyyy hh:mm:ss'))
                    } else if (fieldName === 'content') {
                        try {
                            var parseContent = JSON.parse(response.data[fieldName])
                        } catch (error) { }
                        setValue('content', parseContent?.ticketContent ?? response.data[fieldName] ?? '')
                    } else {
                        setValue(fieldName, response.data[fieldName])
                    }
                })
                if (response.data.customerId?.length) {
                    const customerDetails = await ControllerDA.getDetail(ConfigAPI.customerUrl, 'Customer', { "id": response.data.customerId, "Action": ActionType.GetById.value })
                    if (customerDetails.code === 200)
                        setValue('customerName', customerDetails.data.name)
                }
            }
        }
    }

    useEffect(() => {
        _InitData()
    }, [])

    return (
        <form className="admin-add-view mainView col" >
            <div className='view-header col'>
                <div className='view-name heading-6'>Thông tin ticket</div>
            </div>
            <div className="view-content-container row">
                <div className="view-content col">
                    <div className='scroll-view-container col'>
                        <div className="inputForm view-form row-2 row" >
                            <div className='header row'>
                                <div className='label flex1 heading-7'>Thông tin chung</div>
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"title"}
                                    label="Tiêu đề ticket"
                                    disabled
                                />
                            </div>
                            <div className="form-card">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"customerName"}
                                    label="Tên ứng viên"
                                    disabled
                                />
                            </div>

                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("type")}
                                    label='Loại đơn'
                                    name='type'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={ticketType}
                                    disabled
                                />
                            </div>
                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("typeTicket")}
                                    label='Tên loại đơn'
                                    name='typeTicket'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={ticketTypeName}
                                    disabled
                                />
                            </div>
                            <div className="form-card row">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"startTime"}
                                    label="Thời gian bắt đầu"
                                    disabled
                                />
                            </div>
                            <div className="form-card row">
                                <ReusableInput
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"endTime"}
                                    label="Thời gian kết thúc"
                                    disabled
                                />
                            </div>
                            <div className="form-card">
                                <ReusableSelect1
                                    value={watch("status")}
                                    label='Trạng thái'
                                    name='status'
                                    control={control}
                                    required={false}
                                    errors={errors}
                                    options={ticketStatus}
                                    disabled
                                />
                            </div>
                            <div className="form-card row" style={{ width: '100%' }}>
                                <ReusableTextArea
                                    register={register}
                                    required={false}
                                    errors={errors}
                                    name={"content"}
                                    label="Nội dung"
                                    disabled
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='view-footer row'>
                <button type='button' className='close-button' onClick={() => {
                    window.history.back()
                }}><span className='button-text-3'>Thoát</span></button>
                <div className='space'></div>

            </div>
        </form >
    )
};
