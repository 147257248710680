/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form'
import {
  ReusableInput,
  ReusableSelect2
} from '../../component/TextField/text-field'
import { NavLink, Navigate, useLocation, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import {
  Dialog,
  DialogStatus,
  showDialog
} from './../../component/dialog/dialog'
import ConfigAPI from '../../config/configApi'
import { ControllerDA } from '../../core/controller'
import { ActionType } from '../../core/ActionType'
import notiUtil from './../../core/notiUtil'
import { getUrlRouter } from '../../router/router'
import { ADD, EDIT, listVideoType } from '../../assets/const/select-list'

export default function QuanLyVideoForm({ controller }) {
  const location = useLocation()

  const {
    register,
    control,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors }
  } = useForm({ shouldFocusError: false, defaultValues: { type: 5 } })

  const { id } = useParams()

  const dialogRef = useRef()
  const avatarRef = useRef()

  const _submitForm = () => {
    showDialog({
      ref: dialogRef,
      status: DialogStatus.INFOR,
      title: `Xác nhận ${id ? 'chỉnh sửa' : 'tạo'} thông tin video?`,
      submit: () => {
        let data = getValues()
        data.type = parseInt(data.type)
        submitAction(data)
      }
    })
  }

  const submitAction = event => {
    if (id) {
      event.Action = ActionType.Edit.value
      ControllerDA.editAction(ConfigAPI.customerUrl, controller, event).then(
        res => {
          if (res.code === 200) {
            window.history.back()
          } else {
            notiUtil.success(res.message)
          }
        }
      )
    } else {
      //   event.dateCreated = new Date().getTime()
      event.Action = ActionType.Add.value
      ControllerDA.doAction(ConfigAPI.customerUrl, controller, event).then(
        res => {
          if (res.code === 200) {
            window.history.back()
          } else {
            notiUtil.success(res.message)
          }
        }
      )
    }
  }
  const onError = () => { }

  const handleImageChange = async e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      const resFile = await ControllerDA.uploadFile({ file: img })
      if (resFile) {
        setValue('url', resFile.data.id)
      }
    }
  }

  useEffect(() => {
    if (id) {
      if (!localStorage.getItem('listActive')?.includes(EDIT)) notiUtil.errors('Tài khoản không có quyền chỉnh sửa')
      ControllerDA.getDetail(ConfigAPI.customerUrl, controller, {
        id: id,
        Action: ActionType.GetById.value
      }).then(res => {
        if (res) {
          Object.keys(res.data).map(fieldName => {
            setValue(fieldName, res.data[fieldName])
            return 0
          })
        }
      })
    }
  }, [location.pathname])
  const editable = (id && localStorage.getItem('listActive')?.includes(EDIT)) || (!id && localStorage.getItem('listActive')?.includes(ADD))

  return (
    <form className={`admin-add-view mainView col ${editable ? '' : 'disabled'}`} onSubmit={handleSubmit(_submitForm, onError)} >
      <Dialog ref={dialogRef} />
      <div className='view-header col'>
        <div className='view-name heading-6'>
          {id ? 'Chỉnh sửa thông tin video' : 'Thêm mới video'}
        </div>
        <div className='breadcrums row'>
          <NavLink to={'/admin/quan-ly-ho-so-ung-vien'} className='nav-link regular1' type='button' >
            Quản lý thông tin video
          </NavLink>
          <FontAwesomeIcon className='icon' size='2xs' icon={faChevronRight} />
          <NavLink className='nav-link regular1' type='button'>
            {id ? 'Chỉnh sửa thông tin video' : 'Thêm mới video'}
          </NavLink>
        </div>
      </div>
      <div className='view-content-container row'>
        <div className='view-content col'>
          {/* //!input form */}
          <div className='inputForm view-form row-2 row'>
            <div className='header row'>
              <div className='label flex1 heading-7'>Thông tin video</div>
            </div>
            <div className='left-item col' style={{ gap: 16 }}>
              <input ref={avatarRef} type='file' onChange={handleImageChange} style={{ display: 'none' }} />
              <img className='image' src={watch('url') ? `${ConfigAPI.fileUrl}api/UploadFile/img/${watch('url')}` : ''} alt='' style={{ width: 320, height: 168 }} />
              {localStorage.getItem('listActive')?.includes(EDIT) ? <button type='button' style={{ justifyContent: 'center' }} className='upload-image row' onClick={() => { avatarRef.current.click() }} >
                <svg width={14} height={13} viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg' >
                  <path d='M3.66732 3.88889L7.00065 0L10.334 3.88889H7.55621V8.88889H6.4451V3.88889H3.66732Z' fill='#262626' />
                  <path d='M12.5562 11.1111H1.4451V7.77778H0.333984V11.6667C0.333984 11.9733 0.582873 12.2222 0.88954 12.2222H13.1118C13.4184 12.2222 13.6673 11.9733 13.6673 11.6667V7.77778H12.5562V11.1111Z' fill='#262626' />
                </svg>
                <span className='button-text-3'>Tải ảnh lên</span>
              </button> : null}
            </div>

            <div className='list-input-container row'>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'name'}
                  label={'Tên video'}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'link'}
                  label={'Liên kết'}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'sort'}
                  label={'Thứ tự hiển thị'}
                  type={'number'}
                />
              </div>
              <div className='form-card'>
                <ReusableSelect2
                  control={control}
                  errors={errors}
                  name={'type'}
                  label={'Loại video'}
                  value={watch('type')}
                  valueType={'int'}
                  options={listVideoType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='view-footer row'>
        <button
          type='button'
          className='close-button'
          onClick={() => {
            if (editable) {
              showDialog({
                ref: dialogRef,
                status: DialogStatus.WARNING,
                title: `Xác nhận thoát ${id ? 'chỉnh sửa' : 'tạo'} video?`,
                content:
                  'Thông tin video đã nhập sẽ không được lưu lại',
                submit: () => {
                  window.history.back()
                }
              })
            } else {
              window.history.back()
            }
          }}
        >
          <span className='button-text-3'>Thoát</span>
        </button>
        <div className='space'></div>
        <button type='submit' className='submit-button'>
          <span className='button-text-3'>Gửi</span>
        </button>
      </div>
    </form>
  )
}
