/* eslint-disable react-hooks/exhaustive-deps */
import { NavLink } from "react-router-dom";
import { ActionType } from "../../core/ActionType";
import { useEffect, useState } from "react";
import { EDIT, ticketStatus } from "../../assets/const/select-list";
import ConfigAPI from "../../config/configApi";
import { postData } from "../../core/BaseDA";
import { Select1 } from "../../component/select1/select1";
import { Ultis } from "../../core/Utils";

export default function ListView({ data, callback_action, onChangeTab, tab }) {
    const [listCustomer, setListCustomer] = useState([]);

    const _InitData = async () => {
        if (data?.length) {
            const customerIds = data.map(item => item.customerId).filter(id => id !== null);
            const res = await postData(ConfigAPI.customerUrl + 'Customer/GetMutipleInfo', { obj: customerIds })
            if (res.code === 200) {
                setListCustomer(res.data)
            }
        }
    }

    useEffect(() => {
        _InitData()
    }, [data]);

    return (
        <>
            <div className='listView-header row'>
                <div className='heading-7 space'>Danh sách kết quả</div>
                {/* <GetAction controller={controller} action={ActionType.Add.value} /> */}
            </div>
            <div className="row tab-container">
                <div className={`button-tab semibold2 ${tab === 1 ? "selected" : ""}`} onClick={() => { onChangeTab(1) }}>Chờ phê duyệt</div>
                <div className={`button-tab semibold2 ${tab === 2 ? "selected" : ""}`} onClick={() => { onChangeTab(2) }}>Đã phê duyệt</div>
                <div className={`button-tab semibold2 ${tab === 3 ? "selected" : ""}`} onClick={() => { onChangeTab(3) }}>Từ chối</div>
            </div>
            <div className='listView-body col'>
                <table className='custom-table'>
                    <thead>
                        <tr>
                            <th style={{ minWidth: 75, }} className='fixed-first-column'><div className="center">STT</div></th>
                            <th style={{ minWidth: 360, }} className=''>Tiêu đề ticket</th>
                            <th style={{ minWidth: 200, }} className=''>Tên ứng viên</th>
                            <th style={{ minWidth: 360, }} className=''>Nội dung</th>
                            <th style={{ minWidth: 172, }} className=''>Thời gian tạo</th>
                            <th style={{ minWidth: 172, }} className=''>Thời gian kết thúc</th>
                            <th style={{ minWidth: 172, }} className=''>Trạng thái</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (data ?? []).map((item, index) => {
                                try {
                                    var parseContent = JSON.parse(item.content)
                                } catch (error) { }
                                return (
                                    <tr key={index}>
                                        <td style={{ minWidth: 75, }} className='fixed-first-column'><div className="center">{index + 1}</div></td>
                                        <td style={{ minWidth: 360, }} className=''> <NavLink to={ActionType.Edit.value + `/${item.id}`} className={'nav-link'}>{item.title ?? "-"}</NavLink></td>
                                        <td style={{ minWidth: 200, }} className=''>{listCustomer.find(e => e.id === item.customerId)?.name ?? ''}</td>
                                        <td style={{ minWidth: 360, }} className=''>{parseContent?.ticketContent ?? item.content ?? "-"}</td>
                                        <td style={{ minWidth: 172, }} className=''>{item.dateCreated ? Ultis.datetoString(new Date(item.dateCreated)) : ""}</td>
                                        <td style={{ minWidth: 172, }} className=''>{item.endTime ? Ultis.datetoString(new Date(item.endTime)) : ""}</td>
                                        <td style={{ minWidth: 172, }} className=''>
                                            <Select1
                                                value={item.status}
                                                placeholder='Chọn trạng thái'
                                                disabled={!localStorage.getItem('listActive')?.includes(EDIT)}
                                                options={ticketStatus}
                                                onChange={ev => {
                                                    item.status = ev.id;
                                                    callback_action(item);
                                                }} />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>

        </>
    )
}