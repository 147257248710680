/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { ControllerDA } from '../../core/controller';
import { ActionType } from '../../core/ActionType';
import ConfigAPI from '../../config/configApi';
import { Dialog, showDialog } from '../../component/dialog/dialog';
import { useForm } from 'react-hook-form';
import { Select1 } from '../../component/select1/select1';
import './role.css'
import notiUtil from '../../core/notiUtil';
import { SelectMultiple } from '../../component/input-multi-select/input-multi-select';
import { uuidv4 } from '../../core/Enum';
import { adminId } from '../../assets/const/select-list';

const pencilSvg = <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5073 14.6668H1.91297C1.75922 14.6668 1.61176 14.6058 1.50305 14.497C1.39433 14.3883 1.33325 14.2409 1.33325 14.0871C1.33325 13.9334 1.39433 13.7859 1.50305 13.6772C1.61176 13.5685 1.75922 13.5074 1.91297 13.5074H13.5073C13.661 13.5074 13.8085 13.5685 13.9172 13.6772C14.0259 13.7859 14.087 13.9334 14.087 14.0871C14.087 14.2409 14.0259 14.3883 13.9172 14.497C13.8085 14.6058 13.661 14.6668 13.5073 14.6668Z" fill="#00204D" fillOpacity="0.6" />
    <path d="M8.04984 3.65223L3.24226 8.45982C3.16793 8.53418 3.11523 8.62736 3.08979 8.72938L1.91297 12.348L5.53155 11.1711C5.63357 11.1457 5.72675 11.093 5.80112 11.0187L10.6087 6.2111L8.04984 3.65223Z" fill="#00204D" fillOpacity="0.6" />
    <path d="M12.7577 3.24238L11.0186 1.50323C10.9098 1.39455 10.7624 1.3335 10.6087 1.3335C10.455 1.3335 10.3076 1.39455 10.1988 1.50323L8.86955 2.83252L11.4284 5.39138L12.7577 4.06209C12.8664 3.95338 12.9274 3.80595 12.9274 3.65223C12.9274 3.49851 12.8664 3.35109 12.7577 3.24238Z" fill="#00204D" fillOpacity="0.6" />
</svg>
const searchSvg = <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0289 10.8703C9.11306 11.6029 7.95131 12.041 6.68724 12.041C3.73045 12.041 1.3335 9.64403 1.3335 6.68724C1.3335 3.73045 3.73045 1.3335 6.68724 1.3335C9.64403 1.3335 12.041 3.73045 12.041 6.68724C12.041 7.95135 11.6029 9.11313 10.8702 10.029L14.6668 13.8256L13.8255 14.6669L10.0289 10.8703ZM2.52322 6.68724C2.52322 4.38751 4.38751 2.52322 6.68724 2.52322C8.98697 2.52322 10.8513 4.38751 10.8513 6.68724C10.8513 7.80878 10.4079 8.82675 9.68682 9.57544L9.57544 9.68682C8.82675 10.4079 7.80878 10.8513 6.68724 10.8513C4.38751 10.8513 2.52322 8.98697 2.52322 6.68724Z" fill="#00204D" fillOpacity="0.6" />
</svg>
const userProfile = <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.62346 19.7036C5.63199 19.7109 5.64067 19.7181 5.6495 19.7251C7.37657 21.1465 9.58861 22 12 22C14.4095 22 16.6199 21.1479 18.3464 19.7285L18.3507 19.7251C18.3608 19.717 18.3707 19.7088 18.3805 19.7003C20.5917 17.8661 22 15.0975 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 15.0993 3.40997 17.8693 5.62346 19.7036ZM3.66667 12C3.66667 7.39763 7.39763 3.66667 12 3.66667C16.6024 3.66667 20.3333 7.39763 20.3333 12C20.3333 14.0013 19.6279 15.8378 18.4521 17.2744C18.1824 16.5599 17.6706 15.9473 16.9866 15.5565L15.2745 14.5768C15.8333 13.8677 16.1667 12.9728 16.1667 12V10.3333C16.1667 8.03226 14.3011 6.16667 12 6.16667C9.69893 6.16667 7.83333 8.03226 7.83333 10.3333V12C7.83333 12.972 8.1662 13.8663 8.72416 14.5751L7.01268 15.5567C6.32875 15.9475 5.8173 16.5599 5.54778 17.2742C4.37206 15.8376 3.66667 14.0012 3.66667 12ZM17 18.6673C15.6072 19.7134 13.876 20.3333 12 20.3333C10.124 20.3333 8.39277 19.7134 7 18.6673V18.4508C7 17.8523 7.32055 17.3002 7.83995 17.0036L10.0982 15.7083C10.6684 16.0013 11.3149 16.1667 12 16.1667C12.6839 16.1667 13.3294 16.0019 13.8988 15.7098L16.16 17.0037C16.6794 17.3004 17 17.8525 17 18.4509V18.6673ZM12 7.83333C10.6194 7.83333 9.5 8.95274 9.5 10.3333V12C9.5 12.8511 9.92547 13.603 10.5753 14.0545L10.5852 14.0614C10.9875 14.338 11.4749 14.5 12 14.5C12.518 14.5 12.9992 14.3424 13.3983 14.0726C13.4108 14.0635 13.4235 14.0547 13.4364 14.0464C14.0796 13.594 14.5 12.846 14.5 12V10.3333C14.5 8.95274 13.3806 7.83333 12 7.83333Z" fill="#00204D" fillOpacity="0.6" />
</svg>



export default function QuanLyRole({ controller }) {
    const [list, setList] = useState([])
    const [searchList, setSearchList] = useState()
    const [selected, setSelected] = useState()
    const [ownerList, setOwnerList] = useState([])

    const diaglogRef = useRef()

    const popupAddEdit = (item) => {
        showDialog({
            ref: diaglogRef,
            children: <PopupAddEditRole ref={diaglogRef} ownerList={ownerList} item={item} onSubmit={(ev) => {
                if (item) {
                    ControllerDA.editAction(ConfigAPI.customerUrl, controller, { Action: ActionType.Edit.value, ...ev }).then(res => {
                        if (res.code === 200) {
                            if (item) {
                                notiUtil.success('Cập nhật role thành công')
                            } else {
                                notiUtil.success('Thêm mới role thành công')
                            }
                            _InitData()
                        } else {
                            notiUtil.success(res.message)
                        }
                    })
                } else {
                    ControllerDA.doAction(ConfigAPI.customerUrl, controller, { Action: ActionType.Add.value, ...ev, id: uuidv4(), isActive: true }).then(res => {
                        if (res.code === 200) {
                            notiUtil.success('Thêm mới role thành công')
                            _InitData()
                        } else {
                            notiUtil.success(res.message)
                        }
                    })
                }
            }} />
        })
    }

    const _InitData = () => {
        ControllerDA.actionGet(ConfigAPI.customerUrl, controller, { Action: 'GetAll' }).then(res => {
            if (res.code === 200) {
                setList(res.data)
            }
        })
        ControllerDA.filterAction(ConfigAPI.customerUrl, 'Customer', {
            "loadOptions": {
                "requireTotalCount": true,
                "skip": 0,
                "take": 100,
            },
            "filter": [{
                "field": 'type',
                "operator": "contains",
                "value": 0,
            }],
            "Action": ActionType.GetAll.value
        }).then(res => {
            if (res.code === 200) setOwnerList(res.data ?? [])
        })
    }

    useEffect(() => {
        _InitData()
    }, [])


    return (
        <div className="mainView col">
            <div className="view-content-container row" style={{ padding: '24px 32px' }}>
                <div className="view-content col">
                    <div className="listView col" style={{ padding: '0 32px' }}>
                        <div className='listView-header row' style={{ padding: 0 }}>
                            <Dialog ref={diaglogRef} />
                            <div className='heading-7 space'>Nhóm quyền (role)</div>
                        </div>
                        <div className="row table-search">
                            <div className="row" style={{ width: 16, height: 16 }}>{searchSvg}</div>
                            <input placeholder="Tìm kiếm" onChange={(ev) => {
                                if (ev.target.value.trim().length) {
                                    const filterList = list.filter(e => e.name.toLowerCase().includes(ev.target.value.trim().toLowerCase()))
                                    setSearchList(list.filter(e => filterList.some(el => el.id === e.id || el.parentId === e.id)).map(e => {
                                        return JSON.parse(JSON.stringify(e))
                                    }))
                                } else {
                                    setSearchList(null)
                                }
                            }} />
                        </div>
                        <div className='listView-body col'>
                            <table className='custom-table'>
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: 44, padding: 0 }} className='fixed-first-column'>
                                            <div className="center">STT</div>
                                        </th>
                                        <th style={{ minWidth: 172, }} className=''>Tên nhóm quyền</th>
                                        <th style={{ minWidth: 172, }} className='' >Owner</th>
                                        <th style={{ minWidth: 240, }} className='' >Account</th>
                                        <th style={{ minWidth: 360, }} className='' >Action</th>
                                        <th style={{ minWidth: 112, }} className='fixed-last-column'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (searchList ?? list).map((item, index) => {
                                            return (
                                                <tr id={item.id} key={item.id} className={"tb-tr-item " + (selected?.id === item.id ? 'selected ' : '')} onClick={() => { setSelected(item) }} >
                                                    <td style={{ minWidth: 44, padding: 0 }} className='fixed-first-column'><div className="center">{index + 1}</div></td>
                                                    <td style={{ minWidth: 172, }} className="">{item.name ?? "-"}</td>
                                                    <td style={{ minWidth: 172, }} className=''>{ownerList.find(e => e.id === item.owner)?.name ?? "administrator"}</td>
                                                    <td style={{ minWidth: 240, }} className=''>{(item.userRoleItems ?? []).map(e => e.nameUser).join(' , ')}</td>
                                                    <td style={{ minWidth: 360, }} className=''>{item.listActive ?? ''}</td>
                                                    <td style={{ minWidth: 112, }} className='fixed-last-column row'>
                                                        <button type="button" className="deleteButton box32 center" onClick={() => { popupAddEdit(item); }}>
                                                            {pencilSvg}
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const PopupAddEditRole = forwardRef(function PopupAddEditRole(data, ref) {
    const methods = useForm({
        shouldFocusError: false,
        defaultValues: data.item ? {
            ...data.item,
            owner: adminId,
            listActive: data.item?.listActive?.split(',')?.map(e => {
                return {
                    id: e,
                    name: e
                }
            }),
            userRoleItems: data.item.userRoleItems.map(e => {
                return {
                    ...e,
                    id: e.userId,
                    name: e.nameUser
                }
            })
        } : {
            owner: adminId, listActive: [],
            userRoleItems: []
        }
    })
    const roleAdminId = '9dad6b70-cddc-41c8-b599-6b97bc02fb10'
    const [actionList, setActionList] = useState([])
    const [selectedAcc, setSelectedAcc] = useState([])

    const _submitForm = (ev) => {
        ev.listActive = ev.listActive.map(e => e.id).join(',')
        ev.userRoleItems = ev.userRoleItems.map(e => { return { userId: e.id } })
        ref.current.closeDialog()
        if (data.onSubmit) data.onSubmit(ev)
    }

    useEffect(() => {
        console.log(data.ownerList)
        ControllerDA.actionGet(ConfigAPI.customerUrl, 'Active', { Action: 'GetAll' }).then(res => {
            if (res.code === 200) setActionList(res.data.map(e => {
                return {
                    ...e,
                    id: e.name
                }
            }))
        })
    }, [])

    return <div>
        <div className="popup-header row">{data.item ? 'Chỉnh sửa role' : 'Thêm mới role'}</div>
        <div className="row config-popup-body" style={{ width: 1290, padding: 24, columnGap: 24, alignItems: 'start', height: 520 }}>
            <div className="col" style={{ rowGap: 20, flex: 1 }}>
                <div className="block-header">Thông tin chung</div>
                <div className="row">
                    <div className="label">Tên role<span className='regular2' style={{ color: 'red' }}>*</span></div>
                    <div className={`input-border ${methods.formState.errors['name'] && 'helper-text'}`}>
                        <input placeholder="Nhập tên role" name="name" {...methods.register('name', { required: true })} />
                        {methods.formState.errors['name'] && <div className='helper-text regular1'>Vui lòng nhập tên role</div>}
                    </div>
                </div>
                <div className="row">
                    <div className="label">Action</div>
                    <div style={{ flex: 1 }}>
                        <SelectMultiple
                            control={methods.control}
                            name={'listActive'}
                            value={methods.watch('listActive')}
                            options={actionList}
                            disabled={data?.item?.id === roleAdminId}
                            placeholder="Chọn action"
                            errors={methods.formState.errors}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="label">Mô tả</div>
                    <div className={`input-border row`}>
                        <textarea
                            autoComplete='off'
                            className='regular1'
                            placeholder={'Nhập mô tả'}
                            name="content"
                            {...methods.register('description')}
                        />
                    </div>
                </div>
            </div>
            <div className="spacing" style={{ height: 480, margin: '0 16px' }}></div>
            <div className="col" style={{ rowGap: 20, flex: 1, height: '100%' }}>
                <div className="block-header">Account</div>
                <div className="row">
                    <div className="label">Owner</div>
                    <div style={{ flex: 1 }}>
                        <Select1
                            options={(data.ownerList ?? []).map(e => {
                                return {
                                    id: e.id,
                                    name: e.name
                                }
                            })}
                            disabled={true}
                            placeholder="Chọn owner"
                            value={methods.watch('owner')}
                            control={methods.control}
                            name={'owner'}
                            errors={methods.formState.errors}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="label">Member</div>
                    <div className='row' style={{ columnGap: 8, flex: 1 }}>
                        <SelectMultiple
                            value={selectedAcc}
                            options={data?.ownerList?.filter(e => methods.watch('userRoleItems')?.every(user => user.id !== e.id)) ?? []}
                            placeholder="Nhập account"
                            onChange={(newList) => {
                                setSelectedAcc(newList)
                            }}
                        />
                        <button type='button' className='add-member'
                            onClick={() => {
                                methods.setValue('userRoleItems', [...methods.getValues('userRoleItems'), ...selectedAcc])
                                setSelectedAcc([])
                            }}
                        >Thêm</button>
                    </div>
                </div>
                <div className="col" style={{ alignItems: 'end', overflow: 'hidden auto', height: '100%', flex: 1 }}>
                    {(methods.watch('userRoleItems') ?? []).map(acc => {
                        const thisUser = data.ownerList?.find(e => e.id === acc.id)
                        return <div key={acc.id} className="acc-infor-tile row">
                            <div className="acc-avatar">{userProfile}</div>
                            <div className="col" style={{ flex: 1 }}>
                                <div className="acc-name">{thisUser?.name ?? ''}</div>
                                <div className="acc-sub-infor">{thisUser?.email ?? thisUser?.mobile ?? ''}</div>
                            </div>
                            {
                                acc.id === adminId ? null :
                                    <button type='button' className='suffix-icon-delete' onClick={() => {
                                        methods.setValue('userRoleItems', methods.getValues('userRoleItems').filter(e => e.id !== acc.id))
                                    }}>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.35414 16.3989C4.39331 16.9238 4.62901 17.4145 5.01418 17.7732C5.39935 18.1318 5.90563 18.332 6.43192 18.3337H13.568C14.0943 18.332 14.6006 18.1318 14.9858 17.7732C15.3709 17.4145 15.6066 16.9238 15.6458 16.3989L16.3014 7.22255H3.69859L4.35414 16.3989Z" fill="#00204D" fillOpacity="0.6" />
                                            <path d="M17.6388 4.44477H13.4722V2.36144C13.4722 2.17726 13.399 2.00062 13.2688 1.87039C13.1386 1.74016 12.9619 1.66699 12.7777 1.66699H7.22218C7.038 1.66699 6.86137 1.74016 6.73114 1.87039C6.6009 2.00062 6.52774 2.17726 6.52774 2.36144V4.44477H2.36107C2.17689 4.44477 2.00026 4.51793 1.87002 4.64817C1.73979 4.7784 1.66663 4.95504 1.66663 5.13921C1.66663 5.32339 1.73979 5.50003 1.87002 5.63026C2.00026 5.76049 2.17689 5.83366 2.36107 5.83366H17.6388C17.823 5.83366 17.9997 5.76049 18.1299 5.63026C18.2601 5.50003 18.3333 5.32339 18.3333 5.13921C18.3333 4.95504 18.2601 4.7784 18.1299 4.64817C17.9997 4.51793 17.823 4.44477 17.6388 4.44477ZM7.91663 3.05588H12.0833V4.44477H7.91663V3.05588Z" fill="#00204D" fillOpacity="0.6" />
                                        </svg>
                                    </button>
                            }   
                        </div>
                    })}
                </div>
            </div>
        </div>
        <div className='dialog-footer row' style={{ padding: '0 24px 24px 24px' }}>
            <button type='button' onClick={() => ref.current.closeDialog()} className='dialog-action' >Quay lại</button>
            <button type='button' className='dialog-action dialog-submit' onClick={methods.handleSubmit(_submitForm)} >Lưu</button>
        </div>
    </div>
})