import BaiVietGioiThieu from "../view/BaiVietGioiThieu/BaiVietGioiThieu";
import QuanLyDonHangForm from "../view/QuanLyDonHang/Form";
import QuanLyDonHang from "../view/QuanLyDonHang/QuanLyDonHang";
import QuanLyNguoiDungMobileApp from "../view/QuanLyNguoiDungMobileApp/QuanLyNguoiDungMobileApp";
import QuanLyNguoiDungMobileAppForm from "../view/QuanLyNguoiDungMobileApp/FormNoiBo";
import QuanLyNguoiDungHeThong from './../view/QuanLyNguoiDungHeThong/QuanLyNguoiDungHeThong';
import QuanLyNguoiDungHeThongForm from "../view/QuanLyNguoiDungHeThong/Form";
import QuanLyTicket from "../view/QuanLyTicket/QuanLyTicket";
import QuanLyLopHocForm from "../view/QuanLyLopHoc/Form";
import QuanLyLopHoc from "../view/QuanLyLopHoc/QuanLyLopHoc";
import QuanLyUngVien from "../view/QuanLyUngVien/QuanLyUngVien";
import QuanLyXiNghiepForm from "../view/QuanLyXiNghiep/Form";
import QuanLyXiNghiep from "../view/QuanLyXiNghiep/QuanLyXiNghiep";
import DoiMatKhauForm from "../view/DoiMatKhau/Form";
import QuanLyVideoForm from "../view/QuanLyVideo/Form";
import QuanLyVideo from "../view/QuanLyVideo/QuanLyVideo";
import QuanLyTinTucForm from "../view/QuanLyTinTuc/Form";
import QuanLyTinTuc from "../view/QuanLyTinTuc/QuanLyTinTuc";
import QuanLyUngVienForm from "../view/QuanLyUngVien/Form";
import QuanLyTicketForm from "../view/QuanLyTicket/Form";
import QuanLyBanner from "../view/QuanLyBanner/QuanLyBanner";
import QuanLyModule from "../view/QuanLyModule/QuanLyModule";
import QuanLyRole from "../view/QuanLyRole/QuanLyRole";
import NotifiManagerView from "../view/QuanLyThongBao/Form";
import QuanLyThongBao from "../view/QuanLyThongBao/QuanLyThongBao";
var routes = [
    // khai báo thêm router ở đây khi data của user không trả ra.
    // {
    //     path: "/nhap-kho/add",
    //     name: "",
    //     components: <Form />,
    // },
];
export default routes;
export const getUrlRouter = (moduleCode) => {
    switch (moduleCode) {
        case "quan-ly-ho-so-ung-vien":
            return "quan-ly-ho-so-ung-vien";
        case "quan-ly-don-hang":
            return "quan-ly-don-hang";
        case "quan-ly-nguoi-dung-mobile-app":
            return "quan-ly-nguoi-dung-mobile-app";
        case "quan-ly-nguoi-dung-he-thong":
            return "quan-ly-nguoi-dung-he-thong";
        case "quan-ly-lop-hoc":
            return "quan-ly-lop-hoc";
        case "quan-ly-xi-nghiep":
            return "quan-ly-xi-nghiep";
        case "quan-ly-thong-bao":
            return "quan-ly-thong-bao";
        case "quan-ly-ticket":
            return "quan-ly-ticket";
        case "Module":
            return "Module";
        case "Role":
            return "Role";
        case "doi-mat-khau":
            return "doi-mat-khau";
        case "banner":
            return "banner";
        case "gioi-thieu-dhm":
            return "gioi-thieu-dhm";
        case "gioi-thieu-nhat-ban":
            return "gioi-thieu-nhat-ban";
        case "gioi-thieu-cac-hinh-thuc-di-nhat":
            return "gioi-thieu-cac-hinh-thuc-di-nhat";
        case "video":
            return "video";
        case "tin-tuc":
            return "tin-tuc";
        default:
            break;
    }
};
export const getcomponentRouter = (moduleCode, ActionCode) => {
    if (ActionCode) {
        switch (moduleCode) {
            case "quan-ly-ho-so-ung-vien":
                return <QuanLyUngVienForm controller={"Customer"} />;
            case "quan-ly-don-hang":
                return <QuanLyDonHangForm controller={"Product"} />;
            case "quan-ly-nguoi-dung-mobile-app":
                return <QuanLyNguoiDungMobileAppForm controller={"Customer"} />;
            case "quan-ly-nguoi-dung-he-thong":
                return <QuanLyNguoiDungHeThongForm controller={"Customer"} />;
            case "quan-ly-lop-hoc":
                return <QuanLyLopHocForm controller={"Customer"} />;
            case "quan-ly-xi-nghiep":
                return <QuanLyXiNghiepForm controller={"Company"} />;
            case "quan-ly-thong-bao":
                return <NotifiManagerView />;
            case "quan-ly-ticket":
                return <QuanLyTicketForm controller={"Ticket"} />;
            case "Module":
                return <QuanLyModule controller={"Module"} />;
            case "Role":
                return <QuanLyRole controller={"Role"} />;
            case "doi-mat-khau":
                return <DoiMatKhauForm controller={"Customer"} />;
            case "banner":
                return <QuanLyBanner controller={"Banner"} />;
            case "video":
                return <QuanLyVideoForm controller={"Banner"} />;
            case "tin-tuc":
                return <QuanLyTinTucForm controller={"News"} type={6} />;
            default:
                break;
        }
    } else {
        switch (moduleCode) {
            case "quan-ly-ho-so-ung-vien":
                return <QuanLyUngVien controller={"Customer"} />;
            case "quan-ly-don-hang":
                return <QuanLyDonHang controller={"Product"} />;
            case "quan-ly-nguoi-dung-mobile-app":
                return <QuanLyNguoiDungMobileApp controller={"Customer"} />;
            case "quan-ly-nguoi-dung-he-thong":
                return <QuanLyNguoiDungHeThong controller={"Customer"} />;
            case "quan-ly-lop-hoc":
                return <QuanLyLopHoc controller={"Customer"} />;
            case "quan-ly-xi-nghiep":
                return <QuanLyXiNghiep controller={"Company"} />;
            case "quan-ly-thong-bao":
                return <QuanLyThongBao controller={"Notification"} />;
            case "quan-ly-ticket":
                return <QuanLyTicket controller={"Ticket"} />;
            case "Module":
                return <QuanLyModule controller={"Module"} />;
            case "Role":
                return <QuanLyRole controller={"Role"} />;
            case "doi-mat-khau":
                return <DoiMatKhauForm controller={"Customer"} />;
            case "banner":
                return <QuanLyBanner controller={"Banner"} />;
            case "gioi-thieu-dhm":
                return <BaiVietGioiThieu controller={"News"} type={2} />;
            case "gioi-thieu-nhat-ban":
                return <BaiVietGioiThieu controller={"News"} type={3} />;
            case "gioi-thieu-cac-hinh-thuc-di-nhat":
                return <BaiVietGioiThieu controller={"News"} type={4} />;
            case "video":
                return <QuanLyVideo controller={"Banner"} />;
            case "tin-tuc":
                return <QuanLyTinTuc controller={"News"} type={6} />;
            default:
                break;
        }
    }

};