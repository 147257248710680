/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ControllerDA } from "../../core/controller";
import ConfigAPI from "../../config/configApi";
import { ActionType } from "../../core/ActionType";
import SearchForm from "./Search";
import ListView from "./ListView";
import Pagination from "../../component/pagination/pagination";
import { GET } from "../../assets/const/select-list";
import notiUtil from "../../core/notiUtil";

export default function QuanLyTicket({ controller }) {
    const location = useLocation();

    const [data, setData] = useState([]);
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 });
    const [filterTab, setFilterTab] = useState(1)

    const _InitData = async ({ page, size, query, tab }) => {
        if (localStorage.getItem('listActive')?.includes(GET)) {
            if (!query) {
                query = location.search;
            }
            const queryParams = new URLSearchParams(query);
            const filters = [{
                "field": 'status',
                "operator": "contains",
                "value": tab,
            }];

            queryParams.forEach((value, key) => {
                filters.push({
                    "field": key,
                    "operator": "contains",
                    "value": value,
                });
            });

            var obj = {
                "loadOptions": {
                    "requireTotalCount": true,
                    "skip": (page - 1) * size,
                    "take": size,
                },
                "filter": filters,
                "Action": ActionType.GetAll.value
            }
            const res = await ControllerDA.filterAction(ConfigAPI.baseUrl, controller, obj);
            if (res) {
                setData(res);
            }
            console.log(location);
        } else {
            notiUtil.errors('Tài khoản không có quyền lấy dữ liệu')
          }
    }

    const _Action = async (obj) => {
        let inputData = {
            ...obj,
            Action: ActionType.Edit.value
        }
        const response = await ControllerDA.editAction(ConfigAPI.customerUrl, controller, inputData)
        if (response.code === 200)
            _InitData({ page: pageDetails.page, size: pageDetails.size, query: location.search, tab: filterTab });
    }
    useEffect(() => {
        _InitData({ page: pageDetails.page, size: pageDetails.size, query: location.search, tab: filterTab });
    }, [location.search])

    return (
        <div className="mainView col">
            <div className='view-header col'>
                <div className="view-name heading-6">Quản lý ticket</div>
            </div>
            <div className="view-content-container row">
                <div className="view-content col">
                    <SearchForm />
                    <div className="listView col">
                        <ListView
                            tab={filterTab}
                            data={data?.data}
                            onChangeTab={(newTab) => {
                                setFilterTab(newTab)
                                _InitData({ page: pageDetails.page, size: pageDetails.size, query: location.search, tab: newTab });
                            }}
                            callback_action={_Action}
                        />
                        <div className="footer row">
                            <Pagination
                                /// Size
                                currentPage={pageDetails.page}
                                /// pageSize
                                itemPerPage={pageDetails.size}
                                // data.total
                                totalItem={data?.totalCount ?? 10}
                                /// action
                                onChangePage={(page, size) => {
                                    if (pageDetails.page !== page || pageDetails.size !== size) {
                                        setPageDetails({ page: page, size: size });
                                        _InitData({ page: page, size: size, query: location.search, tab: filterTab });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}