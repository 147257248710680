/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form'
import {
  ReusableInput,
} from '../../component/TextField/text-field'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import { Dialog, DialogStatus, showDialog } from '../../component/dialog/dialog'
import ConfigAPI from '../../config/configApi'
import { ControllerDA } from '../../core/controller'
import { ActionType } from '../../core/ActionType'
import notiUtil from '../../core/notiUtil'
import { postData } from '../../core/BaseDA'
import { EDIT, GET } from '../../assets/const/select-list'

export default function DoiMatKhauForm({ controller }) {
  const {
    register,
    control,
    setValue,
    handleSubmit,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm({ shouldFocusError: false })
  const dialogRef = useRef()
  const [showPass, setShowPass] = useState(false)
  const [showNewPass, setShowNewPass] = useState(false)
  const [repeatNewPass, setRepeatNewPass] = useState(false)

  const _submitForm = () => {
    let data = getValues()
    if (data.passold === data.passnew) {
      setError('passnew', {
        message: 'Mật khẩu mới trùng với mật khẩu hiện tại'
      })
    } else if (data.passnew !== data.repeatNewPass) {
      setError('repeatNewPass', {
        message: 'Nhập lại mật khẩu mới không trùng khớp'
      })
    } else {
      showDialog({
        ref: dialogRef,
        status: DialogStatus.INFOR,
        title: `Xác nhận thay đổi mật khẩu?`,
        submit: () => {
          submitAction(data)
        }
      })
    }
  }

  const submitAction = event => {
    postData(ConfigAPI.customerUrl + controller + `/${ActionType.ChangePass.value}?passold=${event.passold}&passnew=${event.passnew}`, { obj: { Action: ActionType.ChangePass.value } }).then(res => {
      if (res.code === 200) {
        notiUtil.success('Chỉnh sửa mật khẩu thành công')
        localStorage.clear()
        window.location.href = '/'
      } else {
        notiUtil.success(res.message)
      }
    })
  }

  const onError = () => { }

  useEffect(() => {
    if (localStorage.getItem('listActive')?.includes(GET))
      ControllerDA.doAction(ConfigAPI.customerUrl, controller, {
        Action: ActionType.GetInfo.value
      }).then(res => {
        setValue('userName', res.data.userName)
      })
  }, [])

  return (
    <form
      className={`mainView col ${localStorage.getItem('listActive')?.includes(EDIT) ? '' : 'disabled'}`}
      onSubmit={handleSubmit(_submitForm, onError)}
    >
      <Dialog ref={dialogRef} />
      <div className='view-header col'>
        <div className='view-name heading-6'>Đổi mật khẩu</div>
      </div>
      {localStorage.getItem('listActive')?.includes(GET) ? [<div key={'body'} className='view-content-container row'>
        <div className='view-content col'>
          <div className='inputForm view-form col'>
            <div className='header row'>
              <div className='label flex1 heading-7'>Thông tin</div>
            </div>
            <div className='form-card' style={{ width: '50%' }}>
              <ReusableInput
                register={register}
                required={false}
                errors={errors}
                name={'userName'}
                label='Tên đăng nhập'
                disabled={true}
              />
            </div>
            <div className='form-card' style={{ width: '50%' }}>
              <ReusableInput
                register={register}
                required={false}
                errors={errors}
                name={'passold'}
                label='Mật khẩu hiện tại'
                type={showPass ? 'text' : 'password'}
                sufix={
                  <div
                    className='box32 center'
                    onClick={() => setShowPass(!showPass)}
                  >
                    <FontAwesomeIcon
                      size='xs'
                      icon={showPass ? faEyeSlash : faEye}
                    />
                  </div>
                }
              />
            </div>
            <div className='form-card' style={{ width: '50%' }}>
              <ReusableInput
                register={register}
                required={false}
                errors={errors}
                name={'passnew'}
                label='Mật khẩu mới'
                type={showNewPass ? 'text' : 'password'}
                onChange={() => {
                  clearErrors('passnew')
                }}
                sufix={
                  <div
                    className='box32 center'
                    onClick={() => setShowNewPass(!showNewPass)}
                  >
                    <FontAwesomeIcon
                      size='xs'
                      icon={showNewPass ? faEyeSlash : faEye}
                    />
                  </div>
                }
              />
            </div>
            <div className='form-card' style={{ width: '50%' }}>
              <ReusableInput
                register={register}
                required={false}
                errors={errors}
                name={'repeatNewPass'}
                label='Nhập lại mật khẩu mới'
                type={repeatNewPass ? 'text' : 'password'}
                onChange={() => {
                  clearErrors('repeatNewPass')
                }}
                sufix={
                  <div
                    className='box32 center'
                    onClick={() => setRepeatNewPass(!repeatNewPass)}
                  >
                    <FontAwesomeIcon
                      size='xs'
                      icon={repeatNewPass ? faEyeSlash : faEye}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>,
      <div key={'footer'} className='view-footer row'>
        <div className='space'></div>

        <button type='submit' className='submit-button'>
          <span className='button-text-3'>Gửi</span>
        </button>
      </div>] : <div className='empty-page col'>
        <div className='img-empty'></div>
        <div className='heading-6'>Tài khoản không có quyền lấy dữ liệu</div>
      </div>}
    </form>
  )
}
