import { NavLink } from "react-router-dom";
import { ActionType } from "../../core/ActionType";
import GetAction from "../../core/Action";
import { useEffect, useState } from "react";
import { Ultis } from "../../core/Utils";
import { postData } from "../../core/BaseDA";
import ConfigAPI from "../../config/configApi";
import { ADD } from "../../assets/const/select-list";

export default function ListView({ data = [], controller, callback_action }) {
    const [customers, setCustomers] = useState([])

    useEffect(() => {
        const customerListId = data.map(e => e.customerId).filter(e => e != null)
        if (customerListId.length)
            postData(`${ConfigAPI.baseUrl}Customer/GetMutipleInfo`, { obj: customerListId }).then(res => {
                if (res.code === 200) {
                    setCustomers(res.data)
                }
            })
    }, [data])

    return (
        <>
            <div className='listView-header row'>
                <div className='heading-7 space'>Danh sách kết quả</div>
                {localStorage.getItem('listActive')?.includes(ADD) ? <GetAction controller={controller} action={ActionType.Add.value} /> : null}
            </div>
            <div className='listView-body col'>
                <table className='custom-table'>
                    <thead>
                        <tr>
                            <th style={{ minWidth: 75, }} className='fixed-first-column'><div className="center">STT</div></th>
                            <th style={{ minWidth: 360, }} className=''>Tiêu đề</th>
                            <th style={{ minWidth: 172, }} className=''>Người tạo</th>
                            <th style={{ minWidth: 172, }} className=''>Ngày tạo</th>
                            <th style={{ minWidth: 360, }} className=''>Nội dung</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (data ?? []).map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{ minWidth: 75, }} className='fixed-first-column'><div className="center">{index + 1}</div></td>
                                        <td style={{ minWidth: 360, }} className=''>{item.title}</td>
                                        <td style={{ minWidth: 172, }} className=''>{item.customerId ? customers.find(e => e.id === item.customerId)?.name : ""}</td>
                                        <td style={{ minWidth: 172, }} className='' >{item.dateCreated ? Ultis.datetoString(new Date(item.dateCreated)) : ""}</td>
                                        <td style={{ minWidth: 360, }} className=''>{item.content}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

        </>
    )
}