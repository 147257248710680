import CryptoJS from "crypto-js";
const secretPass = "lkjhgndsa123!@#";
export default class encrypt {
    static encryptData = (text) => {
        const data = CryptoJS.AES.encrypt(
            JSON.stringify(text),
            secretPass
        ).toString();
        return data;
    };
    static decryptData = (text) => {
        if(text){
            const bytes = CryptoJS.AES.decrypt(text, secretPass);
            const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return data;
        }
        return null;
        
    };
}
