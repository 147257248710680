/* eslint-disable eqeqeq */
import { useForm } from "react-hook-form";
import { ReusableInput, ReusableSelect1 } from "../../component/TextField/text-field";
import { ControllerDA } from "../../core/controller";
import notiUtil from "../../core/notiUtil";
import ConfigAPI from "../../config/configApi";
import { ActionType } from "../../core/ActionType";
import { useRef } from "react";
import { Dialog, DialogStatus, showDialog } from "../../component/dialog/dialog";
import { notiRecieve } from "../../assets/const/select-list";

export default function NotifiManagerView() {
    const { register, control, handleSubmit, getValues, watch, formState: { errors } } = useForm({ shouldFocusError: false });
    const dialogRef = useRef()

    const onSubmit = () => {
        showDialog({
            ref: dialogRef,
            status: DialogStatus.INFOR,
            title: `Xác nhận tạo thông báo?`,
            submit: sendNotifi
        })
    }

    const sendNotifi = async () => {
        var data = {
            "to": `/topics/${getValues('groupReceive') != 5 ? getValues('groupReceive') : "All"}`,
            "notification": {
                "body": `${getValues('content')}`,
                "title": `${getValues('title')}`,
                "icon": "https://cdn-icons-png.flaticon.com/256/124/124010.png"
            },
            "data": {
                "click_action": "FLUTTER_NOTIFICATION_CLICK",
                "body": "Body of Your Notification in Data",
                "title": "Title of Your Notification in Title",
                "url": "HOME2",
                "key_2": "Value for key_2",
                "pattner_id": "90349279303689920881142",
            }
        };
        const res = await ControllerDA.pushNoti(data);
        if (res) {
            const obj = {
                "type": 0,
                "isRead": false,
                "title": data.notification.title,
                "dateCreated": new Date().getTime(),
                "content": data.notification.body,
                "isDeleted": false,
                "customerId": JSON.parse(localStorage.getItem('customer'))?.id,
                "Action": ActionType.Add.value,
            }
            await ControllerDA.doAction(ConfigAPI.noti, "Notification", obj)
            notiUtil.success("Gửi thông báo thành công");
            window.history.back()
        }
    }

    return (
        <form className="mainView col" onSubmit={handleSubmit(onSubmit)}>
            <Dialog ref={dialogRef} />
            <div className='view-header col'>
                <div className="view-name heading-6">Quản lý thông báo</div>
            </div>
            <div className="view-content-container row">
                <div className="view-content col">
                    <div className="inputForm view-form row-2 row" >
                        <div className='header row'>
                            <div className='label flex1 heading-7'>Thông tin</div>
                        </div>
                        <div className="form-card">
                            <ReusableSelect1
                                value={watch("groupReceive")}
                                label='Người nhận'
                                name='groupReceive'
                                control={control}
                                required={false}
                                errors={errors}
                                options={notiRecieve}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <ReusableInput
                                register={register}
                                required={false}
                                errors={errors}
                                name={"title"}
                                label="Tiêu đề thông báo"
                            />
                        </div>

                        <div style={{ width: "100%" }}>
                            <div className='TextFieldContainer col'>
                                <div className='label-container row'>
                                    <span className='label-3'>Nội dung thông báo</span>
                                    {/* <span className='required-icon regular2'>*</span> */}
                                </div>
                                <div className='input-container row'>
                                    <div className={`input-border row `}>
                                        <textarea
                                            {...register("content")}
                                            autoComplete='off'
                                            type='text'
                                            placeholder='Nội dung thông báo'
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className='view-footer row'>
                <button type='button' className='close-button' onClick={() => {
                    showDialog({
                        ref: dialogRef,
                        status: DialogStatus.WARNING,
                        title: `Xác nhận thoát tạo thông báo?`,
                        content: 'Thông tin thông báo mới đã nhập sẽ không được lưu lại',
                        submit: () => {
                            window.history.back()
                        }
                    })
                }}><span className='button-text-3'>Thoát</span></button>
                <div className='space'></div>

                <button type='submit' className='submit-button'><span className='button-text-3'>Gửi</span></button>
            </div>
        </form>
    )
}