/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { ControllerDA } from '../../core/controller';
import { ActionType } from '../../core/ActionType';
import ConfigAPI from '../../config/configApi';
import { Dialog, DialogStatus, showDialog } from '../../component/dialog/dialog';
import notiUtil from '../../core/notiUtil';
import CheckboxComponent from '../../component/checkbox/checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { SelectMultiple } from '../../component/input-multi-select/input-multi-select';
import { useForm } from 'react-hook-form';
import './module.css'
import encrypt from '../../core/encrypt';
import { postData } from '../../core/BaseDA';


const plusSvg = <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.60598 1.93956C8.60598 1.60484 8.33464 1.3335 7.99992 1.3335C7.6652 1.3335 7.39386 1.60484 7.39386 1.93956V7.3941H1.93931C1.60459 7.3941 1.33325 7.66544 1.33325 8.00016C1.33325 8.33488 1.60459 8.60622 1.93931 8.60622H7.39386V14.0608C7.39386 14.3955 7.6652 14.6668 7.99992 14.6668C8.33464 14.6668 8.60598 14.3955 8.60598 14.0608V8.60622H14.0605C14.3952 8.60622 14.6666 8.33488 14.6666 8.00016C14.6666 7.66544 14.3952 7.3941 14.0605 7.3941H8.60598V1.93956Z" fill="#00204D" fillOpacity="0.6" />
</svg>
const pencilSvg = <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5073 14.6668H1.91297C1.75922 14.6668 1.61176 14.6058 1.50305 14.497C1.39433 14.3883 1.33325 14.2409 1.33325 14.0871C1.33325 13.9334 1.39433 13.7859 1.50305 13.6772C1.61176 13.5685 1.75922 13.5074 1.91297 13.5074H13.5073C13.661 13.5074 13.8085 13.5685 13.9172 13.6772C14.0259 13.7859 14.087 13.9334 14.087 14.0871C14.087 14.2409 14.0259 14.3883 13.9172 14.497C13.8085 14.6058 13.661 14.6668 13.5073 14.6668Z" fill="#00204D" fillOpacity="0.6" />
    <path d="M8.04984 3.65223L3.24226 8.45982C3.16793 8.53418 3.11523 8.62736 3.08979 8.72938L1.91297 12.348L5.53155 11.1711C5.63357 11.1457 5.72675 11.093 5.80112 11.0187L10.6087 6.2111L8.04984 3.65223Z" fill="#00204D" fillOpacity="0.6" />
    <path d="M12.7577 3.24238L11.0186 1.50323C10.9098 1.39455 10.7624 1.3335 10.6087 1.3335C10.455 1.3335 10.3076 1.39455 10.1988 1.50323L8.86955 2.83252L11.4284 5.39138L12.7577 4.06209C12.8664 3.95338 12.9274 3.80595 12.9274 3.65223C12.9274 3.49851 12.8664 3.35109 12.7577 3.24238Z" fill="#00204D" fillOpacity="0.6" />
</svg>
const trashSvg = <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.48327 13.1191C3.5146 13.5389 3.70316 13.9315 4.01129 14.2184C4.31943 14.5054 4.72446 14.6655 5.14549 14.6668H10.8544C11.2754 14.6655 11.6804 14.5054 11.9886 14.2184C12.2967 13.9315 12.4853 13.5389 12.5166 13.1191L13.041 5.77794H2.95882L3.48327 13.1191Z" fill="#00204D" fillOpacity="0.6" />
    <path d="M14.111 3.55572H10.7777V1.88905C10.7777 1.74171 10.7192 1.6004 10.615 1.49621C10.5108 1.39203 10.3695 1.3335 10.2221 1.3335H5.7777C5.63035 1.3335 5.48905 1.39203 5.38486 1.49621C5.28067 1.6004 5.22214 1.74171 5.22214 1.88905V3.55572H1.88881C1.74147 3.55572 1.60016 3.61425 1.49597 3.71844C1.39178 3.82262 1.33325 3.96393 1.33325 4.11127C1.33325 4.25862 1.39178 4.39992 1.49597 4.50411C1.60016 4.6083 1.74147 4.66683 1.88881 4.66683H14.111C14.2584 4.66683 14.3997 4.6083 14.5039 4.50411C14.6081 4.39992 14.6666 4.25862 14.6666 4.11127C14.6666 3.96393 14.6081 3.82262 14.5039 3.71844C14.3997 3.61425 14.2584 3.55572 14.111 3.55572ZM6.33325 2.44461H9.66659V3.55572H6.33325V2.44461Z" fill="#00204D" fillOpacity="0.6" />
</svg>
const indicatorSvg = <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.66683 3.00016C6.66683 3.91683 5.91683 4.66683 5.00016 4.66683C4.0835 4.66683 3.3335 3.91683 3.3335 3.00016C3.3335 2.0835 4.0835 1.3335 5.00016 1.3335C5.91683 1.3335 6.66683 2.0835 6.66683 3.00016Z" fill="#00204D" fillOpacity="0.4" />
    <path d="M3.3335 8.00016C3.3335 7.0835 4.0835 6.3335 5.00016 6.3335C5.91683 6.3335 6.66683 7.0835 6.66683 8.00016C6.66683 8.91683 5.91683 9.66683 5.00016 9.66683C4.0835 9.66683 3.3335 8.91683 3.3335 8.00016Z" fill="#00204D" fillOpacity="0.4" />
    <path d="M3.3335 13.0002C3.3335 12.0835 4.0835 11.3335 5.00016 11.3335C5.91683 11.3335 6.66683 12.0835 6.66683 13.0002C6.66683 13.9168 5.91683 14.6668 5.00016 14.6668C4.0835 14.6668 3.3335 13.9168 3.3335 13.0002Z" fill="#00204D" fillOpacity="0.4" />
    <path d="M12.6668 3.00016C12.6668 3.91683 11.9168 4.66683 11.0002 4.66683C10.0835 4.66683 9.3335 3.91683 9.3335 3.00016C9.3335 2.0835 10.0835 1.3335 11.0002 1.3335C11.9168 1.3335 12.6668 2.0835 12.6668 3.00016Z" fill="#00204D" fillOpacity="0.4" />
    <path d="M9.3335 8.00016C9.3335 7.0835 10.0835 6.3335 11.0002 6.3335C11.9168 6.3335 12.6668 7.0835 12.6668 8.00016C12.6668 8.91683 11.9168 9.66683 11.0002 9.66683C10.0835 9.66683 9.3335 8.91683 9.3335 8.00016Z" fill="#00204D" fillOpacity="0.4" />
    <path d="M9.3335 13.0002C9.3335 12.0835 10.0835 11.3335 11.0002 11.3335C11.9168 11.3335 12.6668 12.0835 12.6668 13.0002C12.6668 13.9168 11.9168 14.6668 11.0002 14.6668C10.0835 14.6668 9.3335 13.9168 9.3335 13.0002Z" fill="#00204D" fillOpacity="0.4" />
</svg>
const searchSvg = <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.0289 10.8703C9.11306 11.6029 7.95131 12.041 6.68724 12.041C3.73045 12.041 1.3335 9.64403 1.3335 6.68724C1.3335 3.73045 3.73045 1.3335 6.68724 1.3335C9.64403 1.3335 12.041 3.73045 12.041 6.68724C12.041 7.95135 11.6029 9.11313 10.8702 10.029L14.6668 13.8256L13.8255 14.6669L10.0289 10.8703ZM2.52322 6.68724C2.52322 4.38751 4.38751 2.52322 6.68724 2.52322C8.98697 2.52322 10.8513 4.38751 10.8513 6.68724C10.8513 7.80878 10.4079 8.82675 9.68682 9.57544L9.57544 9.68682C8.82675 10.4079 7.80878 10.8513 6.68724 10.8513C4.38751 10.8513 2.52322 8.98697 2.52322 6.68724Z" fill="#00204D" fillOpacity="0.6" />
</svg>



export default function QuanLyModule({ controller }) {
    const [list, setList] = useState([])
    const [searchList, setSearchList] = useState()
    const [selected, setSelected] = useState()

    const diaglogRef = useRef()

    const toggleChildren = (item) => {
        item.hideChildren = !item.hideChildren
        setList(list.map(e => {
            if (e.id === item.id) {
                e.hideChildren = item.hideChildren
            } else if (e.parentId === item.id) {
                e.hide = item.hideChildren
            }
            return e
        }))
    }

    const popupAddEdit = (item, parentId = 1) => {
        showDialog({
            ref: diaglogRef,
            children: <PopupAddEditModule ref={diaglogRef} item={item} parentId={parentId} onSubmit={(ev) => {
                if (item) {
                    ControllerDA.editAction(ConfigAPI.customerUrl, controller, { Action: item ? ActionType.Edit.value : ActionType.Add.value, ...ev }).then(res => {
                        if (res.code === 200) {
                            if (item) {
                                notiUtil.success('Cập nhật module thành công')
                            } else {
                                notiUtil.success('Thêm mới module thành công')
                            }
                            ControllerDA.doAction(ConfigAPI.customerUrl, 'Module', { Action: 'GetListSimple_new' }).then(lstModule => {
                                localStorage.setItem(
                                    "_t",
                                    encrypt.encryptData(JSON.stringify(lstModule))
                                )
                                window.location.reload()
                            })
                        } else {
                            notiUtil.success(res.message)
                        }
                    })
                } else {
                    ControllerDA.doAction(ConfigAPI.customerUrl, controller, { Action: ActionType.Add.value, ...ev, parentId: parentId, isDelete: false, isShow: true }).then(res => {
                        if (res.code === 200) {
                            notiUtil.success('Thêm mới module thành công')
                            ControllerDA.doAction(ConfigAPI.customerUrl, 'Module', { Action: 'GetListSimple_new' }).then(lstModule => {
                                localStorage.setItem(
                                    "_t",
                                    encrypt.encryptData(JSON.stringify(lstModule))
                                )
                                window.location.reload()
                            })
                        } else {
                            notiUtil.success(res.message)
                        }
                    })
                }
            }} />
        })
    }

    const _InitData = () => {
        ControllerDA.actionGet(ConfigAPI.customerUrl, controller, { Action: 'GetAll' }).then(res => {
            if (res.code === 200)
                setList(res.data.sort((a, b) => a.sort - b.sort).map(e => {
                    const oldE = list.find(el => el.id === e.id)
                    e.hide = oldE?.hide
                    e.hideChildren = oldE?.hideChildren
                    return e
                }))
        })
    }

    useEffect(() => {
        _InitData()
    }, []);


    return (
        <div className="mainView col">
            <div className="view-content-container row" style={{ padding: '24px 32px' }}>
                <div className="view-content col">
                    <div className="listView col" style={{ padding: '0 32px' }}>
                        <div className='listView-header row' style={{ padding: 0 }}>
                            <Dialog ref={diaglogRef} />
                            <div className='heading-7 space'>Quản lý module</div>
                            {/* <button type="button" className='nav-link addButton row button-text-3' onClick={() => popupAddEdit()}>
                                Thêm mới
                                <div className="row" style={{ width: 16, height: 16 }}>
                                    <svg width='100%' height='100%' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.60574 1.93809C8.60574 1.60337 8.33439 1.33203 7.99967 1.33203C7.66496 1.33203 7.39361 1.60337 7.39361 1.93809V7.39264H1.93907C1.60435 7.39264 1.33301 7.66398 1.33301 7.9987C1.33301 8.33342 1.60435 8.60476 1.93907 8.60476H7.39361V14.0593C7.39361 14.394 7.66496 14.6654 7.99967 14.6654C8.33439 14.6654 8.60574 14.394 8.60574 14.0593V8.60476H14.0603C14.395 8.60476 14.6663 8.33342 14.6663 7.9987C14.6663 7.66398 14.395 7.39264 14.0603 7.39264H8.60574V1.93809Z" fill="white" />
                                    </svg>
                                </div>
                            </button> */}
                        </div>
                        <div className="row table-search">
                            <div className="row" style={{ width: 16, height: 16 }}>{searchSvg}</div>
                            <input placeholder="Tìm kiếm" onChange={(ev) => {
                                if (ev.target.value.trim().length) {
                                    const filterList = list.filter(e => e.name.toLowerCase().includes(ev.target.value.trim().toLowerCase()))
                                    setSearchList(list.filter(e => filterList.some(el => el.id === e.id || el.parentId === e.id)).map(e => {
                                        return {
                                            ...JSON.parse(JSON.stringify(e)),
                                            hide: false,
                                            hideChildren: false
                                        }
                                    }))
                                } else {
                                    setSearchList(null)
                                }
                            }} />
                        </div>
                        <div className='listView-body col'>
                            <table className='custom-table'>
                                <thead>
                                    <tr>
                                        {/* <th style={{ minWidth: 44, padding: 0 }} className='fixed-first-column'>
                                            <div className="center"><CheckboxComponent /></div>
                                        </th> */}
                                        <th style={{ minWidth: 150 }} className='fixed-first-column'>Diagram Name</th>
                                        <th style={{ minWidth: 172, }} className=''>Role</th>
                                        <th style={{ minWidth: 172, }} className='' >Url</th>
                                        <th style={{ minWidth: 360, }} className='' >Mô tả</th>
                                        <th style={{ minWidth: 112, }} className='fixed-last-column'></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (searchList ?? list).map((item, index) => {
                                            if (item.parentId !== 1) item.hide ??= true
                                            else item.hideChildren ??= true
                                            return (
                                                <tr
                                                    id={item.id}
                                                    parentid={item.parentId}
                                                    key={item.id}
                                                    className={"tb-tr-item " + (selected?.id === item.id ? 'selected ' : '') + (item.ondrag ? 'ondrag' : '')}
                                                    onClick={(ev) => { setSelected(item) }}
                                                    style={item.hide ? { display: 'none' } : null}
                                                >
                                                    {/* <td style={{ minWidth: 24, padding: 0 }} className='fixed-first-column'><div className="center"><CheckboxComponent /></div></td> */}
                                                    <td style={{ minWidth: 360 }} className='fixed-first-column'>
                                                        <div className="row" style={{ columnGap: 8 }}>
                                                            {item.parentId !== 1 ? selected?.id === item.id ?
                                                                <div draggable="true" className="row" style={{ width: '12.5px', height: '12.5px', cursor: 'pointer' }}
                                                                    onDrag={(ev) => {
                                                                        const thisTr = ev.target.closest('.tb-tr-item')
                                                                        const closestTr = [...document.querySelectorAll(`.tb-tr-item[parentid="${item.parentId}"]`)].filter(e => e !== thisTr).sort((a, b) => {
                                                                            const aRect = a.getBoundingClientRect()
                                                                            const bRect = b.getBoundingClientRect()
                                                                            return Math.abs(ev.pageY - (aRect.y + aRect.height / 2)) - Math.abs(ev.pageY - (bRect.y + bRect.height / 2))
                                                                        })[0]
                                                                        item.ondrag = true
                                                                        let newList = list.filter(e => e.id !== item.id)
                                                                        let index = newList.findIndex(e => e.id == closestTr.id)
                                                                        const closestRect = closestTr.getBoundingClientRect()
                                                                        if ((ev.pageY - (closestRect.y + closestRect.height / 2)) < 0) index--
                                                                        newList = [...newList.slice(0, index + 1), item, ...newList.slice(index + 1)]
                                                                        setList(newList)
                                                                    }}
                                                                    onDragEnd={() => {
                                                                        const listSort = list.filter(e => e.parentId === item.parentId)
                                                                        postData(ConfigAPI.customerUrl + `${controller}/EditSort?id=${item.id}`, {
                                                                            obj: listSort.map((e, i) => {
                                                                                return {
                                                                                    id: e.id,
                                                                                    sort: i
                                                                                }
                                                                            })
                                                                        }).then(res => {
                                                                            if (res.code === 200) {
                                                                                ControllerDA.doAction(ConfigAPI.customerUrl, 'Module', { Action: 'GetListSimple_new' }).then(lstModule => {
                                                                                    localStorage.setItem(
                                                                                        "_t",
                                                                                        encrypt.encryptData(JSON.stringify(lstModule))
                                                                                    )
                                                                                    window.location.reload()
                                                                                })
                                                                            }
                                                                        })
                                                                        setList(list.filter(e => {
                                                                            e.ondrag = false
                                                                            return e
                                                                        }))
                                                                    }}
                                                                >{indicatorSvg}</div> : <div style={{ width: '12.5px', height: '12.5px' }}></div> :
                                                                <div className="row" style={{ width: '12.5px', height: '12.5px', cursor: 'pointer' }} onClick={() => toggleChildren(item)}>
                                                                    <FontAwesomeIcon icon={item.hideChildren ? faCaretRight : faCaretDown} style={{ fontSize: '12.5px', color: 'rgba(0, 32, 77, 0.6)' }} />
                                                                </div>}
                                                            <div className="regular2">{item.name ?? ""}</div>
                                                        </div>
                                                    </td>
                                                    <td style={{ minWidth: 150, }} className="">{(item.roleModuleItems ?? []).map(e => e.nameRole).join(',')}</td>
                                                    <td style={{ minWidth: 172, }} className=''>{item.link ?? "-"}</td>
                                                    <td style={{ minWidth: 360, }} className=''>{item.content ?? ''}</td>
                                                    <td style={{ minWidth: 112, }} className='fixed-last-column row'>
                                                        {/* {item.parentId !== 1 ? <div style={{ width: 36 }}></div> : <button type="button" className="deleteButton box32 center" onClick={() => { popupAddEdit(null, item.id) }}>
                                                            {plusSvg}
                                                        </button>} */}
                                                        <button type="button" className="deleteButton box32 center" onClick={() => { popupAddEdit(item, item.parentId); }}>
                                                            {pencilSvg}
                                                        </button>
                                                        {/* <button type="button" className="deleteButton box32 center" onClick={() => { confirmDelete(item); }}>
                                                            {trashSvg}
                                                        </button> */}
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const PopupAddEditModule = forwardRef(function PopupAddEditModule(data, ref) {
    const methods = useForm({
        shouldFocusError: false,
        defaultValues: data.item ? {
            ...data.item,
            roleModuleItems: data.item.roleModuleItems.map(e => {
                return {
                    ...e,
                    id: e.roleId,
                    name: e.nameRole
                }
            })
        } : null
    })
    const [roleList, setRoleList] = useState([])

    const _submitForm = (ev) => {
        ev.roleModuleItems = ev.roleModuleItems.map(e => { return { roleId: e.id } })
        ref.current.closeDialog()
        if (data.onSubmit) data.onSubmit(ev)
    }

    useEffect(() => {
        ControllerDA.actionGet(ConfigAPI.customerUrl, 'Role', { Action: 'GetAll' }).then(res => {
            if (res.code === 200) {
                setRoleList(res.data)
            }
        })
    }, [])

    return <div>
        <div className="popup-header row">{data.item ? 'Chỉnh sửa module' : 'Thêm mới module'}</div>
        <div className="col popup-body" style={{ width: 700, padding: 24 }}>
            <div className="row">
                <div className="label">Tên module<span className='regular2' style={{ color: 'red' }}>*</span></div>
                <div className={`input-border ${methods.formState.errors['name'] && 'helper-text'}`}>
                    <input placeholder="Nhập tên module" name="name" {...methods.register('name', { required: true })} />
                    {methods.formState.errors['name'] && <div className='helper-text regular1'>Vui lòng nhập tên module</div>}
                </div>
            </div>
            {/* <div className="row">
                <div className="label">Url</div>
                <div className="input-border">
                    <input placeholder="Nhập Url" name="link" {...methods.register('link')} />
                </div>
            </div> */}
            {
                data.parentId !== 1 ? null : <div className="row">
                    <div className="label">Role</div>
                    <div style={{ flex: 1 }}>
                        <SelectMultiple
                            control={methods.control}
                            name={'roleModuleItems'}
                            value={methods.watch('roleModuleItems')}
                            options={roleList}
                            placeholder="Chọn role"
                            errors={methods.formState.errors}

                        />
                    </div>
                </div>
            }
            <div className="row">
                <div className="label">Mô tả</div>
                <div className={`input-border row`}>
                    <textarea
                        autoComplete='off'
                        className='regular1'
                        placeholder={'Nhập mô tả'}
                        name="content"
                        {...methods.register('content')}
                    />
                </div>
            </div>
        </div>
        <div className='dialog-footer row' style={{ padding: '0 24px 24px 24px' }}>
            <button type='button' onClick={() => ref.current.closeDialog()} className='dialog-action' >Quay lại</button>
            <button type='button' className='dialog-action dialog-submit' onClick={methods.handleSubmit(_submitForm)} >Lưu</button>
        </div>
    </div>
})