/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form'
import {
  ReusableInput,
  ReusableSelect1
} from '../../component/TextField/text-field'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import {
  Dialog,
  DialogStatus,
  showDialog
} from './../../component/dialog/dialog'
import { Ultis } from '../../core/Utils'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ReusableDatePicker from '../../component/date-picker/date-picker'
import ConfigAPI from '../../config/configApi'
import { ControllerDA } from '../../core/controller'
import { ActionType } from '../../core/ActionType'
import notiUtil from './../../core/notiUtil'
import { ADD, EDIT } from '../../assets/const/select-list'
import { editorConfiguration } from '../../component/ckeditor/ckeditor'

export default function QuanLyXiNghiepForm({ controller }) {
  const location = useLocation()

  const {
    register,
    control,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors }
  } = useForm({ shouldFocusError: false })

  const { id } = useParams()

  const dialogRef = useRef()

  const pictureRef = useRef()
  const [partnerList, setPartnerList] = useState([])

  const handleImageChange = async e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      const resFile = await ControllerDA.uploadFile({ file: img })
      if (resFile) {
        setValue('pictureId', resFile.data.id)
      }
    }
  }

  const _submitForm = () => {
    showDialog({
      ref: dialogRef,
      status: DialogStatus.INFOR,
      title: `Xác nhận ${id ? 'chỉnh sửa' : 'tạo'} thông tin xí nghiệp?`,
      submit: () => {
        let data = getValues()
        if (data.establishDate?.length) data.establishDate = Ultis.stringToDate(data.establishDate).getTime()
        else {
          delete data.establishDate
        }
        if (data.companySize) data.companySize = JSON.stringify(data.companySize)
        submitAction(data)
      }
    })
  }

  const submitAction = event => {
    if (id) {
      event.Action = ActionType.Edit.value
      ControllerDA.editAction(ConfigAPI.customerUrl, controller, event).then(
        res => {
          if (res.code === 200) {
            notiUtil.success('Chỉnh sửa thông tin xí nghiệp thành công')
            window.history.back()
          } else {
            notiUtil.success(res.message)
          }
        }
      )
    } else {
      event.dateCreated = new Date().getTime()
      event.Action = ActionType.Add.value
      ControllerDA.doAction(ConfigAPI.customerUrl, controller, event).then(
        res => {
          if (res.code === 200) {
            notiUtil.success('Tạo mới thông tin xí nghiệp thành công')
            window.history.back()
          } else {
            notiUtil.success(res.message)
          }
        }
      )
    }
  }
  const onError = () => { }

  useEffect(() => {
    ControllerDA.filterAction(
      ConfigAPI.customerUrl,
      'Customer',
      {
        loadOptions: {
          requireTotalCount: true,
          skip: 0,
          take: 100
        },
        filter: [{
          "field": 'type',
          "operator": "contains",
          "value": 2,
        }],
        Action: ActionType.GetAll.value
      },
    ).then(res => {
      if (res.code === 200)
        setPartnerList(res.data)
    })
    if (id) {
      if (!localStorage.getItem('listActive')?.includes(EDIT)) notiUtil.errors('Tài khoản không có quyền chỉnh sửa')
      ControllerDA.getDetail(ConfigAPI.customerUrl, controller, {
        id: id,
        Action: ActionType.GetById.value
      }).then(res => {
        Object.keys(res.data).map(fieldName => {
          switch (fieldName) {
            case 'companySize':
              setValue(
                fieldName,
                res.data[fieldName]?.length > 0
                  ? JSON.parse(res.data[fieldName])
                  : []
              )
              break
            case 'establishDate':
              if (res.data[fieldName])
                setValue(
                  fieldName,
                  Ultis.datetoString(new Date(res.data[fieldName]))
                )
              break
            default:
              setValue(fieldName, res.data[fieldName])
              break
          }
          return 0
        })
      })
    }
  }, [location.pathname])

  const editable = (id && localStorage.getItem('listActive')?.includes(EDIT)) || (!id && localStorage.getItem('listActive')?.includes(ADD))
  return (
    <form className={`admin-add-view mainView col ${editable ? '' : 'disabled'}`} onSubmit={handleSubmit(_submitForm, onError)} >
      <Dialog ref={dialogRef} />
      <div className='view-header col'>
        <div className='view-name heading-6'>
          {id ? 'Chỉnh sửa thông tin xí nghiệp' : 'Thêm mới xí nghiệp'}
        </div>
        <div className='breadcrums row'>
          <NavLink
            to={'/admin/quan-ly-ho-so-ung-vien'}
            className='nav-link regular1'
            type='button'
          >
            Quản lý xí nghiệp
          </NavLink>
          <FontAwesomeIcon className='icon' size='2xs' icon={faChevronRight} />
          <NavLink className='nav-link regular1' type='button'>
            {id ? 'Chỉnh sửa thông tin xí nghiệp' : 'Thêm mới xí nghiệp'}
          </NavLink>
        </div>
      </div>
      <div className='view-content-container row'>
        {/* <div className="view-sidebar"></div> */}
        <div className='view-content col'>
          <div className='scroll-view-container col'>
            {/* //!input form */}
            <div className='inputForm view-form row-2 row'>
              {' '}
              {/* row-3 */}
              <div className='header row'>
                <div className='label flex1 heading-7'>Thông tin chung</div>
              </div>
              <div className='left-item col' style={{ gap: 16 }}>
                <input ref={pictureRef} type='file' onChange={handleImageChange} style={{ display: 'none' }} />
                <img className='image' src={watch('pictureId')
                  ? `${ConfigAPI.fileUrl}api/UploadFile/img/${watch(
                    'pictureId'
                  )}`
                  : ''
                }
                  alt=''
                />

                {editable ? <button
                  type='button'
                  className='upload-image row'
                  onClick={() => {
                    pictureRef.current.click()
                  }}
                >
                  <svg width={14} height={13} viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg' >
                    <path d='M3.66732 3.88889L7.00065 0L10.334 3.88889H7.55621V8.88889H6.4451V3.88889H3.66732Z' fill='#262626' />
                    <path d='M12.5562 11.1111H1.4451V7.77778H0.333984V11.6667C0.333984 11.9733 0.582873 12.2222 0.88954 12.2222H13.1118C13.4184 12.2222 13.6673 11.9733 13.6673 11.6667V7.77778H12.5562V11.1111Z' fill='#262626' />
                  </svg>
                  <span className='button-text-3'>Tải ảnh lên</span>
                </button> : null}
              </div>
              <div className='list-input-container row'>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'name'}
                    label='Tên xí nghiệp'
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'listTopicSmallId'}
                    label='Trụ sở chính'
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    label='Đối tác'
                    name='customerId'
                    value={watch('customerId')}
                    control={control}
                    required={false}
                    errors={errors}
                    options={partnerList.map(e => {
                      return { name: e.name, id: e.id }
                    })}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'listTopicId'}
                    label='Lĩnh vực'
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableDatePicker
                    label={'Ngày thành lập'}
                    errors={errors}
                    register={register}
                    name={'establishDate'}
                    required={false}
                    value={watch('establishDate')}
                    placeholder={'Chọn ngày thành lập'}
                    onChange={value => {
                      if (value) setValue('establishDate', value)
                    }}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'factory'}
                    label='Nhà máy sản xuất'
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'field'}
                    label='Lĩnh vực hoạt động'
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'workshop'}
                    label='Tiểu ngạch'
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'link'}
                    label='Website'
                    required={false}
                  />
                </div>
                <div className='form-card' style={{ width: '100%' }}>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'other'}
                    label='Khác'
                    required={false}
                  />
                </div>
              </div>
            </div>
            <div className='inputForm view-form row-3 row'>
              <div className='header row'>
                <div className='label flex1 heading-7'>Quy mô công ty</div>
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'companySize.fund'}
                  label='Vốn'
                  required={false}
                  type={'number'}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'companySize.income'}
                  label='Doanh thu'
                  required={false}
                  type={'number'}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'companySize.employeeNumber'}
                  label='Số lượng nhân viên'
                  required={false}
                  type={'number'}
                />
              </div>
            </div>
            {/* //!ckeditor */}
            <div className='ckeditor-layout col' style={{ minHeight: '212px' }}>
              <div className='header row '>
                <div className='label heading-7'>Hồ sơ công ty</div>
              </div>
              <div className='ckeditor-form'>
                <CKEditor
                  editor={ClassicEditor}
                  data={watch("document") ?? ""}
                  config={editorConfiguration}

                  onReady={editor => {
                    console.log('Editor is ready to use!', editor);
                  }}
                  disabled={!editable}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setValue("document", data)
                    console.log({ event, editor, data });
                  }}
                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='view-footer row'>
        <button
          type='button'
          className='close-button'
          onClick={() => {
            if (editable) {
              showDialog({
                ref: dialogRef,
                status: DialogStatus.WARNING,
                title: `Xác nhận thoát ${id ? 'chỉnh sửa' : 'tạo'} xí nghiệp?`,
                content: 'Thông tin xí nghiệp đã nhập sẽ không được lưu lại',
                submit: () => {
                  window.history.back()
                }
              })
            } else {
              window.history.back()
            }
          }}
        >
          <span className='button-text-3'>Thoát</span>
        </button>
        <div className='space'></div>
        <button type='submit' className='submit-button'>
          <span className='button-text-3'>Gửi</span>
        </button>
      </div>
    </form>
  )
}
