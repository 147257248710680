/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { postData } from "../../core/BaseDA";
import ConfigAPI from "../../config/configApi";
export default function DanhSachUngVienDoiTac() {
    const location = useLocation();
    const { id } = useParams();
    const [listCustomer, setListCustomer] = useState([]);


    const _InitData = async () => {
        const res = await postData(ConfigAPI.customerUrl + `Customer/ListCustomerByPartnerId?id=${id}`, { obj: { Action: 'ListCustomerByPartnerId' } })
        if (res.code === 200) {
            setListCustomer(res.data)
        }
    }

    useEffect(() => {
        _InitData();
    }, [location.search])


    return (
        <div className="mainView col">
            <div className='view-header col'>
                <div className="view-name heading-6">Quản lý ứng viên đối tác đã chọn</div>
            </div>
            <div className="view-content-container row">
                <div className="view-content col">
                    <div className="scroll-view-container col">
                        <div className="listView col">
                            <div className='listView-header row'>
                                <div className='heading-7 space'>Danh sách ứng viên</div>

                                {/* <GetAction controller={controller} action={ActionType.Add.value} /> */}
                            </div>
                            <div className='listView-body col'>
                                <table className='custom-table'>
                                    <thead>
                                        <tr>
                                            <th style={{ minWidth: 75, }} className='fixed-first-column'><div className="center">STT</div></th>
                                            <th style={{ minWidth: 360, }} className=''>Tên ứng viên</th>
                                            <th style={{ minWidth: 150, }} className=''>Mã hồ sơ</th>
                                            <th style={{ minWidth: 200, }} className=''>Tên tài khoản</th>
                                            <th style={{ minWidth: 200, }} className=''>Số điện thoại</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            listCustomer.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ minWidth: 75, }} className='fixed-first-column'><div className="center">{index + 1}</div></td>
                                                        <td style={{ minWidth: 360, }} className=''>
                                                            <NavLink to={'/admin/thong-tin-ung-vien/' + item.id} className={'nav-link'}>{item.name ?? "-"}</NavLink>
                                                        </td>
                                                        <td style={{ minWidth: 150, }} className=''>{item.code ?? "-"}</td>
                                                        <td style={{ minWidth: 200, }} className=''>{item.userName}</td>
                                                        <td style={{ minWidth: 200, }} className=''>{item.mobile}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}