import { Slide, toast } from 'react-toastify';

export const autoCloseWithCloseFunc = 800;
export const autoCloseNoCloseFunc = 2000;
export default class notiUtil {
    static success(message, onclose) {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        transition: Slide,
        autoClose: onclose ? autoCloseWithCloseFunc : autoCloseNoCloseFunc,
        theme: "colored",
        onClose: onclose ? () => {
          onclose()
        } : null,
      });
    }
    static errors(message, onclose) {
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
        pauseOnHover:false,
        hideProgressBar: true,
        transition: Slide,
        autoClose: autoCloseNoCloseFunc,
        onClose: onclose ? () => {
          onclose()
        } : null,      
      });
    }
    static errorsValidate(message, Id,onClickFunc) {
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
        // closeOnClick: false,
        pauseOnHover:false,
        toastId:Id,
        autoClose: 90000000,
        onClick:()=>{
          onClickFunc(Id)
        }
        // onClose: onclose ? () => {
        //   onclose()
        // } : null,      
      });
    }
  }