export class ActionType {
    static GetAll = { 'id': null, 'value': 'GetListSimple' };
    static NoRole = { 'id': 0, 'value': 'NoRole' };
    static Add = { 'id': 1, 'value': 'Add' };
    static Edit = { 'id': 2, 'value': 'Edit' };
    static Delete = { 'id': 3, 'value': 'Delete' };
    static Active = { 'id': 4, 'value': 'Active' };
    static GetById = { 'id': 5, 'value': 'GetById' };
    static Show = { 'id': 6, 'value': 'Show' };
    static Hide = { 'id': 7, 'value': 'Hide' };
    static UserRole = { 'id': 8, 'value': 'UserRole' };
    static RoleModule = { 'id': 9, 'value': 'RoleModule' };
    static CheckProfile = { 'id': 10, 'value': 'CheckProfile' };
    static GetInfo = { 'id': 11, 'value': 'GetInfo' };
    static ChangePass = { 'id': 12, 'value': 'ChangePass' };
}
