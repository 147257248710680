/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form'
import {
  ReusableInput,
  ReusableSelect2
} from '../../component/TextField/text-field'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import {
  Dialog,
  DialogStatus,
  showDialog
} from './../../component/dialog/dialog'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import ConfigAPI from '../../config/configApi'
import { ControllerDA } from '../../core/controller'
import { ActionType } from '../../core/ActionType'
import notiUtil from './../../core/notiUtil'
import { Ultis } from '../../core/Utils'
import CheckboxComponent from '../../component/checkbox/checkbox'
import { ADD, EDIT, notiNews } from '../../assets/const/select-list'
import { editorConfiguration } from '../../component/ckeditor/ckeditor'

export default function QuanLyTinTucForm({ controller }) {
  const location = useLocation()

  const {
    register,
    control,
    setValue,
    handleSubmit,
    getValues,
    watch,
    formState: { errors }
  } = useForm({ shouldFocusError: false })

  const { id } = useParams()
  const fileInputRef = useRef()

  const dialogRef = useRef()
  const [pushNoti, setPushOnti] = useState(true)

  const handleImageChange = async e => {
    if (e.target.files && e.target.files[0]) {
      let img = e.target.files[0]
      const resFile = await ControllerDA.uploadFile({ file: img })
      if (resFile) {
        setValue('pictureId', resFile.data.id)
      }
    }
  }

  const _submitForm = () => {
    showDialog({
      ref: dialogRef,
      status: DialogStatus.INFOR,
      title: `Xác nhận ${id ? 'chỉnh sửa' : 'tạo'} tin tức?`,
      submit: () => {
        let data = getValues()
        data.type = 6
        data.typeNew = parseInt(data.typeNew)
        submitAction(data)
      }
    })
  }

  const submitAction = event => {
    if (id) {
      event.Action = ActionType.Edit.value
      ControllerDA.editAction(ConfigAPI.newsUrl, controller, event).then(
        res => {
          if (res) {
            window.history.back()
          } else {
            notiUtil.success(res.message)
          }
        }
      )
    } else {
      event.dateCreated = new Date().getTime()
      event.Action = ActionType.Add.value
      ControllerDA.doAction(ConfigAPI.newsUrl, controller, event).then(
        async response => {
          if (response.code === 200) {
            if (pushNoti) {
              const data = {
                to: '/topics/All',
                notification: {
                  body: `Tin tức: ${watch('title')}`,
                  title: 'Đã có thêm 1 tin tức mới',
                  icon: 'https://cdn-icons-png.flaticon.com/256/124/124010.png'
                },
                data: {
                  type: notiNews,
                  click_action: 'FLUTTER_NOTIFICATION_CLICK',
                  body: 'Body of Your Notification in Data',
                  title: 'Title of Your Notification in Title',
                  url: 'HOME2',
                  key_2: 'Value for key_2',
                  pattner_id: '90349279303689920881142',
                  idNews: response.data
                }
              }
              const res = await ControllerDA.pushNoti(data)
              if (res) {
                if (res) {
                  const obj = {
                    type: notiNews,
                    isRead: false,
                    title: 'Đã có thêm 1 tin tức mới',
                    content: `Tin tức: ${watch('title')}`,
                    isDeleted: true,
                    url: response.data,
                    Action: ActionType.Add.value
                  }
                  await ControllerDA.doAction(
                    ConfigAPI.noti,
                    'Notification',
                    obj
                  )
                  notiUtil.success('Gửi thông báo thành công')
                }
              }
            }
            window.history.back()
          } else {
            notiUtil.success(response.message)
          }
        }
      )
    }
  }
  const onError = () => { }

  useEffect(() => {
    if (id)
      if (!localStorage.getItem('listActive')?.includes(EDIT)) notiUtil.errors('Tài khoản không có quyền chỉnh sửa')
    ControllerDA.getDetail(ConfigAPI.newsUrl, controller, {
      id: id,
      Action: ActionType.GetById.value
    }).then(res => {
      if (res) {
        Object.keys(res.data).map(fieldName => {
          if (fieldName === 'dateCreated') {
            if (res.data[fieldName])
              setValue(
                fieldName,
                Ultis.datetoString(new Date(res.data[fieldName]))
              )
          } else {
            setValue(fieldName, res.data[fieldName])
          }
          return 0
        })
      }
    })
  }, [location.pathname])

  const editable = (id && localStorage.getItem('listActive')?.includes(EDIT)) || (!id && localStorage.getItem('listActive')?.includes(ADD))
  return (
    <form className={`admin-add-view mainView col ${editable ? '' : 'disabled'}`} onSubmit={handleSubmit(_submitForm, onError)} >
      <Dialog ref={dialogRef} />
      <div className='view-header col'>
        <div className='view-name heading-6'>
          {id ? 'Chỉnh sửa tin tức' : 'Thêm mới tin tức'}
        </div>
        <div className='breadcrums row'>
          <NavLink
            to={'/admin/quan-ly-ho-so-ung-vien'}
            className='nav-link regular1'
            type='button'
          >
            Quản lý tin tức
          </NavLink>
          <FontAwesomeIcon className='icon' size='2xs' icon={faChevronRight} />
          <NavLink className='nav-link regular1' type='button'>
            {id ? 'Chỉnh sửa tin tức' : 'Thêm mới tin tức'}
          </NavLink>
        </div>
      </div>
      <div className='view-content-container row'>
        <div className='view-content col'>
          <div className='scroll-view-container'>
            {/* //!input form */}
            <div className='inputForm view-form row-3 row'>
              {/* row-3 */}
              <div className='header row'>
                <div className='label flex1 heading-7'>Thông tin chung</div>
                {id ? null : <CheckboxComponent
                  value={pushNoti}
                  onClick={() => setPushOnti(!pushNoti)}
                  label={'Gửi thông báo đển người dùng'}
                />}
              </div>
              <div className='col' style={{ gap: 16, alignItems: 'center' }}>
                <input
                  ref={fileInputRef}
                  type='file'
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                />
                <img className='banner-image' src={watch('pictureId')?.length > 0 ? `${ConfigAPI.fileUrl}api/UploadFile/img/${watch('pictureId')}` : ''} alt='' />
                {editable ? <button
                  type='button'
                  className='upload-image row'
                  onClick={() => {
                    fileInputRef.current.click()
                  }}
                  style={{ width: 'fit-content' }}
                >
                  <svg width={14} height={13} viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg' >
                    <path d='M3.66732 3.88889L7.00065 0L10.334 3.88889H7.55621V8.88889H6.4451V3.88889H3.66732Z' fill='#262626' />
                    <path d='M12.5562 11.1111H1.4451V7.77778H0.333984V11.6667C0.333984 11.9733 0.582873 12.2222 0.88954 12.2222H13.1118C13.4184 12.2222 13.6673 11.9733 13.6673 11.6667V7.77778H12.5562V11.1111Z' fill='#262626' />
                  </svg>
                  <span className='button-text-3'>Tải ảnh lên</span>
                </button> : null}
              </div>
              <div className='form-card col' style={{ flex: 1, width: '100%', rowGap: 24 }}>
                <div className='form-card' style={{ width: '100%' }}>
                  <ReusableInput
                    label={'Tên tin tức'}
                    register={register}
                    errors={errors}
                    name={'title'}
                    required={false}
                  />
                </div>
                <div className='form-card' style={{ width: '100%' }}>
                  <ReusableSelect2
                    label={'Loại tin tức'}
                    register={register}
                    errors={errors}
                    name={'typeNew'}
                    control={control}
                    value={watch('typeNew')}
                    valueType={'int'}
                    required={false}
                    options={[
                      { name: 'Nhật Bản', id: 1 },
                      { name: 'Việt Nam', id: 2 }
                    ]}
                  />
                </div>
              </div>
            </div>
            {/* //!ckeditor */}
            <div className='ckeditor-layout col'>
              <div className='header row '>
                <div className='label heading-7'>Nội dung</div>
              </div>
              <div className='ckeditor-form'>
                <CKEditor
                  editor={ClassicEditor}
                  data={watch('content')}
                  config={editorConfiguration}
                  onReady={editor => {
                    // You can store the "editor" and use when it is needed.
                    console.log('Editor is ready to use!', editor)

                    // editor.ui.view.editable.element.style.minHeight = "300px";
                  }}
                  disabled={!editable}
                  onChange={(event, editor) => { }}
                  onBlur={(event, editor) => {
                    setValue('content', editor.getData())
                    console.log('Blur.', editor)
                    // editor.ui.view.editable.element.style.minHeight = "300px";
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor)
                    // editor.ui.view.editable.element.style.minHeight = "300px";
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='view-footer row'>
        <button
          type='button'
          className='close-button'
          onClick={() => {
            if (editable) {
              showDialog({
                ref: dialogRef,
                status: DialogStatus.WARNING,
                title: `Xác nhận thoát ${id ? 'chỉnh sửa' : 'tạo'} tin tức?`,
                content: 'Thông tin tin tức đã nhập sẽ không được lưu lại',
                submit: () => {
                  window.history.back()
                }
              })
            } else {
              window.history.back()
            }
          }}
        >
          <span className='button-text-3'>Thoát</span>
        </button>
        <div className='space'></div>
        <button type='submit' className='submit-button'>
          <span className='button-text-3'>Gửi</span>
        </button>
      </div>
    </form>
  )
}
