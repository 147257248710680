import { useEffect, useRef, useState } from 'react'
import SearchComponent from '../component/search'
import './header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsis, faUserCircle } from '@fortawesome/free-solid-svg-icons'

export default function HeaderView() {
    const [showNofiContainer, setShowNotiContainer] = useState(false)
    const notiContainerRef = useRef(null)

    // Handler để kiểm tra click outside
    const handleClickOutside = event => {
        if (
            notiContainerRef.current &&
            !notiContainerRef.current.contains(event.target)
        ) {
            setShowNotiContainer(false)
        }
    }

    // Thêm event listener khi component mount và gỡ bỏ khi unmount
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <div className='header-view row'>
            {/* <div className='space'></div> */}

            <button
                type='button'
                className='more-action-button box20'
                onClick={() => {
                    let siderbar = document.body.querySelector(`.sidebar-view`)
                    if (siderbar.classList.contains('hidden')) {
                        siderbar.classList.remove('hidden')
                        siderbar.classList.add('show')
                    } else {
                        siderbar.classList.remove('show')
                        siderbar.classList.add('hidden')
                    }
                }}
            >
                <svg width={20} height={20} viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' >
                    <g clipPath='url(#clip0_12284_65280)'>
                        <path d='M17.6392 8.88856H2.36144C1.94477 8.88856 1.66699 9.16634 1.66699 9.58301V10.9719C1.66699 11.3886 1.94477 11.6663 2.36144 11.6663H17.6392C18.0559 11.6663 18.3337 11.3886 18.3337 10.9719V9.58301C18.3337 9.16634 18.0559 8.88856 17.6392 8.88856Z' fill='#282829' fillOpacity='0.8' />
                        <path d='M17.6392 3.33301H2.36144C1.94477 3.33301 1.66699 3.61079 1.66699 4.02745V5.41634C1.66699 5.83301 1.94477 6.11079 2.36144 6.11079H17.6392C18.0559 6.11079 18.3337 5.83301 18.3337 5.41634V4.02745C18.3337 3.61079 18.0559 3.33301 17.6392 3.33301Z' fill='#282829' fillOpacity='0.8' />
                        <path d='M17.6392 14.4441H2.36144C1.94477 14.4441 1.66699 14.7219 1.66699 15.1386V16.5275C1.66699 16.9441 1.94477 17.2219 2.36144 17.2219H17.6392C18.0559 17.2219 18.3337 16.9441 18.3337 16.5275V15.1386C18.3337 14.7219 18.0559 14.4441 17.6392 14.4441Z' fill='#282829' fillOpacity='0.8' />
                    </g>
                    <defs>
                        <clipPath id='clip0_12284_65280'>
                            <rect width={20} height={20} rx={4} fill='white' />
                        </clipPath>
                    </defs>
                </svg>
            </button>
            {/* 
            <div className='search-container'>
                <SearchComponent />
            </div> */}

            <div className='' style={{ flex: 1 }}></div>

            {/* <button type="button" className='change-language-button box32' onClick={() => { }}>
                <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.3337 20.667C29.3337 23.2443 27.9282 25.3337 25.167 25.3337H6.83366C4.07241 25.3337 2.66699 23.2443 2.66699 20.667V11.3337C2.66699 8.75639 4.07241 6.66699 6.83366 6.66699H25.167C27.9282 6.66699 29.3337 8.75639 29.3337 11.3337V20.667Z" fill="#E60012" />
                    <path d="M21.5804 14.8751L17.4149 14.8814L16.122 10.6672L14.8387 14.8814L10.6679 14.8751L14.0454 17.4405L12.737 21.628L16.1245 19.0276L19.5191 21.628L18.2079 17.4405L21.5804 14.8751Z" fill="#F9CB38" />
                </svg>
            </button>

            <button type="button" className='show-calender-button box24' onClick={() => { }}>
                <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_12284_65325)">
                        <path d="M6.16667 11.1667C5.70643 11.1667 5.33333 11.5398 5.33333 12V12.8333C5.33333 13.2936 5.70643 13.6667 6.16667 13.6667H7.83333C8.29357 13.6667 8.66667 13.2936 8.66667 12.8333V12C8.66667 11.5398 8.29357 11.1667 7.83333 11.1667H6.16667Z" fill="#282829" fillOpacity="0.8" style={{ mixBlendMode: 'multiply' }} />
                        <path d="M11.1667 11.1667C10.7064 11.1667 10.3333 11.5398 10.3333 12V12.8333C10.3333 13.2936 10.7064 13.6667 11.1667 13.6667H12.8333C13.2936 13.6667 13.6667 13.2936 13.6667 12.8333V12C13.6667 11.5398 13.2936 11.1667 12.8333 11.1667H11.1667Z" fill="#282829" fillOpacity="0.8" style={{ mixBlendMode: 'multiply' }} />
                        <path d="M5.33333 16.1667C5.33333 15.7064 5.70643 15.3333 6.16667 15.3333H7.83333C8.29357 15.3333 8.66667 15.7064 8.66667 16.1667V17C8.66667 17.4602 8.29357 17.8333 7.83333 17.8333H6.16667C5.70643 17.8333 5.33333 17.4602 5.33333 17V16.1667Z" fill="#282829" fillOpacity="0.8" style={{ mixBlendMode: 'multiply' }} />
                        <path d="M11.1667 15.3333C10.7064 15.3333 10.3333 15.7064 10.3333 16.1667V17C10.3333 17.4602 10.7064 17.8333 11.1667 17.8333H12.8333C13.2936 17.8333 13.6667 17.4602 13.6667 17V16.1667C13.6667 15.7064 13.2936 15.3333 12.8333 15.3333H11.1667Z" fill="#282829" fillOpacity="0.8" style={{ mixBlendMode: 'multiply' }} />
                        <path d="M15.3333 12C15.3333 11.5398 15.7064 11.1667 16.1667 11.1667H17.8333C18.2936 11.1667 18.6667 11.5398 18.6667 12V12.8333C18.6667 13.2936 18.2936 13.6667 17.8333 13.6667H16.1667C15.7064 13.6667 15.3333 13.2936 15.3333 12.8333V12Z" fill="#282829" fillOpacity="0.8" style={{ mixBlendMode: 'multiply' }} />
                        <path fillRule="evenodd" clipRule="evenodd" d="M7.83333 2.83333C7.83333 2.3731 7.46024 2 7 2C6.53976 2 6.16667 2.3731 6.16667 2.83333V3.66667H4.5C3.11929 3.66667 2 4.78595 2 6.16667V18.6667C2 20.0474 3.11929 21.1667 4.5 21.1667H19.5C20.8807 21.1667 22 20.0474 22 18.6667V6.16667C22 4.78595 20.8807 3.66667 19.5 3.66667H17.8333V2.83333C17.8333 2.3731 17.4602 2 17 2C16.5398 2 16.1667 2.3731 16.1667 2.83333V3.66667H7.83333V2.83333ZM4.5 5.33333C4.03976 5.33333 3.66667 5.70643 3.66667 6.16667V7.83333H20.3333V6.16667C20.3333 5.70643 19.9602 5.33333 19.5 5.33333H17.8333C17.8333 5.79357 17.4602 6.16667 17 6.16667C16.5398 6.16667 16.1667 5.79357 16.1667 5.33333H7.83333C7.83333 5.79357 7.46024 6.16667 7 6.16667C6.53976 6.16667 6.16667 5.79357 6.16667 5.33333H4.5ZM4.5 19.5C4.03976 19.5 3.66667 19.1269 3.66667 18.6667V9.5H20.3333V18.6667C20.3333 19.1269 19.9602 19.5 19.5 19.5H4.5Z" fill="#282829" fillOpacity="0.8" style={{ mixBlendMode: 'multiply' }} />
                    </g>
                    <defs>
                        <clipPath id="clip0_12284_65325">
                            <rect width={24} height={24} rx={4} fill="white" />
                        </clipPath>
                    </defs>
                </svg>
            </button> */}

            {/* <div className='show-noti-container ' ref={notiContainerRef}>
                <button type='button' className='show-noti-button'
                    onClick={() => {
                        setShowNotiContainer(true)
                    }}
                >
                    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' >
                        <path fillRule='evenodd' clipRule='evenodd' d='M7.45318 3.95287C8.70358 2.70247 10.3995 2 12.1678 2C13.9361 2 15.6321 2.70247 16.8825 3.95287C18.1328 5.20327 18.8353 6.89917 18.8353 8.6675V11.1678C18.8353 12.239 19.2996 12.8999 19.877 13.5543C19.9437 13.6299 20.0171 13.7107 20.0938 13.7951C20.326 14.0508 20.5886 14.3399 20.7897 14.6214C21.079 15.0263 21.3356 15.5335 21.3356 16.1684C21.3356 16.8754 20.9239 17.4252 20.4287 17.813C19.9333 18.201 19.2617 18.5067 18.4878 18.7461C16.9316 19.2276 14.7572 19.5022 12.1678 19.5022C9.5784 19.5022 7.40408 19.2276 5.84783 18.7461C5.07395 18.5067 4.40231 18.201 3.90692 17.813C3.41168 17.4252 3 16.8754 3 16.1684C3 15.5335 3.25665 15.0263 3.54592 14.6214C3.74701 14.3399 4.00959 14.0508 4.2418 13.7951C4.3185 13.7107 4.39189 13.6299 4.45865 13.5543C5.03601 12.8999 5.50031 12.239 5.50031 11.1678V8.6675C5.50031 6.89917 6.20278 5.20327 7.45318 3.95287ZM12.1678 3.66688C10.8416 3.66688 9.56964 4.19373 8.63184 5.13153C7.69404 6.06932 7.16719 7.34125 7.16719 8.6675V11.1678C7.16719 12.847 6.38133 13.8946 5.70854 14.6571C5.60029 14.7798 5.50196 14.8881 5.41187 14.9873C5.20553 15.2145 5.04243 15.3941 4.90231 15.5902C4.72276 15.8416 4.66688 16.0116 4.66688 16.1684C4.6668 16.1723 4.66434 16.289 4.93459 16.5007C5.20752 16.7144 5.66623 16.9451 6.34045 17.1537C7.68039 17.5682 9.67326 17.8353 12.1678 17.8353C14.6624 17.8353 16.6552 17.5682 17.9952 17.1537C18.6694 16.9451 19.1281 16.7144 19.401 16.5007C19.6713 16.289 19.6688 16.1723 19.6688 16.1685C19.6688 16.0117 19.6129 15.8416 19.4333 15.5902C19.2932 15.3941 19.1301 15.2145 18.9238 14.9873C18.8337 14.8881 18.7353 14.7798 18.6271 14.6571C17.9543 13.8946 17.1684 12.847 17.1684 11.1678V8.6675C17.1684 7.34125 16.6416 6.06932 15.7038 5.13153C14.766 4.19373 13.4941 3.66688 12.1678 3.66688Z' fill='#282829' fillOpacity='0.8' />
                        <path d='M12.1678 20.3357C11.3252 20.3357 10.5335 20.3073 9.78837 20.2523C9.94722 20.759 10.2637 21.2018 10.6917 21.5161C11.1196 21.8305 11.6368 22 12.1678 22C12.6989 22 13.216 21.8305 13.644 21.5161C14.072 21.2018 14.3884 20.759 14.5473 20.2523C13.8022 20.3073 13.0104 20.3357 12.1678 20.3357Z' fill='#282829' fillOpacity='0.8' />
                    </svg>
                </button>
                {showNofiContainer && (
                    <div className='noti-container'>
                        <div className='noti-card row'>
                            <div className='box40 center'>
                                <FontAwesomeIcon size='2x' icon={faUserCircle} />
                            </div>
                            <div className='space col'>
                                <div className='title regular2'>
                                    Sáng mai nó còn đòi ngủ cùng
                                </div>
                                <div className='time regular11'>04/01/2024 02:02 AM</div>
                            </div>
                        </div>
                        <div className='noti-card row'>
                            <div className='box40 center'>
                                <FontAwesomeIcon size='2x' icon={faUserCircle} />
                            </div>
                            <div className='space col'>
                                <div className='title regular2'>
                                    Bạn có một con Ngân đù giục làm việc lúc 2 giờ sáng
                                </div>
                                <div className='time regular11'>04/01/2024 02:00 AM</div>
                            </div>
                        </div>
                    </div>
                )}
            </div> */}

            <button type='button' className='user-button row' onClick={() => { }}>
                <div className='box32'>
                    <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M5.62346 19.7036C5.63199 19.7109 5.64067 19.7181 5.6495 19.7251C7.37657 21.1465 9.58861 22 12 22C14.4095 22 16.6199 21.1479 18.3464 19.7285L18.3507 19.7251C18.3608 19.717 18.3707 19.7088 18.3805 19.7003C20.5917 17.8661 22 15.0975 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 15.0993 3.40997 17.8693 5.62346 19.7036ZM3.66667 12C3.66667 7.39763 7.39763 3.66667 12 3.66667C16.6024 3.66667 20.3333 7.39763 20.3333 12C20.3333 14.0013 19.6279 15.8378 18.4521 17.2744C18.1824 16.5599 17.6706 15.9473 16.9866 15.5565L15.2745 14.5768C15.8333 13.8677 16.1667 12.9728 16.1667 12V10.3333C16.1667 8.03226 14.3011 6.16667 12 6.16667C9.69893 6.16667 7.83333 8.03226 7.83333 10.3333V12C7.83333 12.972 8.1662 13.8663 8.72416 14.5751L7.01268 15.5567C6.32875 15.9475 5.8173 16.5599 5.54778 17.2742C4.37206 15.8376 3.66667 14.0012 3.66667 12ZM17 18.6673C15.6072 19.7134 13.876 20.3333 12 20.3333C10.124 20.3333 8.39277 19.7134 7 18.6673V18.4508C7 17.8523 7.32055 17.3002 7.83995 17.0036L10.0982 15.7083C10.6684 16.0013 11.3149 16.1667 12 16.1667C12.6839 16.1667 13.3294 16.0019 13.8988 15.7098L16.16 17.0037C16.6794 17.3004 17 17.8525 17 18.4509V18.6673ZM12 7.83333C10.6194 7.83333 9.5 8.95274 9.5 10.3333V12C9.5 12.8511 9.92547 13.603 10.5753 14.0545L10.5852 14.0614C10.9875 14.338 11.4749 14.5 12 14.5C12.518 14.5 12.9992 14.3424 13.3983 14.0726C13.4108 14.0635 13.4235 14.0547 13.4364 14.0464C14.0796 13.594 14.5 12.846 14.5 12V10.3333C14.5 8.95274 13.3806 7.83333 12 7.83333Z" fill="#00204D" fillOpacity="0.6" />
                    </svg>
                </div>
                <div className='user-name'>
                    {JSON.parse(localStorage.getItem('customer'))?.name ?? 'Admin'}
                </div>
            </button>

            <button
                className='text-button box24'
                onClick={() => {
                    localStorage.clear()
                    window.location.href = '/'
                }}
            >
                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M2.86957 2C2.38932 2 2 2.38932 2 2.86957V15.913C2 16.1939 2.13562 16.4574 2.36414 16.6206L8.4511 20.9685C8.71615 21.1578 9.06479 21.1831 9.35442 21.0341C9.64404 20.885 9.82609 20.5866 9.82609 20.2609V16.7826H13.3043C13.7846 16.7826 14.1739 16.3933 14.1739 15.913V12.4348C14.1739 11.9545 13.7846 11.5652 13.3043 11.5652C12.8241 11.5652 12.4348 11.9545 12.4348 12.4348V15.0435H9.82609V7.2313C9.82609 6.95094 9.69091 6.68778 9.46302 6.52447L5.57597 3.73913H12.4348V7.21739C12.4348 7.69764 12.8241 8.08696 13.3043 8.08696C13.7846 8.08696 14.1739 7.69764 14.1739 7.21739V2.86957C14.1739 2.38932 13.7846 2 13.3043 2H2.86957ZM3.73913 15.4655L8.08696 18.5711V7.67797L3.73913 4.56244V15.4655Z" fill="#00204D" fillOpacity="0.6" />
                    <path d="M18.267 5.73295C17.9275 5.39336 17.3769 5.39336 17.0373 5.73295C16.6977 6.07254 16.6977 6.62311 17.0373 6.9627L19.0311 8.95652H11.5652C11.085 8.95652 10.6957 9.34584 10.6957 9.82609C10.6957 10.3063 11.085 10.6957 11.5652 10.6957H19.0311L17.0373 12.6895C16.6977 13.0291 16.6977 13.5796 17.0373 13.9192C17.3769 14.2588 17.9275 14.2588 18.267 13.9192L21.7447 10.4416L21.75 10.4362C21.9167 10.267 22 10.0465 22 9.82609C22 9.60355 21.9151 9.381 21.7453 9.21121L18.267 5.73295Z" fill="#00204D" fillOpacity="0.6"/>
                </svg>

            </button>
        </div>
    )
}
