/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from "react-hook-form";
import { NavLink, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from 'react';
import { ReusableInput, ReusableSelect1 } from "../../component/TextField/text-field";
import ConfigAPI from "../../config/configApi";
import { ControllerDA } from "../../core/controller";
import { ActionType } from "../../core/ActionType";
import { postData } from "../../core/BaseDA";
import notiUtil from "../../core/notiUtil";
import { Dialog, DialogStatus, showDialog } from "../../component/dialog/dialog";
import { ADD, EDIT } from "../../assets/const/select-list";

export default function NguoiDungMobileNoiBoForm({ controller }) {
    const { register, control, setValue, handleSubmit, getValues, watch, setError, formState: { errors } } = useForm({ shouldFocusError: false, defaultValues: { type: 4 } });
    // const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [showPass, setShowPass] = useState(false);

    const fileInputRef = useRef();
    const { id } = useParams();
    const dialogRef = useRef()

    const handleImageChange = async (e) => {
        if (e.target.files && e.target.files[0]) {
            let img = e.target.files[0];
            setPreview(URL.createObjectURL(img));
            const resFile = await ControllerDA.uploadFile({ file: img });
            if (resFile) {
                setValue("urlAvatar", resFile.data.id)
            } else {

            }
        }
    };

    const onSubmit = async () => {
        showDialog({
            ref: dialogRef,
            status: DialogStatus.INFOR,
            title: `Xác nhận ${id ? 'chỉnh sửa' : 'tạo'} tài khoản nội bộ?`,
            submit: () => {
                let data = getValues()
                submitAction(data)
            }
        })

    }


    const submitAction = event => {
        event.Email = "";
        if (id) {
            event.id = id;
            event.Action = ActionType.Edit.value;
        } else {
            event.Action = ActionType.Add.value;
        }
        ControllerDA.editAction(ConfigAPI.baseUrl, controller, event).then(res => {
            if (res.code === 200) {
                notiUtil.success(`${id ? 'Chỉnh sửa' : 'Tạo mới'} tài khoản nội bộ thành công`)
                window.history.back()
            } else {
                notiUtil.success(res.message)
            }
        })
    }
    const onError = () => { }

    useEffect(() => {
        if (id) {
            if (!localStorage.getItem('listActive')?.includes(EDIT)) notiUtil.errors('Tài khoản không có quyền chỉnh sửa')
            ControllerDA.getDetail(ConfigAPI.baseUrl, controller, { id: id, Action: ActionType.GetById.value }).then(res => {
                if (res) {
                    Object.keys(res.data).forEach(fieldName => {
                        if (fieldName === 'referralCode') setValue('initReferralCode', res.data[fieldName])
                        setValue(fieldName, res.data[fieldName]);
                    });
                }
            })
        }
    }, [])

    const editable = (id && localStorage.getItem('listActive')?.includes(EDIT)) || (!id && localStorage.getItem('listActive')?.includes(ADD))
    return (
        <form className={`admin-add-view mainView col ${editable ? '' : 'disabled'}`} onSubmit={handleSubmit(onSubmit, onError)}>
            <Dialog ref={dialogRef} />
            <div className='view-header col'>
                <div className='view-name heading-6'>{id ? 'Chỉnh sửa' : 'Thêm mới'} người dùng mobile app</div>
                <div className='breadcrums row'>
                    <NavLink to={'/admin/quan-ly-nguoi-dung-mobile-app'} className='nav-link regular1' type='button'>Quản lý người dùng mobile app</NavLink>
                    <FontAwesomeIcon className='icon' size='2xs' icon={faChevronRight} />
                    <NavLink className='nav-link regular1' type='button'>{id ? 'Chỉnh sửa' : 'Thêm mới'} tài khoản nội bộ</NavLink>
                </div>
            </div>
            <div className="view-content-container row">
                <div className="view-content col">
                    <div className='scroll-view-container col'>
                        <div className="inputForm view-form row-3 col" >
                            <div className='header row'>
                                <div className='label flex1 heading-7'>Thông tin chung</div>
                            </div>
                            <div className='left-item row' style={{ gap: 16 }}>
                                <input ref={fileInputRef} type="file" onChange={handleImageChange} style={{ display: "none" }} />
                                <img className='image' src={preview || `${ConfigAPI.fileUrl}api/UploadFile/img/${watch("urlAvatar")}`} alt="" />

                                <button type="button" className='upload-image row' onClick={() => {
                                    fileInputRef.current.click();
                                }}>
                                    <svg width={14} height={13} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.66732 3.88889L7.00065 0L10.334 3.88889H7.55621V8.88889H6.4451V3.88889H3.66732Z" fill="#262626" />
                                        <path d="M12.5562 11.1111H1.4451V7.77778H0.333984V11.6667C0.333984 11.9733 0.582873 12.2222 0.88954 12.2222H13.1118C13.4184 12.2222 13.6673 11.9733 13.6673 11.6667V7.77778H12.5562V11.1111Z" fill="#262626" />
                                    </svg>
                                    <span className='button-text-3'>Tải ảnh lên</span>
                                </button>
                            </div>

                            <div className='list-input-container row'>
                                <div className="form-card">
                                    <ReusableInput
                                        register={register}
                                        errors={errors}
                                        name={"name"}
                                        label="Họ và tên"
                                    />
                                </div>
                                <div className="form-card">
                                    <ReusableInput
                                        maxLength={12}
                                        register={register}
                                        required={false}
                                        errors={errors}
                                        name={"referralCode"}
                                        label="Mã nhân viên"
                                        onBlur={(ev) => {
                                            const newReferCode = ev.target.value.trim()
                                            if (getValues('initReferralCode') !== newReferCode && newReferCode?.length) {
                                                postData(ConfigAPI.customerUrl + `Customer/CheckRegister?userName='dsfsfvdrgrg@r'&mobile=0&rereferralCode=${newReferCode}`, { obj: { Action: 'CheckRegister' } }).then(res => {
                                                    if (res.data === 3 && res.code !== 404) {
                                                        setError('referralCode', 'Mã nhân viên đã tồn tại')
                                                        notiUtil.errors('Mã nhân viên đã tồn tại')
                                                    }
                                                })
                                            }
                                        }}
                                    />
                                </div>
                                <div className="form-card">
                                    <ReusableInput
                                        register={register}
                                        required={false}
                                        errors={errors}
                                        name={"mobile"}
                                        label="Số điện thoại"
                                    />
                                </div>

                                <div className="form-card">
                                    <ReusableSelect1
                                        value={watch("type")}
                                        label='Chức danh'
                                        name='type'
                                        control={control}
                                        required={false}
                                        errors={errors}
                                        options={[{ name: "Cán bộ tuyển dụng", id: 3 }, { name: "Cộng tác viên", id: 4 }]}
                                    />
                                </div>
                                <div className="form-card">
                                    <ReusableInput
                                        register={register}
                                        required={false}
                                        errors={errors}
                                        name={"referralCodeIntroduced"}
                                        label="Mã giới thiệu"
                                    />
                                </div>
                                <div className="form-card">
                                    <ReusableInput
                                        register={register}
                                        errors={errors}
                                        name={"userName"}
                                        label="Tên tài khoản"
                                        disabled={id != null}
                                        onBlur={(ev) => {
                                            postData(ConfigAPI.customerUrl + `Customer/CheckRegister?userName=${ev.target.value}&mobile=0&rereferralCode=0`, { obj: { Action: 'CheckRegister' } }).then(res => {
                                                if (res.code !== 200 && res.data === 1) {
                                                    notiUtil.errors(res.message)
                                                    setError('userName', res.message)
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <div className="form-card">
                                    <ReusableInput
                                        register={register}
                                        errors={errors}
                                        name={"password"}
                                        label="Mật khẩu"
                                        type={showPass ? 'text' : 'password'}
                                        sufix={
                                            <div className='box32 center' onClick={() => setShowPass(!showPass)} >
                                                <FontAwesomeIcon size='xs' icon={showPass ? faEyeSlash : faEye} />
                                            </div>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='view-footer row'>
                <button type="button" className='close-button' onClick={() => {
                    if (editable) {
                        showDialog({
                            ref: dialogRef,
                            status: DialogStatus.WARNING,
                            title: `Xác nhận thoát ${id ? 'chỉnh sửa' : 'tạo'} tài khoản nội bộ?`,
                            content: 'Thông tin tài khoản nội bộ đã nhập sẽ không được lưu lại',
                            submit: () => {
                                window.history.back()
                            }   
                        })
                    } else {
                        window.history.back()
                    }
                }}><span className='button-text-3'>Thoát</span></button>
                <div className='space'></div>

                <button type='submit' className='submit-button'><span className='button-text-3'>Gửi</span></button>
            </div>
        </form >
    )
}