import ConfigAPI from '../config/configApi'
import { ActionType } from './ActionType'
import { getData, postData, postFormDataFile, uploadFile } from './BaseDA'

export class ControllerDA {
    // static async init({ url , data}) {
    //     const response = await postData(url,{obj: data});
    //     return response;
    // }
    // static async add({ url, data }) {
    //     const response = await postData(url, { obj: data });
    //     return response;
    // }
    // static async edit({ url, data }) {
    //     const response = await postData(url, { obj: data });
    //     return response;
    // }
    // static async delete({ url, data }) {
    //     const response = await postData(url, { obj: data });
    //     return response;
    // }
    static async filterAction(domainURL, controller, data) {
        let query = ''
        const filters = []
        data.filter.forEach(e => {
            if (typeof e.value !== 'string') {
                query += query.length ? `&${e.field}=${e.value}` : `?${e.field}=${e.value}`
            } else {
                filters.push(e)
            }
        })
        data.filter = filters
        var url = domainURL + controller + '/' + data.Action + query
        const response = await postData(url, { obj: data })
        return response
    }

    static async doAction(domainURL, controller, data, type, status) {
        var url =
            domainURL +
            controller +
            '/' +
            data.Action +
            `${type != null ? `?type=${type}` : ''}` +
            `${status ? (type != null ? `&status=${status}` : `?status=${status}`) : ''}`
        const response = await postData(url, { obj: data })
        return response
    }
    static async getDetail(domainURL, controller, data) {
        var url = domainURL + controller + '/' + data.Action + `?id=${data.id}`
        const response = await getData(url)
        return response
    }
    static async actionGet(domainURL, controller, data) {
        var url = domainURL + controller + '/' + data.Action
        const response = await getData(url)
        return response
    }
    static async editAction(domainURL, controller, data) {
        var url = domainURL + controller + '/' + data.Action + `?id=${data.id}`
        const response = await postData(url, { obj: data })
        return response
    }
    static async getPinProduct() {
        var url = ConfigAPI.productUrl + `Product/GetPined?quantity=2`
        const response = await postData(url, { obj: {} })
        return response
    }
    // static async delectAction(domainURL, controller, data) {
    //     var url = domainURL + controller + '/' + data.Action + `?id=${data.id}`;
    //     const response = await postData(url, { obj: data });
    //     return response;
    // }

    static async PostListFile(domainURL, controller, data, action) {
        var url = domainURL + controller + '/' + action
        const response = await postFormDataFile(url, { obj: data })
        return response
    }

    static async uploadFile({ file }) {
        const url = ConfigAPI.fileUrl + 'api/UploadFile/upload'
        let formData = new FormData()
        formData.append('File', file)
        const res = await uploadFile(url, { data: formData })
        if (res) {
            return res
        } else {
            return null
        }
    }

    static async download({ fileId }) {
        const url = ConfigAPI.fileUrl + 'api/UploadFile/download?id=' + fileId
        const res = await getData(url)
        if (res) {
            return res
        } else {
            return null
        }
    }

    static pushNoti = async data => {
        const url = 'https://fcm.googleapis.com/fcm/send'
        const headersIos = {
            Authorization:
                'key=AAAAMNsbuAM:APA91bHjSOQTSVIZPbhYVxfwnGfBIINxCXDN3_pwDSN7O0PQ-GyC8inyj1wVyIuYOXB_Z-kS8OlHWVojG6LR6uJp7S5w2QCD1w3dfbPswEiuXsWjbnvJHZpzI45gqdii-ZwsnTiinTMT',
            'Content-Type': 'application/json'
        }
        const headersAndroid = {
            Authorization:
                'key=AAAAGjsI0fg:APA91bFuBG70ZdSBAgdSExh2fZgXAAEyKlcgn7u23xeL6Tmp16IyL1rUMTUAuJR79E-LH7CeQzgiWb6nUsN5Sd4dW9c41X-VTQgGNdWNFp6BB7zkfD1JryQ38YdTY35w6c-W4JNCHyTo',
            'Content-Type': 'application/json'
        }

        const resIos = postData(url, { headers: headersIos, obj: data })
        const resAndroid = await postData(url, { headers: headersAndroid, obj: data })
        return resAndroid

        // fetch(url, {
        //     method: 'POST',
        //     headers: headers,
        //     body: JSON.stringify(data)
        // })
        //     .then(response => response.json())
        //     .then(data => {
        //         console.log('Success:', data);
        //         return data;
        //     })
        //     .catch((error) => {
        //         console.error('Error:', error);
        //         return error
        //     });
    }
}
