import axios from "axios";
import ConfigAPI from "../config/configApi";

const ERROR_AUTHEN = "401";
const NO_ROLE = "403";

export const uploadFile = async (url, { data }) => {
    try {
        const response = await axios({
            method: 'POST',
            url: url,
            data: data,
            responseType: "json",
            headers: ConfigAPI.file_headers(),
        });

        if (response.status === 200) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = "/login";
            }
            if (response.data.code === NO_ROLE) {
                alert(response.data?.message);
            }
            return response.data;
        }
    } catch (error) {
        console.error("Failed to upload file:", error);
        throw error;
    }
};

export const postData = async (url, { headers, obj }) => {
    try {
        ;
        const response = await axios({
            method: "POST",
            url: url,
            data: obj ?? {},
            headers: headers || ConfigAPI.base_headers(),
        });

        if (response.status === 200) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = "/login";
            }
            if (response.data.code === NO_ROLE) {
                alert(response.data?.message);
            }
            return response.data;
        }
    } catch (error) {
        console.error("Failed to POST data:", error);
        throw error;
    }
};
// export const postDataHasParams = async (url, payloadAndParams) => {
//     try {
//         const { payload, params } = payloadAndParams;
//         const response = await axios({
//             method: "POST",
//             url: url,
//             data: payload ?? {},
//             params: params || {},
//             headers: base_headers(),
//         });
//         if (response.status === 200) {
//             if (response.data.code === ERROR_AUTHEN) {
//                 window.location = "/login";
//             }
//             return response.data;
//         }
//     } catch (error) {
//         console.error("Failed to POST data:", error);
//         throw error;
//     }
// };
// export const postDataFile = async (url, { obj }) => {
//     try {
//         const response = await axios({
//             method: "POST",
//             url: url,
//             data: obj ?? {},
//             // responseType: "json",
//             responseType: "blob",
//             headers: base_headers(),
//         });
//         if (response.status === 200) {
//             if (response.data.code === ERROR_AUTHEN) {
//                 window.location = "/login";
//             }

//             return response.data;
//         }
//     } catch (error) {
//         console.error("Failed to POST data:", error);
//         throw error;
//     }
// };
export const postFormDataFile = async (url, { obj }) => {
    try {

        const response = await axios({
            method: "POST",
            url: url,
            data: obj,
            // responseType: "blob",
            headers: ConfigAPI.base_headers("multipart/form-data"),
        });
        if (response.status === 200) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = "/login";
            }

            return response.data;
        }
        if (response.status === 201) {
            return response;
        }
    } catch (error) {
        console.error("Failed to POST data:", error);
        throw error;
    }
};
// export const postFormDatareturnFile = async (url, { obj }) => {
//     try {
//         
//         const response = await axios({
//             method: "POST",
//             url: url,
//             data: obj,
//             responseType: "blob",
//             headers: file_headers(),
//         });
//         if (response.status === 200) {
//             if (response.data.code === ERROR_AUTHEN) {
//                 window.location = "/login";
//             }

//             return response.data;
//         }
//         if (response.status === 201) {
//             return response;
//         }
//     } catch (error) {
//         console.error("Failed to POST data:", error);
//         throw error;
//     }
// };
// export const deleteFormDataFile = async (url, { obj }) => {
//     try {
//         const response = await axios({
//             method: "DELETE",
//             url: url,
//             data: obj,
//             // responseType: "blob",
//             headers: base_headers("multipart/form-data"),
//         });
//         if (response.status === 200) {
//             if (response.data.code === ERROR_AUTHEN) {
//                 window.location = "/login";
//             }

//             return response.data;
//         }
//     } catch (error) {
//         console.error("Failed to POST data:", error);
//         throw error;
//     }
// };
// export const putFormDataFile = async (url, { obj }) => {
//     try {
//         const response = await axios({
//             method: "PUT",
//             url: url,
//             data: obj,
//             // responseType: "blob",
//             headers: base_headers("multipart/form-data"),
//         });
//         if (response.status === 200) {
//             if (response.data.code === ERROR_AUTHEN) {
//                 window.location = "/login";
//             }

//             return response.data;
//         }
//     } catch (error) {
//         console.error("Failed to POST data:", error);
//         throw error;
//     }
// };
// export const putData = async (url, { obj }) => {
//     try {
//         const response = await axios({
//             method: "put",
//             url: url,
//             data: obj,
//             // responseType: "json",
//             headers: base_headers(),
//         });
//         if (response.status === 401) {
//             window.location = "/login";
//         }
//         if (response.status === 200 || response.status === 201) {
//             if (response.data.code === ERROR_AUTHEN) {
//                 window.location = "/login";
//             }
//             if (response.data.code === NO_ROLE) {
//                 alert(response.data?.message);
//             }

//             return response.data;
//         }
//     } catch (error) {
//         console.error("Failed to POST data:", error);
//         throw error;
//     }
// };
// export const putHoSoData = async (url, { obj }) => {
//     try {
//         const response = await axios({
//             method: "put",
//             url: url,
//             data: obj,
//             // responseType: "json",
//             headers: base_headers(),
//         });
//         if (response.status === 401) {
//             window.location = "/login";
//         }
//         if (response.status === 200 || response.status === 201) {
//             if (response.data.code === ERROR_AUTHEN) {
//                 window.location = "/login";
//             }
//             return response;
//         }
//     } catch (error) {
//         console.error("Failed to POST data:", error);
//         throw error;
//     }
// };
// export const deleteData = async (url, { obj }) => {
//     // var headers = { 'Content-type': 'application/json' };
//     try {
//         const response = await axios({
//             method: "DELETE",
//             url: url,
//             data: obj,
//             // responseType: "json",
//             headers: base_headers(),
//         });
//         if (response.status === 200) {
//             if (response.data.code === ERROR_AUTHEN) {
//                 window.location = "/login";
//             }
//             if (response.data.code === NO_ROLE) {
//                 alert(response.data?.message);
//             }
//             return response.data;
//         }
//     } catch (error) {
//         console.error("Failed to DELETE data:", error);
//         throw error;
//     }
// };
export const getData = async (url) => {
    // var headers = { 'Content-type': 'application/json' };
    try {
        const response = await axios({
            method: "GET",
            url: url,
            headers: ConfigAPI.base_headers(),
        });
        if (response.status === ERROR_AUTHEN) {
            window.location = "/login";
        }

        if (response.status === 200) {
            if (response.data.code === ERROR_AUTHEN) {
                window.location = "/login";
            }
            if (response.data.code === NO_ROLE) {
                alert(response.data?.message);
            }
            return response.data;
        } else {
            console.error("Failed to fetch data:", response.status);
            return null;
        }
    } catch (error) {
        console.error("Failed to fetch data:", error);
        throw error;
    }
};
// export const getDataParams = async (url, params) => {
//     // var headers = { 'Content-type': 'application/json' };
//     try {
//         const response = await axios({
//             method: "GET",
//             url: url,
//             params: params || {},
//             headers: base_headers(),
//         });
//         if (response.status === ERROR_AUTHEN) {
//             window.location = "/login";
//         }

//         if (response.status === 200) {
//             if (response.data.code === ERROR_AUTHEN) {
//                 window.location = "/login";
//             }
//             if (response.data.code === NO_ROLE) {
//                 alert(response.data?.message);
//             }
//             return response.data;
//         } else {
//             console.error("Failed to fetch data:", response.status);
//             return null;
//         }
//     } catch (error) {
//         console.error("Failed to fetch data:", error);
//         throw error;
//     }
// };
// export const getDataFile = async (url, responseType) => {
//     try {
//         const response = await axios({
//             method: "GET",
//             url: url,
//             headers: base_headers(),
//             responseType: responseType ?? "blob",
//         });

//         if (response.status === ERROR_AUTHEN) {
//             window.location = "/login";
//         }

//         if (response.status === 201) {
//             return response;
//         } else if (response.status === 200) {
//             return response;
//         } else {
//             console.error("Failed to fetch data:", response.status);
//             return null;
//         }
//     } catch (error) {
//         console.error("Failed to fetch data:", error);
//         throw error;
//     }
// };
