import { NavLink } from "react-router-dom";
import { ActionType } from "../../core/ActionType";
import GetAction from "../../core/Action";
import { useEffect, useRef, useState } from "react";
import { Ultis } from "../../core/Utils";
import { ADD, DELETE, EDIT, GET, listProductType, listStatusExam } from "../../assets/const/select-list";
import SwitchComponent from "../../component/switch/switch";
import { ControllerDA } from "../../core/controller";
import { Dialog, DialogStatus, showDialog } from "../../component/dialog/dialog";

export default function ListView({ data, controller, callback_action }) {
    const [pinned, setPinned] = useState([]);
    const dialogRef = useRef();

    const _InitData = async () => {
        const res = await ControllerDA.getPinProduct();
        if (res.code === 200) {
            setPinned(res.data);
        }
    }

    const handleUpdateCustomer = async ({ data }) => {
        data.Action = "Pin";
        callback_action(data);
    }

    useEffect(() => {
        if (localStorage.getItem('listActive')?.includes(GET))
            _InitData();
    }, [data])

    return (
        <>
            <Dialog ref={dialogRef} />

            <div className='listView-header row'>
                <div className='heading-7 space'>Danh sách kết quả</div>
                {localStorage.getItem('listActive')?.includes(ADD) ? <GetAction controller={controller} action={ActionType.Add.value} /> : null}
            </div>
            <div className='listView-body col'>
                <table className='custom-table'>
                    <thead>
                        <tr>
                            <th style={{ minWidth: 75, }} className='fixed-first-column'><div className="center">STT</div></th>
                            <th style={{ minWidth: 360, }} className=''>Tên đơn hàng</th>
                            <th style={{ minWidth: 150, }} className=''>Mã đơn hàng</th>
                            <th style={{ minWidth: 150, }} className=''>Trạng thái</th>
                            <th style={{ minWidth: 172, }} className=''>Loại hình đơn hàng</th>
                            <th style={{ minWidth: 172, }} className='' >Ngày tạo</th>
                            <th style={{ minWidth: 172, }} className='' >Ngày hết hạn</th>
                            {localStorage.getItem('listActive')?.includes(EDIT) ? <th style={{ minWidth: 110, }} className='' >Ghim đơn hàng</th> : null}
                            {localStorage.getItem('listActive')?.includes(DELETE) ? <th style={{ minWidth: 110, }} className='fixed-last-column' >Action</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            // Array.from({ length: 10 }, (_, index) => {
                            //     return (
                            data && [...data.filter(e => pinned.some(p => p.id === e.id)), ...data.filter(e => pinned.every(p => p.id !== e.id))].map((item, index) => (
                                <tr key={index}>
                                    <td style={{ minWidth: 75, }} className='fixed-first-column'><div className="center">{index + 1}</div></td>
                                    <td style={{ minWidth: 360, }} className=''>
                                        <NavLink to={ActionType.Edit.value + `/${item.id}`} className={'nav-link'}>{item.name ?? "-"}</NavLink>
                                    </td>
                                    <td style={{ minWidth: 150, }} className=''>{item.orderCode ?? "-"}</td>
                                    <td style={{ minWidth: 150, }} className=''>{listStatusExam.find((e) => e.id === item.status)?.name ?? "-"}</td>
                                    <td style={{ minWidth: 172, }} className=''>{listProductType.find((e) => e.id === item.type)?.name ?? "-"}</td>
                                    <td style={{ minWidth: 172, }} className='' >{item.dateCreated ? Ultis.formatDateTime(item.dateCreated, true) : "-"}</td>
                                    <td style={{ minWidth: 172, }} className='' >{item.dateEnd ? Ultis.formatDateTime(item.dateEnd, true) : "-"}</td>
                                    {localStorage.getItem('listActive')?.includes(EDIT) ? <td style={{ minWidth: 110,pointerEvents: pinned.some(e => e.id === item.id) ? 'none' : 'auto' }} className='' >
                                        <SwitchComponent
                                            value={pinned.some(e => e.id === item.id)}
                                            onchanged={(ev) => {
                                                handleUpdateCustomer({ data: item });
                                            }}
                                        />
                                    </td> : null}
                                    <td style={{ minWidth: 110, }} className='fixed-last-column'>
                                        <div className="row">
                                            {/*  to={'/admin/danh-sach-don-hang/danh-sach-ung-vien-trong-don-hang/' + item.id} */}
                                            <NavLink className="deleteButton box32 center" to={'/admin/quan-ly-nguoi-dung-trong-don-hang/' + item.id}>
                                                <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.6668 1.66666H4.02794C3.84376 1.66666 3.66713 1.73983 3.53689 1.87006C3.40666 2.0003 3.3335 2.17693 3.3335 2.36111V17.6389C3.3335 17.8231 3.40666 17.9997 3.53689 18.1299C3.66713 18.2602 3.84376 18.3333 4.02794 18.3333H16.5279C16.7121 18.3333 16.8888 18.2602 17.019 18.1299C17.1492 17.9997 17.2224 17.8231 17.2224 17.6389V7.22222H12.3613C12.1771 7.22222 12.0005 7.14906 11.8702 7.01882C11.74 6.88859 11.6668 6.71195 11.6668 6.52778V1.66666ZM5.76405 13.4722H14.7918C14.8839 13.4722 14.9722 13.5088 15.0374 13.5739C15.1025 13.639 15.1391 13.7274 15.1391 13.8194V14.5139C15.1391 14.606 15.1025 14.6943 15.0374 14.7594C14.9722 14.8245 14.8839 14.8611 14.7918 14.8611H5.76405C5.67196 14.8611 5.58365 14.8245 5.51853 14.7594C5.45341 14.6943 5.41683 14.606 5.41683 14.5139V13.8194C5.41683 13.7274 5.45341 13.639 5.51853 13.5739C5.58365 13.5088 5.67196 13.4722 5.76405 13.4722ZM5.76405 10H14.7918C14.8839 10 14.9722 10.0366 15.0374 10.1017C15.1025 10.1668 15.1391 10.2551 15.1391 10.3472V11.0417C15.1391 11.1338 15.1025 11.2221 15.0374 11.2872C14.9722 11.3523 14.8839 11.3889 14.7918 11.3889H5.76405C5.67196 11.3889 5.58365 11.3523 5.51853 11.2872C5.45341 11.2221 5.41683 11.1338 5.41683 11.0417V10.3472C5.41683 10.2551 5.45341 10.1668 5.51853 10.1017C5.58365 10.0366 5.67196 10 5.76405 10ZM9.23627 7.91666H5.76405C5.67196 7.91666 5.58365 7.88008 5.51853 7.81497C5.45341 7.74985 5.41683 7.66153 5.41683 7.56944V6.875C5.41683 6.78291 5.45341 6.69459 5.51853 6.62947C5.58365 6.56436 5.67196 6.52778 5.76405 6.52778H9.23627C9.32836 6.52778 9.41668 6.56436 9.4818 6.62947C9.54691 6.69459 9.5835 6.78291 9.5835 6.875V7.56944C9.5835 7.66153 9.54691 7.74985 9.4818 7.81497C9.41668 7.88008 9.32836 7.91666 9.23627 7.91666Z" fill="green" fillOpacity="0.6" style={{ mixBlendMode: 'multiply' }} />
                                                    <path d="M16.8154 5.83333H13.0557V2.07361L16.8154 5.83333Z" fill="green" fillOpacity="0.6" />
                                                </svg>
                                            </NavLink>
                                            {localStorage.getItem('listActive')?.includes(DELETE) ? <button type="button" className="deleteButton box32 center" onClick={() => {
                                                showDialog(
                                                    {
                                                        ref: dialogRef,
                                                        status: DialogStatus.WARNING,
                                                        title: "Bạn có chắc muốn xóa đơn hàng?",
                                                        submit: () => {
                                                            item.Action = ActionType.Delete.value;
                                                            callback_action(item)
                                                        }
                                                    }
                                                )
                                            }}>
                                                <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.35402 16.3986C4.39318 16.9234 4.62889 17.4142 5.01406 17.7728C5.39922 18.1315 5.90551 18.3316 6.4318 18.3333H13.5679C14.0942 18.3316 14.6005 18.1315 14.9857 17.7728C15.3708 17.4142 15.6065 16.9234 15.6457 16.3986L16.3012 7.22222H3.69847L4.35402 16.3986Z" fill="red" fillOpacity="1" style={{ mixBlendMode: 'multiply' }} />
                                                    <path d="M17.6387 4.44444H13.4721V2.36111C13.4721 2.17693 13.3989 2.0003 13.2687 1.87006C13.1384 1.73983 12.9618 1.66666 12.7776 1.66666H7.22206C7.03788 1.66666 6.86125 1.73983 6.73101 1.87006C6.60078 2.0003 6.52762 2.17693 6.52762 2.36111V4.44444H2.36095C2.17677 4.44444 2.00014 4.51761 1.8699 4.64784C1.73967 4.77807 1.6665 4.95471 1.6665 5.13889C1.6665 5.32306 1.73967 5.4997 1.8699 5.62993C2.00014 5.76017 2.17677 5.83333 2.36095 5.83333H17.6387C17.8229 5.83333 17.9995 5.76017 18.1298 5.62993C18.26 5.4997 18.3332 5.32306 18.3332 5.13889C18.3332 4.95471 18.26 4.77807 18.1298 4.64784C17.9995 4.51761 17.8229 4.44444 17.6387 4.44444ZM7.9165 3.05555H12.0832V4.44444H7.9165V3.05555Z" fill="red" fillOpacity="1" style={{ mixBlendMode: 'multiply' }} />
                                                </svg>
                                            </button> : null}
                                        </div>
                                    </td>
                                </tr>
                            ))
                            //     )
                            // })
                        }
                    </tbody>
                </table>
            </div>

        </>
    )
}