/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import './sidebar.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import encrypt from "../core/encrypt";

import { faChevronDown, faChevronUp, faStar } from "@fortawesome/free-solid-svg-icons";
import { getUrlRouter } from '../router/router';
import { menu_list } from '../assets/const/menu-list';

export default function SidebarView() {
    const location = useLocation();
    const navigate = useNavigate();
    var menuList = JSON.parse(
        encrypt.decryptData(localStorage.getItem("_t"))
    );
    // var menuList = menu_list;
    const [selected, setSelected] = useState(menuList[0].link);
    // const [listData, setListData] = useState();
    const [listParentSidebar, setListParentSidebar] = useState(menuList.filter((e) => e.parentId === 1));

    const [expanded, setExpanded] = useState({});

    const toggleExpand = (ID) => {
        setExpanded(prevState => ({
            ...prevState,
            [ID]: !prevState[ID]
        }));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setSelected(location.pathname);
        // get data
        // update list parent
        // setListParentSidebar()
        // console.log(location);
        // map location với selected -> change selected sidebar
    }, [location.pathname])

    const createSidebarCard = ({ data, space = 0 }) => {
        const listChild = menuList.filter((e) => e.parentId === data.id);
        const isExpanded = expanded[data.id];
        return (
            <>
                <NavLink key={data.id} className={`sidebar-card nav-link row ${selected.split("/").includes(data.link) && !listChild.length ? "selected" : ""}`} to={listChild.length ? null : data.link} onClick={() => toggleExpand(data.id)}>
                    <div className='box20'>
                        {
                            data.parentId === 1 &&
                            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 4.33366C1.66602 3.56045 2.29283 2.93363 3.06605 2.93363H7.26614C8.03935 2.93363 8.66617 3.56045 8.66617 4.33366V8.53375C8.66617 9.30697 8.03935 9.93378 7.26614 9.93378H3.06605C2.29283 9.93378 1.66602 9.30697 1.66602 8.53375V4.33366ZM7.26614 4.33366H3.06605V8.53375H7.26614V4.33366Z" fill="white" fillOpacity="0.8" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.7792 6.43354L13.566 9.2203L16.3528 6.43354L13.566 3.64677L10.7792 6.43354ZM9.7665 7.40074C9.23233 6.86657 9.23233 6.00051 9.7665 5.46633L12.5988 2.63403C13.133 2.09986 13.999 2.09986 14.5332 2.63403L17.3655 5.46633C17.8997 6.00051 17.8997 6.86657 17.3655 7.40074L14.5332 10.233C13.999 10.7672 13.133 10.7672 12.5988 10.233L9.7665 7.40074Z" fill="white" fillOpacity="0.8" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.0662 12.7338C10.0662 11.9606 10.693 11.3338 11.4662 11.3338H15.6663C16.4395 11.3338 17.0664 11.9606 17.0664 12.7338V16.9339C17.0664 17.7072 16.4395 18.334 15.6663 18.334H11.4662C10.693 18.334 10.0662 17.7072 10.0662 16.9339V12.7338ZM15.6663 12.7338H11.4662V16.9339H15.6663V12.7338Z" fill="white" fillOpacity="0.8" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M1.66602 12.7338C1.66602 11.9606 2.29283 11.3338 3.06605 11.3338H7.26614C8.03935 11.3338 8.66617 11.9606 8.66617 12.7338V16.9339C8.66617 17.7072 8.03935 18.334 7.26614 18.334H3.06605C2.29283 18.334 1.66602 17.7072 1.66602 16.9339V12.7338ZM7.26614 12.7338H3.06605V16.9339H7.26614V12.7338Z" fill="white" fillOpacity="0.8" />
                            </svg>
                        }
                    </div>
                    <div className='sidebar-title' style={{ paddingLeft: space }}>{data.name}</div>
                    {
                        listChild.length > 0 &&
                        <div className='box20'>
                            {isExpanded ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
                        </div>
                    }
                </NavLink>
                {
                    isExpanded && listChild.length > 0 && listChild.map((item, _) => {
                        return createSidebarCard({
                            data: item,
                            space: space + 16
                        })
                    })
                }
            </>
        );

    }

    return (
        <div className="sidebar-view">
            {
                listParentSidebar.map((item, _) => {
                    return createSidebarCard({
                        data: item,
                        action: () => { }
                    })
                })
            }
        </div>
    )
}