import './App.css';
import './component/select2/select2.css';
import './styles/ds-typography.css';
import './styles/ds-skin.css';
import './styles/textfield.css';
import './styles/styles.css';
import './component/table/table.css';

import LoginScreen from './screens/Login/login-screen';
import MainLayout from './screens/Main/main-layout';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ControllerDA } from './core/controller';
import ConfigAPI from './config/configApi';
import { activeAction } from './assets/const/select-list';
import encrypt from './core/encrypt';

const checkToken = () => {
  const token = localStorage.getItem('token') && localStorage.getItem('listActive');
  if (token) {
    return true;
  }
  localStorage.clear()
  return false;
  // return token !== null && token !== undefined;
};


function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/admin/*" element={checkToken() === true ? <MainLayout /> : <Navigate to={"/login"} replace />} />
          <Route path="/admin" element={<Navigate to={checkToken() === false ? "/login" : "/admin/quan-ly-ho-so-ung-vien"} replace />} />
          <Route path="/" element={<Navigate to={checkToken() === false ? "/login" : "/admin/quan-ly-ho-so-ung-vien"} replace />} />

        </Routes>
      </BrowserRouter></>
  );
}

export default App;
