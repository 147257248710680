/* eslint-disable react-hooks/exhaustive-deps */
import { useForm } from 'react-hook-form'
import {
  ReusableInput,
  ReusableSelect1
} from '../../component/TextField/text-field'
import { NavLink, useLocation, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faEye,
  faEyeSlash
} from '@fortawesome/free-solid-svg-icons'
import { useEffect, useRef, useState } from 'react'
import { Dialog } from '../../component/dialog/dialog'
import { Ultis } from '../../core/Utils'
import ReusableDatePicker from '../../component/date-picker/date-picker'
import ConfigAPI from '../../config/configApi'
import { ControllerDA } from '../../core/controller'
import { ActionType } from '../../core/ActionType'
import { applyStatusList } from '../../assets/const/select-list'
import ImportFile from '../../component/import-file/import-file'

export default function QuanLyLopHocForm({ controller }) {
  const location = useLocation()

  const {
    register,
    control,
    setValue,
    watch,
    setError,
    formState: { errors }
  } = useForm({ shouldFocusError: false, defaultValues: { applyStatus: 1, academi: { list: [{}] }, familyMember: [{}], work: [{}] } })

  const { id } = useParams()

  const dialogRef = useRef()

  const [showPass, setShowPass] = useState(false)

  useEffect(() => {
    if (id) {
      ControllerDA.getDetail(ConfigAPI.customerUrl, controller, {
        id: id,
        Action: ActionType.GetById.value
      }).then(res => {
        Object.keys(res.data).forEach(fieldName => {
          if (typeof res.data[fieldName] === 'string')
            res.data[fieldName] = res.data[fieldName].trim()
          if (fieldName === 'academi') {
            let parseValue =
              res.data[fieldName]?.length > 0
                ? JSON.parse(res.data[fieldName])
                : { list: [{}] }
            setValue(fieldName, parseValue)
            if (parseValue.studyFileId?.length > 0) {
              ControllerDA.download({ fileId: parseValue.studyFileId }).then(res => {
                if (res.code === 200) {
                  setValue(`academi.studyFile`, {
                    id: parseValue.studyFileId,
                    name: res.data,
                    path: res.data
                  })
                }
              })
            }
          } else if (fieldName === 'cvFileId') {
            if (res.data[fieldName]?.length > 0)
              ControllerDA.download({ fileId: res.data[fieldName] }).then(res => {
                if (res.code === 200) {
                  setValue(`cvFile`, {
                    id: res.data[fieldName],
                    name: res.data,
                    path: res.data
                  })
                }
              })
          } else if (['familyMember', 'other', 'work'].includes(fieldName)) {
            let parseValue =
              res.data[fieldName]?.length > 0
                ? JSON.parse(res.data[fieldName])
                : [{}]
            setValue(fieldName, parseValue)
          } else if (['birthDay', 'cardDate'].includes(fieldName)) {
            if (res.data[fieldName])
              setValue(
                fieldName,
                Ultis.datetoString(new Date(res.data[fieldName]))
              )
          } else {
            if (fieldName === 'mobile') setValue('initPhoneNumber', res.data[fieldName])
            setValue(fieldName, res.data[fieldName])
          }
        })
      })
    }
  }, [location.pathname])

  return (
    <form
      className='admin-add-view mainView col'
    // onSubmit={handleSubmit(_submitForm, onError)}
    >
      <Dialog ref={dialogRef} />
      <div className='view-header col'>
        <div className='view-name heading-6'>Thông tin ứng viên</div>
        <div className='breadcrums row'>
          <NavLink
            to={'/admin/quan-ly-ho-so-ung-vien'}
            className='nav-link regular1'
            type='button'
          >
            Quản lý hồ sơ ứng viên
          </NavLink>
          <FontAwesomeIcon className='icon' size='2xs' icon={faChevronRight} />
          <NavLink className='nav-link regular1' type='button'>
            Thông tin ứng viên
          </NavLink>
        </div>
      </div>
      <div className='view-content-container row'>
        <div className='view-content col'>
          <div className='scroll-view-container col'>
            <div className='inputForm view-form row-3 row' style={{ pointerEvents: 'none' }} >
              <div className='header row'>
                <div className='label flex1 heading-7'>Thông tin chung</div>
              </div>

              <div className='left-item col' style={{ gap: 16 }}>
                <img
                  className='image'
                  src={watch('urlAvatar') ? `${ConfigAPI.fileUrl}api/UploadFile/img/${watch('urlAvatar')}` : ''}
                  alt=''
                />
              </div>

              <div className='list-input-container row'>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'userName'}
                    label={'Tên đăng nhập'}
                    disabled={id != null}
                  />
                </div>
                {!id && <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'password'}
                    label={'Mật khẩu'}
                    type={showPass ? 'text' : 'password'}
                    sufix={
                      <div className='box32 center' onClick={() => setShowPass(!showPass)} >
                        <FontAwesomeIcon size='xs' icon={showPass ? faEyeSlash : faEye} />
                      </div>
                    }
                  />
                </div>}
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'code'}
                    label={'Mã hồ sơ'}
                    disabled={id != null}
                    onBlur={ev => {
                      if (ev.target.value.trim().length) {
                        ControllerDA.doAction(
                          ConfigAPI.customerUrl,
                          controller,
                          { Action: ActionType.CheckProfile.value }
                        ).then(res => {
                          if (!res.data)
                            setError('code', {
                              message: 'Mã hồ sơ đã tồn tại'
                            })
                        })
                      }
                    }}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'referralCodeIntroduced'}
                    label={'Mã giới thiệu'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'name'}
                    label={'Họ và tên'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    value={watch('sex')}
                    control={control}
                    errors={errors}
                    label={'Giới tính'}
                    required={false}
                    name={'sex'}
                    valueType={'int'}
                    options={[
                      { name: 'Nam', id: 1 },
                      { name: 'Nữ', id: 0 }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'idCard'}
                    label={'CCCD'}
                    required={false}
                    type={'number'}
                  />
                </div>
                <div className='form-card'>
                  <ReusableDatePicker
                    label={'Ngày cấp'}
                    errors={errors}
                    register={register}
                    name={'cardDate'}
                    required={false}
                    value={watch('cardDate')}
                    placeholder={'Chọn ngày cấp'}
                    onChange={value => {
                      if (value) setValue('cardDate', value)
                    }}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'addressId'}
                    label={'Nơi cấp'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableDatePicker
                    label={'Ngày tháng năm sinh'}
                    errors={errors}
                    register={register}
                    name={'birthDay'}
                    required={false}
                    value={watch('birthDay')}
                    placeholder={'Chọn ngày sinh'}
                    onChange={value => {
                      if (value) setValue('birthDay', value)
                    }}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'homeTown'}
                    label={'Địa chỉ'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'mobile'}
                    label={'Số điện thoại'}
                    required={false}
                    type={'number'}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    value={watch('marriage')}
                    control={control}
                    errors={errors}
                    label={'Tình trạng hôn nhân'}
                    required={false}
                    name={'marriage'}
                    valueType={'bool'}
                    options={[
                      { name: 'Đã kết hôn', id: true },
                      { name: 'Chưa kết hôn', id: false }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'height'}
                    label={'Chiều cao'}
                    required={false}
                    type={'number'}
                    sufix={<span className='sufix-icon regular2'>cm</span>}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'weight'}
                    label={'Cân nặng'}
                    required={false}
                    type={'number'}
                    sufix={<span className='sufix-icon regular2'>Kg</span>}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    label={'Nhóm máu'}
                    errors={errors}
                    required={false}
                    name={'bloodGroup'}
                    value={watch('bloodGroup')}
                    options={[
                      { name: 'A', id: 'A' },
                      { name: 'B', id: 'B' },
                      { name: 'AB', id: 'AB' },
                      { name: 'O', id: 'O' }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'eyeSight'}
                    label={'Thị lực'}
                    required={false}
                    type={'number'}
                    sufix={<span className='sufix-icon regular2'>/10</span>}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    name={'colorBlind'}
                    control={control}
                    errors={errors}
                    label={'Mù màu'}
                    value={watch('colorBlind')}
                    required={false}
                    valueType={'bool'}
                    options={[
                      { name: 'Có', id: true },
                      { name: 'Không', id: false }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    label={'Tay thuận'}
                    errors={errors}
                    required={false}
                    name={'preferredHand'}
                    value={watch('preferredHand')}
                    options={[
                      { name: 'Phải', id: 'Phải' },
                      { name: 'Trái', id: 'Trái' }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    errors={errors}
                    label={'Hình xăm'}
                    value={watch('tattooed')}
                    required={false}
                    name={'tattooed'}
                    valueType={'bool'}
                    options={[
                      { name: 'Có', id: true },
                      { name: 'Không', id: false }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    errors={errors}
                    label={'Hút thuốc'}
                    value={watch('smoke')}
                    required={false}
                    name={'smoke'}
                    valueType={'bool'}
                    options={[
                      { name: 'Có', id: true },
                      { name: 'Không', id: false }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    errors={errors}
                    label={'Uống rượu'}
                    value={watch('drinkAlcohol')}
                    required={false}
                    name={'drinkAlcohol'}
                    valueType={'bool'}
                    options={[
                      { name: 'Có', id: true },
                      { name: 'Không', id: false }
                    ]}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    control={control}
                    errors={errors}
                    label={'Trạng thái'}
                    value={watch('applyStatus')}
                    required={false}
                    name={'applyStatus'}
                    options={applyStatusList}
                    disabled={!id}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'forte'}
                    label={'Sở trường'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'weakness'}
                    label={'Sở đoản'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'fieldOfInterest'}
                    label={'Môn học/ lĩnh vực sở trường'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableInput
                    register={register}
                    errors={errors}
                    name={'character'}
                    label={'Tính cách'}
                    required={false}
                  />
                </div>
                <div className='form-card'>
                  <ReusableSelect1
                    errors={errors}
                    required={false}
                    control={control}
                    value={watch(`collectiveExperience`)}
                    label={'Kinh nghiệm tập thể'}
                    options={[
                      { name: 'Có', id: true },
                      { name: 'Không', id: false },
                    ]}
                    name={`collectiveExperience`}
                  />
                </div>
                <div className='form-card' style={{ overflow: 'hidden' }}>
                  <div className='TextFieldContainer col'>
                    <div className='label-container row'>
                      <span className='label-3'>CV ứng viên</span>
                    </div>
                    <div className='row' style={{ justifyContent: 'start' }}>
                      <ImportFile
                        fileItem={watch(`cvFile`)}
                        buttonOnly={true}
                      />
                    </div>
                  </div>
                </div>
                <div className='form-card'>
                  <div className='TextFieldContainer col'>
                    <div className='label-container row'>
                      <span className='label-3'>Tệp đánh giá học tập</span>
                    </div>
                    <div className='row' style={{ justifyContent: 'start' }}>
                      <ImportFile
                        fileItem={watch(`academi.studyFile`)}
                        buttonOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='inputForm view-form row-3 row' style={{ pointerEvents: 'none' }}>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'question1'}
                  label={'Lý do muốn đi Nhật'}
                  required={false}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'question2'}
                  label={'Số tiền tiết kiệm mong muốn sau 3 năm'}
                  placeholder={'Nhập số tiền'}
                  required={false}
                  type={'number'}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'question3'}
                  label={'Công việc mong muốn sau khi về nước'}
                  required={false}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'question4'}
                  label={'Đã từng đi Nhật chưa?'}
                  required={false}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={'question5'}
                  label={'Các quốc gia đã từng đi?'}
                  required={false}
                />
              </div>
            </div>
            <div className='inputForm view-form row-4 col' style={{ pointerEvents: 'none' }}>
              {/* row-3 */}
              <div className='header row'>
                <div className='label flex1 heading-7'>Lý lịch học tập</div>
                <button type='button' className='text-button' >
                  Thêm
                </button>
              </div>
              {(watch('academi.list')).map((item, index) => {
                return (
                  <div style={{ width: '100%' }} key={index} className='list-input-container container-border row' >
                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`academi.list[${index}].schoolName`}
                        label={'Tên trường học'}
                        required={false}
                      />
                    </div>

                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`academi.list[${index}].certificate`}
                        label={'Bằng cấp'}
                        required={false}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`academi.list[${index}].majors`}
                        label={'Khoa'}
                        required={false}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`academi.list[${index}].studyTime`}
                        label={'Thời gian học'}
                        required={false}
                        type={'number'}
                        sufix={<span className='sufix-icon regular2'>năm</span>}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableSelect1
                        errors={errors}
                        required={false}
                        control={control}
                        value={watch(`academi.list[${index}].studyStatus`)}
                        label={'Tình trạng học tập'}
                        options={[
                          { name: 'Học muộn', id: 'Học muộn' },
                          { name: 'Học sớm', id: 'Học sớm' },
                          { name: 'Học lại', id: 'Học lại' }
                        ]}
                        name={`academi.list[${index}].studyStatus`}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='inputForm view-form row-4 col' style={{ pointerEvents: 'none' }}>
              {/* row-3 */}
              <div className='header row'>
                <div className='label flex1 heading-7'>Lý lịch làm việc</div>
                <button type='button' className='text-button' >
                  Thêm
                </button>
              </div>
              {watch('work').map((item, index) => {
                return (
                  <div style={{ width: '100%' }} className='list-input-container container-border row' >
                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`work[${index}].companyName`}
                        label={'Tên công ty'}
                        required={false}
                      />
                    </div>

                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`work[${index}].workPosition`}
                        label={'Vị trí làm việc'}
                        required={false}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`work[${index}].career`}
                        label={'Công việc'}
                        required={false}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`work[${index}].workTime`}
                        label={'Thời gian làm việc'}
                        required={false}
                        type={'number'}
                        sufix={<span className='sufix-icon regular2'>năm</span>}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='inputForm view-form row-4 col' style={{ pointerEvents: 'none' }}>
              <div className='header row'>
                <div className='label flex1 heading-7'>
                  Thành viên trong gia đình
                </div>
                <button type='button' className='text-button'  >
                  Thêm
                </button>
              </div>
              {watch('familyMember').map((item, index) => {
                return (
                  <div style={{ width: '100%' }} className='list-input-container container-border row' >
                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`familyMember[${index}].name`}
                        label={'Họ và tên'}
                        required={false}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableSelect1
                        control={control}
                        errors={errors}
                        label={'Mối quan hệ'}
                        value={watch(`familyMember[${index}].relation`)}
                        required={false}
                        name={`familyMember[${index}].relation`}
                        options={[
                          { name: 'Cha đẻ', id: '1' },
                          { name: 'Mẹ đẻ', id: '2' },
                          { name: 'Vợ', id: '3' },
                          { name: 'Chồng', id: '6' },
                          { name: 'Con trai', id: '4' },
                          { name: 'Con gái', id: '5' },
                        ]}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`familyMember[${index}].age`}
                        label={'Tuổi'}
                        type={'number'}
                        required={false}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableInput
                        register={register}
                        errors={errors}
                        name={`familyMember[${index}].career`}
                        label={'Nghề nghiệp'}
                        required={false}
                      />
                    </div>
                    <div className='form-card'>
                      <ReusableSelect1
                        control={control}
                        errors={errors}
                        label={'Tình trạng chung sống'}
                        value={watch(`familyMember[${index}].situation`)}
                        required={false}
                        name={`familyMember[${index}].situation`}
                        options={[
                          { name: 'Sống chung', id: '1' },
                          { name: 'Sống riêng', id: '2' }
                        ]}
                      />
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='inputForm view-form row-3 row' style={{ pointerEvents: 'none' }}>
              {/* row-3 */}
              <div className='header row'>
                <div className='label flex1 heading-7'>Thông tin khác</div>
              </div>
              <div className='form-card'>
                <ReusableSelect1
                  control={control}
                  errors={errors}
                  label={'Gia đình có ủng hộ đi Nhật?'}
                  value={watch(`other.opinion`)}
                  required={false}
                  name={`other.opinion`}
                  // valueType={'bool'}
                  options={[
                    { name: 'Có', id: 'Có' },
                    { name: 'Không', id: 'Không' }
                  ]}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={`other.familyIncome`}
                  label={'Thu nhập của gia đình'}
                  required={false}
                  type={'number'}
                />
              </div>
              <div className='form-card'>
                <ReusableInput
                  register={register}
                  errors={errors}
                  name={`other.relativesInJapan`}
                  label={'Có người thân bạn bè ở Nhật?'}
                  placeholder={'Nhập người thân đang ở Nhật'}
                  required={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='view-footer row'>
        <button
          type='button'
          className='close-button'
          onClick={() => {
            // showDialog({
            //   ref: dialogRef,
            //   status: DialogStatus.WARNING,
            //   title: `Xác nhận thoát ${id ? 'chỉnh sửa' : 'tạo'} ứng viên?`,
            //   content: 'Thông tin ứng viên đã nhập sẽ không được lưu lại',
            //   submit: () => {
            window.history.back()
            //   }
            // })
          }}
        >
          <span className='button-text-3'>Thoát</span>
        </button>
        <div className='space'></div>
        {/* <button type='submit' className='submit-button'>
          <span className='button-text-3'>Gửi</span>
        </button> */}
      </div>
    </form>
  )
}
