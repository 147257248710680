/* eslint-disable react-hooks/exhaustive-deps */
import { NavLink } from "react-router-dom";
import { ActionType } from "../../core/ActionType";
import GetAction from "../../core/Action";
import { Ultis } from "../../core/Utils";
import { useRef } from "react";
import { Dialog, DialogStatus, showDialog } from "../../component/dialog/dialog";
import { ADD, DELETE } from "../../assets/const/select-list";

export default function ListView({ data, controller, callback_action }) {
    const diaglogRef = useRef()

    const confirmDelete = (item) => {
        showDialog({
            ref: diaglogRef,
            status: DialogStatus.WARNING,
            title: `Xác nhận xóa video ${item.name}`,
            content: 'Thông tin của ứng viên sẽ bị xóa khỏi hệ thống',
            submit: () => {
                callback_action(item)
            }
        })
    }
    return (
        <>
            <Dialog ref={diaglogRef} />
            <div className='listView-header row'>
                <div className='heading-7 space'>Danh sách kết quả</div>
                {localStorage.getItem('listActive')?.includes(ADD) ? <GetAction controller={controller} action={ActionType.Add.value} /> : null}
            </div>
            <div className='listView-body col'>
                <table className='custom-table'>
                    <thead>
                        <tr>
                            <th style={{ minWidth: 75, }} className='fixed-first-column'><div className="center">STT</div></th>
                            <th style={{ minWidth: 360, }} className=''>Tên tin tức</th>
                            <th style={{ minWidth: 150, }} className=''>Loại tin tức</th>
                            <th style={{ minWidth: 150, }} className='' >Ngày tạo</th>
                            {localStorage.getItem('listActive')?.includes(DELETE) ? <th style={{ minWidth: 110, }} className='fixed-last-column' >Action</th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data && data.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ minWidth: 75, }} className='fixed-first-column'><div className="center">{index + 1}</div></td>
                                    <td style={{ minWidth: 360, }} className=''>
                                        <NavLink to={ActionType.Edit.value + `/${item.id}`} className={'nav-link'}>{item.title ?? ""}</NavLink>
                                    </td>
                                    <td style={{ minWidth: 150, }} className=''>{[{ name: 'Nhật Bản', id: 1 }, { name: 'Việt Nam', id: 2 }].find(e => e.id === item.typeNew)?.name ?? ''}</td>
                                    <td style={{ minWidth: 150, }} className=''>{item.dateCreated ? Ultis.datetoString(new Date(item.dateCreated)) : ''}</td>
                                    {localStorage.getItem('listActive')?.includes(DELETE) ? <td style={{ minWidth: 110, }} className='fixed-last-column'>
                                        <button type="button" className="deleteButton box32 center" onClick={() => { confirmDelete(item) }}>
                                            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M4.35402 16.3986C4.39318 16.9234 4.62889 17.4142 5.01406 17.7728C5.39922 18.1315 5.90551 18.3316 6.4318 18.3333H13.5679C14.0942 18.3316 14.6005 18.1315 14.9857 17.7728C15.3708 17.4142 15.6065 16.9234 15.6457 16.3986L16.3012 7.22222H3.69847L4.35402 16.3986Z" fill="red" fillOpacity="1" style={{ mixBlendMode: 'multiply' }} />
                                                <path d="M17.6387 4.44444H13.4721V2.36111C13.4721 2.17693 13.3989 2.0003 13.2687 1.87006C13.1384 1.73983 12.9618 1.66666 12.7776 1.66666H7.22206C7.03788 1.66666 6.86125 1.73983 6.73101 1.87006C6.60078 2.0003 6.52762 2.17693 6.52762 2.36111V4.44444H2.36095C2.17677 4.44444 2.00014 4.51761 1.8699 4.64784C1.73967 4.77807 1.6665 4.95471 1.6665 5.13889C1.6665 5.32306 1.73967 5.4997 1.8699 5.62993C2.00014 5.76017 2.17677 5.83333 2.36095 5.83333H17.6387C17.8229 5.83333 17.9995 5.76017 18.1298 5.62993C18.26 5.4997 18.3332 5.32306 18.3332 5.13889C18.3332 4.95471 18.26 4.77807 18.1298 4.64784C17.9995 4.51761 17.8229 4.44444 17.6387 4.44444ZM7.9165 3.05555H12.0832V4.44444H7.9165V3.05555Z" fill="red" fillOpacity="1" style={{ mixBlendMode: 'multiply' }} />
                                            </svg>
                                        </button>
                                    </td> : null}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>

        </>
    )
}