/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../component/pagination/pagination';
import { ControllerDA } from '../../core/controller';
import { ActionType } from '../../core/ActionType';
import ListView from './ListView';
import SearchForm from './Search';
import ConfigAPI from '../../config/configApi';
import { GET } from '../../assets/const/select-list';
import notiUtil from '../../core/notiUtil';

export default function QuanLyUngVien({ controller }) {
    const location = useLocation();

    const [data, setData] = useState();
    const [pageDetails, setPageDetails] = useState({ page: 1, size: 10 });

    const _InitData = async ({ page, size, query }) => {
        if (localStorage.getItem('listActive')?.includes(GET)) {
            if (!query) {
                query = location.search;
            }
            const queryParams = new URLSearchParams(query);
            const filters = [{
                "field": 'type',
                "operator": "contains",
                "value": 1,
            }];

            queryParams.forEach((value, key) => {
                if (key === 'applyStatus') {
                    var vl = parseInt(value)
                }
                filters.push({
                    "field": key,
                    "operator": "contains",
                    "value": vl ?? value,
                });
            });

            var obj = {
                "loadOptions": {
                    "requireTotalCount": true,
                    "skip": (page - 1) * size,
                    "take": size,
                },
                "filter": filters,
                "Action": ActionType.GetAll.value
            }

            const res = await ControllerDA.filterAction(ConfigAPI.customerUrl, controller, obj);
            if (res.code == 200) {
                setData(res)
            }
        } else {
            notiUtil.errors('Tài khoản không có quyền lấy dữ liệu')
        }
    }

    const _Action = async (obj) => {
        let putData = {
            ...obj,
            Action: ActionType.Delete.value
        }
        const response = await ControllerDA.editAction(ConfigAPI.customerUrl, controller, putData);
        if (response.code == 200) {
            _InitData({ page: pageDetails.page, size: pageDetails.size, query: location.search })
        }
    }
    useEffect(() => {
        console.log(location);
        _InitData({ page: pageDetails.page, size: pageDetails.size, query: location.search });
    }, [location.search]);


    return (
        <div className="mainView col">
            <div className='view-header col'>
                <div className="view-name heading-6">Quản lý hồ sơ ứng viên</div>
            </div>
            <div className="view-content-container row">
                <div className="view-content col">
                    <SearchForm initAction={() => _InitData({ page: pageDetails.page, size: pageDetails.size, query: location.search })} />
                    <div className="listView col">
                        <ListView
                            data={data?.data}
                            controller={controller}
                            callback_action={_Action}
                        />
                        <div className="footer row">
                            <Pagination
                                /// Size
                                currentPage={pageDetails.page}
                                /// pageSize
                                itemPerPage={pageDetails.size}
                                // data.total
                                totalItem={data?.totalCount ?? 10}
                                /// action
                                onChangePage={(page, size) => {
                                    if (pageDetails.page !== page || pageDetails.size !== size) {
                                        setPageDetails({ page: page, size: size });
                                        _InitData({ page: page, size: size });
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
